// Built-in import
import * as React from "react";
import * as Yup from "yup";

// External import
import { Button, Stack, TextField, Typography } from "@mui/material";
// Internal import
import { MyDatePicker, Tag, TitleWithValue } from "@/components/shared";

import EventDetailsActions from "../actions";
import EventStatus from "@/components/shared/event-card/status";
import { IEvent } from "@/types/event";
import { Report } from "@/services";
import moment from "moment";
import { toaster } from "@/components/shared/toaster";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import DeleteEventModalContent from "../actions/delete-modal";
import { useGlobalContext } from "@/context/global-context";

//import moment from "jalali-moment"

interface IFinancialStep {
  event: IEvent;
  onChangeEvents?: () => void;
  isEditable?: Boolean;
}

const FinancialStep: React.FC<IFinancialStep> = ({
  event,
  onChangeEvents,
  isEditable = false,
}) => {
  //check for active edit state or not

  const { showModal } = useGlobalContext();
  const [isEdit, setIsEdit] = React.useState(false);
  // getting translation object
  const { t } = useTranslation(["common", "validation"]);
  //loading state
  const [loading, setLoading] = React.useState(false);

  const {
    values,
    handleSubmit,
    setFieldValue,
    getFieldProps,
    errors,
    touched,
  } = useFormik({
    initialValues: {
      expires_at: event?.expires_at ? new Date(event.expires_at) : new Date(),
      revenue_percent: event?.revenue_percent || "",
    },
    validationSchema: Yup.object().shape({
      revenue_percent: Yup.string().required(
        t("validation:this_field_is_required")
      ),
    }),
    onSubmit: async (values) => {
      setLoading(true);

      try {
        const res = await Report.Create(event?.project_id, event?.type, {
          revenue_percent: values.revenue_percent,
          ...(event.expires_at && {
            expires_at: moment(values.expires_at).unix(),
          }),
          event_id: event?.id,
        });
        toaster.success(res.data.message);
        onChangeEvents();
      } catch (error) {
        toaster.error(error.response.data);
      } finally {
        setLoading(false);
      }
    },
  });
  //checking for select deleted item from client and awaiting admin confirmation
  const isPendingDelete = Boolean(
    event.status === "Deleted" && event.deleted_at === null
  );
  return (
    <Stack
      component={"form"}
      onSubmit={handleSubmit}
      alignItems="start"
      justifyContent={"start"}
      height="100%"
      spacing={5}
    >
      <Stack width="100%" spacing={2}>
        <Stack
          sx={{
            bgcolor: (t) => t.palette.grey[200],
            border: "1px solid",
            borderColor: (t) => t.palette.grey[400],
            px: 2,
            py: 1,
            borderRadius: "12px",
          }}
          width="100%"
          direction={"row"}
          justifyContent="space-between"
          alignItems={"center"}
        >
          <Typography variant="bodyBold">
            {t("common:deposit_interest_account")}
          </Typography>
          {/* check for show tag when event was predicted or not */}
          <EventStatus
            status={isPendingDelete ? t("pending_delete") : event?.status}
          />
          {/* check for show tag when event was predicted or not */}
        </Stack>
        <Stack>
          {/* when we use jalali moment module receive wrong asnwer */}
          {/* {JSON.stringify(
            moment("2023-03-19T20:30:00.000000Z")
              .locale("fa")
              .format("YYYY-MM-DD")
          )} */}
          {/* if our event was unpredicted we shouldn't show interest deposit */}
          {event?.expires_at ? (
            <TitleWithValue
              title={t("common:time_to_deposit_interest_on_account")}
              value={
                <MyDatePicker
                  disabled={!isEdit}
                  title={t("common:select_date")}
                  onChange={(date) => {
                    if (date) {
                      setFieldValue("expires_at", date);
                    }
                  }}
                  value={values.expires_at}
                />
              }
              direction={"column"}
              alignItems="flex-start"
              colorTitle="text.secondary"
              variantTitle="smallMedium"
            />
          ) : null}
          {/* if our event was unpredicted we shouldn't show interest deposit */}
        </Stack>
        <Stack>
          <TitleWithValue
            title={t("common:interest_percentage_on_account")}
            value={
              <TextField
                fullWidth
                size="small"
                {...getFieldProps("revenue_percent")}
                disabled={isEdit ? false : true}
                error={Boolean(
                  touched.revenue_percent && errors.revenue_percent
                )}
                helperText={touched.revenue_percent && errors.revenue_percent}
              />
            }
            direction={"column"}
            alignItems="flex-start"
            colorTitle="text.secondary"
            variantTitle="smallMedium"
          />
        </Stack>
        <Stack>
          <TitleWithValue
            title={t("common:payable")}
            value={
              <TextField
                disabled={true}
                fullWidth
                size="small"
                defaultValue={"است"}
              />
            }
            direction={"column"}
            alignItems="flex-start"
            colorTitle="text.secondary"
            variantTitle="smallMedium"
          />
        </Stack>
        <Stack>
          <TitleWithValue
            title={t("common:status")}
            value={
              <TextField
                disabled={true}
                fullWidth
                size="small"
                defaultValue={"درانتظار پرداخت"}
              />
            }
            direction={"column"}
            alignItems="flex-start"
            colorTitle="text.secondary"
            variantTitle="smallMedium"
          />
        </Stack>
        <Stack>
          <TitleWithValue
            title={t("common:payer")}
            value={
              <TextField
                disabled={true}
                fullWidth
                size="small"
                defaultValue={"احمد سرمایه پذیر"}
              />
            }
            direction={"column"}
            alignItems="flex-start"
            colorTitle="text.secondary"
            variantTitle="smallMedium"
          />
        </Stack>
      </Stack>
      {isEditable ? (
        isEdit ? (
          <EventDetailsActions loading={loading} />
        ) : (
          <Stack
            direction={"row"}
            spacing={2}
            width={"100%"}
            justifyContent={"flex-end"}
            alignItems={"cetner"}
          >
            <Button
              variant="contained"
              color="error"
              onClick={() =>
                showModal(
                  <DeleteEventModalContent
                    event={event}
                    onChangeEvents={onChangeEvents}
                  />,
                  {
                    width: ["100%", , 500],
                    height: "auto",
                  }
                )
              }
            >
              {t("delete")}
            </Button>
            <Button
              variant="outlined"
              onClick={() => setIsEdit(true)}
              sx={{ alignSelf: "flex-end" }}
            >
              {t("common:edit")}
            </Button>
          </Stack>
        )
      ) : null}
    </Stack>
  );
};

export default FinancialStep;
