//Built-in Import
import * as React from "react";
//External Import
import { AllVariants } from "@/mui/theme/declarations";
import { Stack, Typography } from "@mui/material";

interface ITitleWithElementProps {
  title: string | React.ReactNode;
  titleVariant?: AllVariants;
  spacing?: number;
  element: React.ReactElement;
}

const TitleWithElement: React.FunctionComponent<ITitleWithElementProps> = ({
  title,
  titleVariant = "smallRegular",
  spacing,
  element,
}) => {
  return (
    <Stack spacing={spacing} className="title-with-element">
      <Typography variant={titleVariant} color="text.primary">
        {title}
      </Typography>
      {element}
    </Stack>
  );
};

export default TitleWithElement;
