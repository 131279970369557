import { CompleteInfoType } from "@/types/auth";
import type { IUser } from "@/types/user";
import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

//Initial state type
interface IAuthState {
  currentStep: CompleteInfoType | null;
  selectedType: "Real" | "Legal" | null;
  loading: boolean;
  user: IUser | null;
  updatedUser: IUser | null;
  token: string | null;
}

//Initial state
const initialState: IAuthState = {
  currentStep: null,
  selectedType: null,
  loading: false,
  user: null,
  updatedUser: null,
  token: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setLoading(state) {
      state.loading = true;
    },
    setLoadingOff(state) {
      state.loading = false;
    },
    setUser(state, action: PayloadAction<any>) {
      state.user = action.payload?.user;
      if (action.payload.token) {
        state.token = action.payload?.token;
      }
      state.loading = false;
      state.user = action.payload?.user;
      state.loading = false;
    },
    setToken(state, action: PayloadAction<any>) {
      state.token = action.payload?.token;
      state.loading = false;
    },
    setLogout(state) {
      state.user = null;
      state.token = null;
      state.loading = false;
    },
    reset(state) {
      state = initialState;
    },
    updateUserInfo(state, action: PayloadAction<any>) {
      if (action.payload && Object.keys(action.payload).length) {
        for (let key of Object.keys(action.payload)) {
          state.updatedUser = {
            ...state.updatedUser,
            id: state.user.id,
            [key]: action?.payload?.[key],
          };
        }
      }
    },
    setCurrentStep(state, action: PayloadAction<CompleteInfoType>) {
      state.currentStep = action.payload;
    },
    setSelectedType(state, action: PayloadAction<"Real" | "Legal" | null>) {
      state.selectedType = action.payload;
    },
  },
});

export const {
  setUser: actionSetUser,
  setToken: actionSetToken,
  setLogout: actionSetLogout,
  setLoading: actionAuthSetLoading,
  setLoadingOff: actionAuthSetLoadingOff,
  updateUserInfo: actionUpdateUserInfo,
  setCurrentStep: actionSetCurrentStep,
  setSelectedType: actionSetSelectedType,
} = authSlice.actions;

export default authSlice.reducer;
