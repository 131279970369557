//Built-in Import
import * as React from "react";
//External Import
import { Grid } from "@mui/material";
import FinancialInformationCard from "./card";
import { useAuth } from "@/hook";
import { useTranslation } from "react-i18next";
import { IFinancialSummary } from "@/components/pages/dashboard/financial";
//Internal Import

interface IFinancialInformationItemsProps {
  item: IFinancialSummary;
}

const FinancialInformationItems: React.FunctionComponent<
  IFinancialInformationItemsProps
> = ({ item }) => {
  //getting translation object
  const { t } = useTranslation();
  //getting role definer from useAuth
  const { isRole } = useAuth();
  //checking for user is investor or not
  const isInvestor = isRole("investor");
  // check for investment title
  const investmentTitle = React.useMemo(() => {
    if (isInvestor) {
      return t("investment_count");
    } else {
      return t("project_investors");
    }
  }, [isInvestor, t]);
  return (
    <Grid container spacing={2}>
      <Grid item md={6} xs={6}>
        <FinancialInformationCard
          color="linear-gradient(180deg, #62E0C2 80%, #21989F 100%)"
          amount={item?.total_average_profits}
          description={t("average_of_profit_percent_of_project")}
        />
      </Grid>
      <Grid item md={6} xs={6}>
        <FinancialInformationCard
          color="linear-gradient(180deg, #A77DFF 80%, #632ED5 100%)"
          amount={item?.total_invested_projects}
          description={investmentTitle}
        />
      </Grid>
    </Grid>
  );
};

export default FinancialInformationItems;
