//  Built-in import
import * as React from "react";
// External Import
import { Button, Stack, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import SearchIcon from "@mui/icons-material/Search";

interface ISearchBoxWithButton {
  placeholder?: string;
  handleChange?: Function;
}

const SearchBoxWithButton: React.FunctionComponent<ISearchBoxWithButton> = ({
  placeholder,
  handleChange,
}) => {
  // Getting Translation
  const { t } = useTranslation(["common"]);

  const [search, setSearch] = React.useState("");

  const handleChangeInputVal = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setSearch(e.target.value);
    if (handleChange) handleChange(e.target.value);
  };
  return (
    <Stack
      direction="row"
      sx={{
        width: "100%",
        [`& .MuiButtonBase-root`]: {
          borderTopLeftRadius: 0,
          borderBottomLeftRadius: 0,
          width: "auto",
          minWidth: "auto",
          px: 1,
        },

        [`& .MuiFormControl-root`]: {
          flexGrow: 1,
          [`& .MuiInputBase-root`]: {
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
            height: "100%",
            "&.Mui-focused fieldset": {
              borderWidth: ".5px",
              borderRightWidth: "0px",
            },
            [`& .MuiInputBase-input`]: {
              py: 1,
              bgcolor: (t) => t.palette.common.white,
              fontSize: "12px",
            },
            "& fieldset": {
              borderRightWidth: 0,
            },
          },
        },
      }}
    >
      <TextField
        placeholder={placeholder}
        onChange={(e) => handleChangeInputVal(e)}
      />
      <Button variant="outlined">
        <SearchIcon />
      </Button>
    </Stack>
  );
};

export default SearchBoxWithButton;
