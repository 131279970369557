// Built-in import
import * as React from "react"
// External Import
// Internal Import

interface ILunchIconProps {
  color?: string
}

const LunchIcon: React.FunctionComponent<ILunchIconProps> = ({
  color = "#6E6B7B"
}) => {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.8327 2.24984C17.9993 2.33317 18.166 2.49984 18.2493 2.6665C18.3327 2.74984 18.3327 2.9165 18.3327 2.99984V7.99984C18.3327 8.49984 17.9993 8.83317 17.4993 8.83317C16.9993 8.83317 16.666 8.49984 16.666 7.99984V4.99984L8.91602 12.7498C8.74935 12.9165 8.58268 12.9998 8.33268 12.9998C8.08268 12.9998 7.91602 12.9165 7.74935 12.7498C7.41602 12.4165 7.41602 11.9165 7.74935 11.5832L15.4993 3.83317H12.4993C11.9993 3.83317 11.666 3.49984 11.666 2.99984C11.666 2.49984 11.9993 2.1665 12.4993 2.1665H17.4993C17.5827 2.1665 17.7493 2.1665 17.8327 2.24984ZM15.8327 16.3332V11.3332C15.8327 10.8332 15.4993 10.4998 14.9993 10.4998C14.4993 10.4998 14.166 10.8332 14.166 11.3332V16.3332C14.166 16.8332 13.8327 17.1665 13.3327 17.1665H4.16602C3.66602 17.1665 3.33268 16.8332 3.33268 16.3332V7.1665C3.33268 6.6665 3.66602 6.33317 4.16602 6.33317H9.16602C9.66602 6.33317 9.99935 5.99984 9.99935 5.49984C9.99935 4.99984 9.66602 4.6665 9.16602 4.6665H4.16602C2.74935 4.6665 1.66602 5.74984 1.66602 7.1665V16.3332C1.66602 17.7498 2.74935 18.8332 4.16602 18.8332H13.3327C14.7493 18.8332 15.8327 17.7498 15.8327 16.3332Z"
        fill={color}
      />
      <mask
        id="mask0_1253_22977"
        maskUnits="userSpaceOnUse"
        x="1"
        y="2"
        width="18"
        height="17"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M17.8327 2.24984C17.9993 2.33317 18.166 2.49984 18.2493 2.6665C18.3327 2.74984 18.3327 2.9165 18.3327 2.99984V7.99984C18.3327 8.49984 17.9993 8.83317 17.4993 8.83317C16.9993 8.83317 16.666 8.49984 16.666 7.99984V4.99984L8.91602 12.7498C8.74935 12.9165 8.58268 12.9998 8.33268 12.9998C8.08268 12.9998 7.91602 12.9165 7.74935 12.7498C7.41602 12.4165 7.41602 11.9165 7.74935 11.5832L15.4993 3.83317H12.4993C11.9993 3.83317 11.666 3.49984 11.666 2.99984C11.666 2.49984 11.9993 2.1665 12.4993 2.1665H17.4993C17.5827 2.1665 17.7493 2.1665 17.8327 2.24984ZM15.8327 16.3332V11.3332C15.8327 10.8332 15.4993 10.4998 14.9993 10.4998C14.4993 10.4998 14.166 10.8332 14.166 11.3332V16.3332C14.166 16.8332 13.8327 17.1665 13.3327 17.1665H4.16602C3.66602 17.1665 3.33268 16.8332 3.33268 16.3332V7.1665C3.33268 6.6665 3.66602 6.33317 4.16602 6.33317H9.16602C9.66602 6.33317 9.99935 5.99984 9.99935 5.49984C9.99935 4.99984 9.66602 4.6665 9.16602 4.6665H4.16602C2.74935 4.6665 1.66602 5.74984 1.66602 7.1665V16.3332C1.66602 17.7498 2.74935 18.8332 4.16602 18.8332H13.3327C14.7493 18.8332 15.8327 17.7498 15.8327 16.3332Z"
          fill={color}
        />
      </mask>
      <g mask="url(#mask0_1253_22977)">
        <rect y="0.5" width="20" height="20" fill={color} />
      </g>
    </svg>
  )
}

export default LunchIcon
