function roundCoefficientNumber(inputNumber, coefficientNumber) {
  const rounded =
    Math.round(inputNumber / coefficientNumber) * coefficientNumber
  if (rounded < inputNumber) {
    return rounded + coefficientNumber
  } else {
    return rounded
  }
}
export default roundCoefficientNumber
