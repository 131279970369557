import * as React from "react";

import { Stack, Typography, alpha, useTheme } from "@mui/material";

import { useTranslation } from "react-i18next";

interface IEventStatusProps {
  status: string;
}

const EventStatus: React.FunctionComponent<IEventStatusProps> = ({
  status,
}) => {
  // Getting theme mui
  const theme = useTheme();

  //Getting translation
  const { t } = useTranslation(["common"]);
  // bg color
  let statusBgColor: string;

  // switch case status
  switch (status) {
    case "Accepted":
      statusBgColor = theme.palette.success.light;
      break;
    case "Pending":
      statusBgColor = theme.palette.warning.light;
      break;

    case "Deleted":
    case "pending_delete":
    case "Rejected":
      statusBgColor = theme.palette.error.main;
      break;
    default:
      statusBgColor = theme.palette.grey[400];
      break;
  }

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="center"
      sx={{
        p: 1,
        px: 2,
        borderRadius: 10,
        bgcolor: alpha(statusBgColor, 0.12),
        [`& *`]: {
          alignItems: "center",
          display: "flex",
          color: `${statusBgColor} !important`,
        },
      }}
      spacing={1}
    >
      <Typography variant="smallLight">
        {t(`common:property_${status?.toLowerCase()}`)}
      </Typography>
    </Stack>
  );
};

export default EventStatus;
