import * as React from "react"

import { TitleWithDescription } from "@/components/shared"
import { useTranslation } from "react-i18next"

interface ISajamSystemInformationTitle {}

export const SajamSystemInformationTitle: React.FC<
  ISajamSystemInformationTitle
> = () => {
  const { t } = useTranslation()

  return (
    <TitleWithDescription
      title={t("receive_information_from_sjam_system")}
      spacing={1}
      headerVariant={"subtitleBlack"}
      descriptionVariant={"bodyRegular"}
      primaryColor={"text.primary"}
      alignment={{ xs: "center", md: "left" }}
    />
  )
}
