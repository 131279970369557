export enum CompleteInfoType {
  Legal = "Legal",
  Real = "Real",
  SajamSystemInformation = "sajam-system-information",
  BasicInformation = "basic-information",
  CompleteInformation = "complete-information",
  UploadDocuments = "upload-docs",
  RealUpdatedSuccess = "real-updated-successfully",
  LegalUpdatedSuccess = "legal-updated-successfully",
}
export interface IRegisterWaysItem {
  id: number;
  icon: React.ReactNode;
  title: string;
  description: string;
  value: "Real" | "Legal";
}

export enum LoginSteps {
  LoginWithMobile = 0,
  EnterMobileNumber = 1,
  OtpField = 2,
}

export enum ForgetPasswordSteps {
  ResetWaySelection = 1,
  EnterMobileNUmber = 2,
  OtpField = 3,
  SetNewPassword = 5,
}

export enum OtpFieldTypes {
  OneTimePassword = "one-time-password",
  ChangePassword = "change-password",
}
