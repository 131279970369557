import * as React from "react";
import { IIconType } from "@/types/icon";

interface PersonalCardIconProps extends IIconType {}

const PersonalCardIcon: React.FunctionComponent<PersonalCardIconProps> = ({
  color = "#E29314",
  width = 47,
  height = 46,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 57 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M40.1665 50.75H16.8332C6.54317 50.75 3.4165 47.6233 3.4165 37.3333V18.6667C3.4165 8.37667 6.54317 5.25 16.8332 5.25H40.1665C50.4565 5.25 53.5832 8.37667 53.5832 18.6667V37.3333C53.5832 47.6233 50.4565 50.75 40.1665 50.75ZM16.8332 8.75C8.47984 8.75 6.9165 10.3367 6.9165 18.6667V37.3333C6.9165 45.6633 8.47984 47.25 16.8332 47.25H40.1665C48.5198 47.25 50.0832 45.6633 50.0832 37.3333V18.6667C50.0832 10.3367 48.5198 8.75 40.1665 8.75H16.8332Z"
        fill={color}
      />
      <path
        d="M44.8332 20.4166H33.1665C32.2098 20.4166 31.4165 19.6233 31.4165 18.6666C31.4165 17.71 32.2098 16.9166 33.1665 16.9166H44.8332C45.7898 16.9166 46.5832 17.71 46.5832 18.6666C46.5832 19.6233 45.7898 20.4166 44.8332 20.4166Z"
        fill={color}
      />
      <path
        d="M44.8333 29.75H35.5C34.5433 29.75 33.75 28.9567 33.75 28C33.75 27.0433 34.5433 26.25 35.5 26.25H44.8333C45.79 26.25 46.5833 27.0433 46.5833 28C46.5833 28.9567 45.79 29.75 44.8333 29.75Z"
        fill={color}
      />
      <path
        d="M44.8332 39.0834H40.1665C39.2098 39.0834 38.4165 38.29 38.4165 37.3334C38.4165 36.3767 39.2098 35.5834 40.1665 35.5834H44.8332C45.7898 35.5834 46.5832 36.3767 46.5832 37.3334C46.5832 38.29 45.7898 39.0834 44.8332 39.0834Z"
        fill={color}
      />
      <path
        d="M20.3332 28.0934C17.0432 28.0934 14.3599 25.4101 14.3599 22.1201C14.3599 18.8301 17.0432 16.1467 20.3332 16.1467C23.6232 16.1467 26.3065 18.8301 26.3065 22.1201C26.3065 25.4101 23.6232 28.0934 20.3332 28.0934ZM20.3332 19.6467C18.9799 19.6467 17.8599 20.7667 17.8599 22.1201C17.8599 23.4734 18.9799 24.5934 20.3332 24.5934C21.6865 24.5934 22.8065 23.4734 22.8065 22.1201C22.8065 20.7667 21.6865 19.6467 20.3332 19.6467Z"
        fill={color}
      />
      <path
        d="M28.4998 39.8534C27.6131 39.8534 26.8431 39.1767 26.7498 38.2667C26.4931 35.7467 24.4631 33.7167 21.9198 33.4834C20.8464 33.39 19.7731 33.39 18.6998 33.4834C16.1564 33.7167 14.1264 35.7234 13.8698 38.2667C13.7764 39.2234 12.9131 39.9467 11.9564 39.83C10.9998 39.7367 10.2998 38.8734 10.3931 37.9167C10.8131 33.7167 14.1498 30.38 18.3731 30.0067C19.6564 29.89 20.9631 29.89 22.2464 30.0067C26.4464 30.4034 29.8064 33.74 30.2264 37.9167C30.3198 38.8734 29.6198 39.7367 28.6631 39.83C28.6164 39.8534 28.5464 39.8534 28.4998 39.8534Z"
        fill={color}
      />
    </svg>
  );
};

export default PersonalCardIcon;
