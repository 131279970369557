// Built-in import
import { FC } from "react"

import EnterNationalCode from "./enter-national-code"
import EnterSejamOtp from "./enter-sejam-otp"

import UpdateInfoModalWrapper from "../content-wrapper"
// Internal import
import { StepValues } from "@/components/shared"

interface ISajamSystemInformation {
  onClose?: () => void
}

const SajamSystemInformation: FC<ISajamSystemInformation> = ({ onClose }) => {
  return (
    <StepValues initValues={{ national_code: "" }}>
      {({ changeBulk, changeKey, nextStep, prevStep, step, values }) => {
        let currentView = null

        if (step === 0) {
          currentView = (
            <EnterNationalCode onChange={changeKey} onSubmit={nextStep} />
          )
        }
        if (step === 1) {
          currentView = (
            <EnterSejamOtp
              onClose={onClose}
              onPrevStep={prevStep}
              national_code={values.national_code}
            />
          )
        }
        return (
          <UpdateInfoModalWrapper handleBack={prevStep}>
            {currentView}
          </UpdateInfoModalWrapper>
        )
      }}
    </StepValues>
  )
}

export default SajamSystemInformation
