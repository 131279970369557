import { api, getEndpoint } from "@/api";

class Transaction {
  public static Verify(id: string | number, reference: string, file?: File) {
    const formData = new FormData();

    formData.append("_method", "PUT");
    formData.append("reference", reference);
    formData.append("media[0]", file);
    return api({
      method: file ? "POST" : "PUT",
      url: getEndpoint("submitPayment", {
        transaction_id: id,
      }),
      data: file ? formData : { reference: reference },
    });
  }
}
export default Transaction;
