//Built-in Import
import * as React from "react";
//External Import
import { Stack, Typography } from "@mui/material";
//Internal Import
interface IFinancialInformationCardProps {
  amount: number | string;
  description: string;
  color: string;
}

const FinancialInformationCard: React.FunctionComponent<
  IFinancialInformationCardProps
> = ({ amount, description, color }) => {
  return (
    <Stack
      sx={{
        minHeight: 115,
        borderRadius: "12px",
        background: color,
      }}
      justifyContent="center"
      alignItems="center"
    >
      <Typography variant="largeBold" color="white">
        {amount}
      </Typography>
      <Typography variant="smallBold" color="white">
        {description}
      </Typography>
    </Stack>
  );
};

export default FinancialInformationCard;
