import * as React from "react"

import { Button } from "@mui/material"
import NoItemsWasFound from "."
import WarningIcon from "@mui/icons-material/Warning"
import routes from "@/navigation/routes"
import { useGlobalContext } from "@/context/global-context"
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"

interface ICompleteProfileRoleAlertProps {
  title?: string
}

const CompleteProfileRoleAlert: React.FunctionComponent<
  ICompleteProfileRoleAlertProps
> = ({ title }) => {
  const { closeModal } = useGlobalContext()

  const { t } = useTranslation(["common"])

  const navigator = useNavigate()

  const handleBack = () => {
    navigator(-1)
    closeModal()
  }

  return (
    <NoItemsWasFound
      title={title ? title : ""}
      icon={<WarningIcon />}
      afterTitle={
        <Button onClick={handleBack} variant="outlined">
          {t("common:back")}
        </Button>
      }
    />
  )
}

export default CompleteProfileRoleAlert
