import { PaletteExtending } from "../theme/declarations";
//External import
import { PaletteOptions } from "@mui/material";
/**
 * Light palette
 */

export type ICustomizedPalette = PaletteOptions & PaletteExtending;

const palette = (mode: "dark" | "light" = "light") => ({
  mode,
  purple: {
    main: "rgb(135, 42, 178)",
  },
  primary: {
    main: "#E29314",
    light: "#E29314",
    dark: "#E29314",
  },
  secondary: {
    main: "#E27714",
  },
  warning: {
    main: "#FCBF63",
    light: "#FFD02A",
  },
  success: {
    main: "#28C76F",
  },
  label: {
    main: "#000",
  },
  text: {
    primary: mode === "light" ? "#5E5873" : "rgba(255,255,255,0.6)",
    secondary: mode === "light" ? "#6E6B7B" : "rgba(255,255,255,0.6)",
  },
  grey: {
    100: "#f5f5f5",
  },
  paper: {
    main: mode === "light" ? "#f5f5f5" : "#000",
  },
  common: {
    white: mode === "light" ? "rgba(255,255,255,1)" : "rgba(0,0,0,0.6)",
    black: mode === "light" ? "#000" : "rgba(255,255,255,1)",
  },
});

export default palette;
