// Built-in import
import * as React from "react"
// External Import
import { useTranslation } from "react-i18next"
import { Stack, TextField } from "@mui/material"
import { useFormik } from "formik"
import * as Yup from "yup"
// Internal Import
import ModalContentWrapper from "@/components/shared/modal/modal-content-wrapper"
import { TitleWithValue } from "@/components/shared"

interface IEnterPaymentDetailsProps {}

const EnterPaymentDetails: React.FunctionComponent<IEnterPaymentDetailsProps> =
  (props) => {
    // Getting Translation object
    const { t } = useTranslation(["common"])
    // Formik
    const { values, handleSubmit, getFieldProps, errors, touched } = useFormik({
      initialValues: {
        transaction_tracking_code: ""
      },
      validationSchema: Yup.object().shape({
        transaction_tracking_code: Yup.string().required(
          t("validation:this_field_is_required")
        )
      }),
      onSubmit: (values, actions) => {}
    })

    return (
      <ModalContentWrapper title={t("common:payment_details")}>
        <Stack
          sx={{
            width: "100%",
            [`& .MuiFormControl-root`]: {
              width: "100%"
            }
          }}
        >
          <TitleWithValue
            alignItems="flex-start"
            direction="column"
            title={t("common:transaction_tracking_code")}
            value={<TextField />}
          />
        </Stack>
      </ModalContentWrapper>
    )
  }

export default EnterPaymentDetails
