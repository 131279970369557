import * as React from "react";

import { HomeIcon, PersonalCardIcon } from "@/components/icons";
import { Stack, useTheme } from "@mui/material";
import { RegisterTypeBox } from "@/components/shared";
import { useTranslation } from "react-i18next";
import { useAuth } from "@/hook";

interface IUserTypesCardsProps {
  handleChange: (value: "Real" | "Legal") => void;
  registerType: "Real" | "Legal";
}

export const UserTypesCards: React.FunctionComponent<IUserTypesCardsProps> = ({
  handleChange,
  registerType,
}) => {
  const { isRole } = useAuth();
  const isInvestor = isRole("investor");
  //Getting translation object
  const { t } = useTranslation();
  //getting theme from mui
  const theme = useTheme();

  let registerWaysItems: any[] = [
    {
      id: 1,
      icon: <HomeIcon color={theme.palette.primary.main} />,
      title: t("legal"),
      description: t("legal_description"),
      value: "Legal",
    },
    ...(isInvestor
      ? [
          {
            id: 2,
            icon: <PersonalCardIcon color={theme.palette.primary.main} />,
            title: t("real"),
            description: t("real_description"),
            value: "Real",
          },
        ]
      : []),
  ];

  return (
    <Stack
      direction="row"
      width="100%"
      justifyContent={{ xs: "center", md: "center" }}
      spacing={{ xs: 3, md: 1 }}
      my={4}
      sx={{ "& a": { textDecoration: "none" } }}
    >
      {registerWaysItems.map((item) => {
        const isActive = item.value === registerType;
        return (
          <RegisterTypeBox
            key={item.id}
            item={item}
            handleChange={(value) => handleChange(value)}
            isActive={isActive}
          />
        );
      })}
    </Stack>
  );
};
