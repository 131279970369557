// Built-in import
import * as React from "react"

// External import
import { Stack, SxProps, Theme, Typography } from "@mui/material"

interface IUploader {
  title: React.ReactNode
  description?: string
  hiddenInput?: any
  sx?: SxProps<Theme>
  onChange?: (val: File) => void
}

const Uploader: React.FC<IUploader> = ({
  title,
  description,
  hiddenInput,
  sx,
  onChange
}) => {
  return (
    <Stack
      sx={{
        cursor: "pointer",
        width: ["100%", "400px", "100%", "400px"],
        border: "2px dashed",
        borderRadius: 3,
        borderColor: (t) => t.palette.primary.main,
        py: 3,
        px: 1,
        textAlign: "center",
        ...sx
      }}
      alignItems="center"
      justifyContent={"center"}
      spacing={1}
      component="label"
    >
      {hiddenInput}
      {typeof title === "string" ? (
        <Typography variant="bodyBold" color="primary">
          {title}
        </Typography>
      ) : (
        title
      )}
      <Typography
        variant="extraSmallRegular"
        color="text.primary"
        lineHeight={"22px"}
      >
        {description}
      </Typography>
    </Stack>
  )
}

export default Uploader
