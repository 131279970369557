import * as React from "react";

import {
  IconButton,
  Stack,
  SxProps,
  Theme,
  Typography,
  Box,
  alpha,
} from "@mui/material";

import { DeleteIcon } from "@/components/icons";
import ImageOutlinedIcon from "@mui/icons-material/ImageOutlined";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import { useImageUploaded } from "@/hook";
import { useTranslation } from "react-i18next";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";

interface IGalleryItemProps {
  item: any;
  index?: number;
  handleRemove: (index: number) => void;
  onItemClick?: (item: any, index: number) => void;
  isPrimary?: boolean;
  hasPrimaryChange?: boolean;
  hiddenInput?: React.ReactElement;
  uploadOnce?: boolean;
  showActions?: boolean;
}

const GalleryItem: React.FunctionComponent<IGalleryItemProps> = ({
  item,
  index,
  handleRemove,
  onItemClick,
  isPrimary = false,
  hiddenInput,
  uploadOnce = false,
  hasPrimaryChange = false,
  showActions = true,
}) => {
  // Style Main Image
  const styleBoxImage: SxProps<Theme> = {
    width: "100%",
    height: "220px",
    borderRadius: "12px",
    overflow: "hidden",
    position: "relative",
    boxShadow: "0px 3px 10px #99999960",
    cursor: "pointer",
    "& img": { objectFit: "cover", width: "100%", height: "100%" },
    [`&:hover .cover-symbol`]: {
      opacity: 1,
    },
  };

  // Style main image
  const styleMainImage = {
    position: "absolute",
    bottom: 7,
    left: 7,
    background: (t) => t.palette.common.white,
    px: 1,
    py: 0.3,
    borderRadius: 2,
    opacity: isPrimary ? 1 : 0,
    transition: "0.2s all",
  };

  const styleIconBtn = {
    position: "absolute",
    top: 8,
    right: 8,
  };

  const editIconBtnStyle: SxProps<Theme> = {
    position: "absolute",
    width: 40,
    height: 40,
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    top: 8,
    borderRadius: "50%",
    right: 48,
    "&:hover": {
      bgcolor: (t) => alpha(t.palette.common.black, 0.04),
    },
  };

  //Getting translation object
  const { t } = useTranslation(["common"]);

  const mediaSource = useImageUploaded(item);

  let fileFormat = React.useMemo(() => {
    if (!item) return;
    return item;
  }, [item]);

  //Handle preview of file
  const previewHandler = React.useCallback(() => {
    if (!fileFormat) return null;
    switch (fileFormat?.type || fileFormat?.mime_type) {
      case "image/jpeg":
      case "image/png":
      case "image/gif":
        return <img src={mediaSource} alt="" />;
      case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
      case "application/pdf":
      case "application/msword":
      case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
      case "application/x-rar-compressed":
      case "application/zip":
      case "text/plain":
        return (
          <Stack
            sx={{ width: "100%", height: "100%" }}
            alignItems="center"
            justifyContent={"center"}
            spacing={1}
          >
            <InsertDriveFileIcon sx={{ fontSize: 64 }} />
            {Boolean(fileFormat?.name) && (
              <Typography variant="extraSmallMedium">
                {fileFormat.name}
              </Typography>
            )}
          </Stack>
        );
      default:
        return (
          <Stack
            sx={{ width: "100%", height: "100%" }}
            alignItems="center"
            justifyContent={"center"}
            spacing={1}
          >
            {Boolean(fileFormat?.name) && (
              <Typography variant="extraSmallMedium">
                {fileFormat.name}
              </Typography>
            )}
          </Stack>
        );
    }
  }, [fileFormat]);

  if (!mediaSource) return null;

  return (
    <Stack
      sx={styleBoxImage}
      alignItems="end"
      onClick={() => onItemClick(item, index)}
    >
      {showActions && (
        <React.Fragment>
          <IconButton
            sx={styleIconBtn}
            onClick={(e) => {
              e.stopPropagation();
              handleRemove(index);
            }}
          >
            <DeleteIcon />
          </IconButton>
          {uploadOnce && (
            <Box sx={editIconBtnStyle} component={"label"}>
              <CreateOutlinedIcon />
              {hiddenInput}
            </Box>
          )}
        </React.Fragment>
      )}
      {previewHandler()}
      {Boolean(hasPrimaryChange) && (
        <Stack
          className="cover-symbol"
          sx={styleMainImage}
          direction="row"
          spacing={0.5}
        >
          <ImageOutlinedIcon color="primary" />
          <Typography variant="smallBold">{t("common:main_cover")}</Typography>
        </Stack>
      )}
    </Stack>
  );
};

export default GalleryItem;
