import { IMedia } from "@/types/media";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { Button, Stack, alpha } from "@mui/material";
import { Link } from "react-router-dom";
import InsertLinkIcon from "@mui/icons-material/InsertLink";
import { t } from "i18next";

type Props = {
  document: File;
};

export default function Doc({ document }: Props) {
  return (
    <Stack
      sx={{
        height: 240,
        transition: "0.3s all",
        borderRadius: 3,
        backgroundColor: (t) => alpha(t.palette.common.black, 0.05),
        ":hover": {
          backgroundColor: (t) => alpha(t.palette.common.black, 0.1),
        },
      }}
    >
      <Stack
        sx={{ height: "100%" }}
        spacing={2}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <AttachFileIcon />
        <span className='whitespace-pre-wrap w-[70%] text-ellipsis overflow-hidden'>
          {document.name}
        </span>
        <a href={URL.createObjectURL(document)} target='_blank'>
          <Button type='button' startIcon={<InsertLinkIcon />}>
            {t("open_file")}
          </Button>
        </a>
      </Stack>
    </Stack>
  );
}
