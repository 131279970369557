// Built-in import
import * as React from "react";

// External Import
import {
  Stack,
  SxProps,
  Theme,
  alpha,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import FinancialAccordion from "./mobile";
import FinancialTableFilter from "./filter";
import { ITableItems } from "@/types/table";
// Internal Import
import { MyTable } from "..";
import { useTranslation } from "react-i18next";

interface IFinancialTableProps {
  items: any[];
  tableHeadItems?: ITableItems[];
  hasFilter?: boolean;
  handleReset?: () => void;
  handleChangeQueries?: (key: string, val: string) => void;
  values?: any;
  loading?: boolean;
}

export const customTableStyle: SxProps<Theme> = {
  border: "1px solid",
  borderRadius: "12px",
  overflow: "hidden",
  borderCollapse: "initial",
  borderColor: (t) => ` ${alpha(t.palette.common.black, 0.12)} !important`,
  [`& thead tr th`]: {
    borderLeft: "1px solid",
    borderBottom: "1px solid ",
    borderColor: (t) => alpha(t.palette.common.black, 0.12),
    [`&:first-of-type`]: {
      borderLeftColor: "transparent",
      textAlign: "center",
    },
  },
  [`& tbody tr`]: {
    [`& td`]: {
      fontSize: "14px",
      fontWeight: 500,
      borderLeft: "1px solid  !important",
      borderBottomWidth: "1px !important",
      borderColor: (t) => ` ${alpha(t.palette.common.black, 0.12)} !important`,
      color: (t) => t.palette.text.primary,
      p: 2,
      [`&:first-of-type`]: {
        borderLeftColor: "transparent  !important",
        textAlign: "center",
      },
    },
    [`&:last-child`]: {
      [`& td`]: {
        borderBottomColor: "transparent !important",
      },
    },
  },
};
const FinancialTable: React.FunctionComponent<IFinancialTableProps> = ({
  items = [],
  tableHeadItems = [],
  hasFilter = true,
  handleChangeQueries,
  handleReset,
  values = {},
  loading = false,
}) => {
  // Getting Translation object
  const { t } = useTranslation(["common"]);

  //getting mui theme
  const theme = useTheme();
  //check for mobile view
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Stack
      spacing={0}
      sx={{
        [`& .DatePicker input`]: {
          borderRadius: "12px !important",
        },
      }}
    >
      {hasFilter ? (
        <FinancialTableFilter
          values={values}
          handleReset={handleReset}
          handleChangeQueries={handleChangeQueries}
        />
      ) : null}
      {isMobile ? (
        <FinancialAccordion items={items} />
      ) : (
        <MyTable
          sxProps={{
            ...customTableStyle,
          }}
          items={items}
          tableHeadItems={tableHeadItems}
          loading={loading}
        />
      )}
    </Stack>
  );
};

export default FinancialTable;
