import { api, getEndpoint } from "@/api"

import { IEventType } from "@/types/event"
import { getUrlWithQueryParams } from "@/utils"

class Report {
  public static Create(
    id: string,
    type: string,
    data: Object,
    media: File[] = []
  ) {
    const formData = new FormData()
    for (let key of Object.keys(data)) {
      formData.append(key, data[key])
    }

    //Attach  media
    for (let index in media) {
      const t = media[index]
      formData.append(`media[${index}]`, t)
    }

    return api.post(
      getUrlWithQueryParams(getEndpoint("events"), {
        project: id,
        type
      }),
      formData
    )
  }
  public static RecieveProjectEvents(
    projectId: string,
    type: IEventType,
    other: any
  ) {
    return api.get(
      getUrlWithQueryParams(getEndpoint("events"), {
        project: projectId,
        type,
        ...other
      })
    )
  }
  public static RecieveSingleEvent(eventId: number) {
    return api.get(
      getUrlWithQueryParams(getEndpoint("event", { event_id: eventId }), {
        expand: "project,media"
      })
    )
  }
  public static DeleteEvent(type: IEventType, eventId: number) {
    return api.delete(
      getUrlWithQueryParams(getEndpoint("event", { event_id: eventId }), {
        type
      })
    )
  }
}
export default Report
