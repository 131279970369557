import * as React from "react";

interface ISuccessIconProps {
  color?: string;
  width?: number;
  height?: number;
}

const SuccessIcon: React.FunctionComponent<ISuccessIconProps> = ({
  color = "white",
  width = 24,
  height = 24,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M23.791 12.9062V12.0063C23.791 11.4062 23.391 11.0063 22.791 11.0063C22.191 11.0063 21.791 11.4062 21.791 12.0063V12.9062C21.791 17.9062 17.791 21.9062 12.791 21.9062C7.79102 21.9062 3.79102 17.9062 3.79102 12.9062C3.79102 7.90625 7.79102 3.90625 12.791 3.90625C14.091 3.90625 15.291 4.20625 16.491 4.70625C16.991 4.90625 17.591 4.70625 17.791 4.20625C17.991 3.70625 17.791 3.10625 17.291 2.90625C15.891 2.20625 14.291 1.90625 12.791 1.90625C6.69102 1.90625 1.79102 6.80625 1.79102 12.9062C1.79102 19.0063 6.69102 23.9062 12.791 23.9062C18.891 23.9062 23.791 19.0063 23.791 12.9062ZM23.091 3.20625C23.491 2.80625 24.091 2.80625 24.491 3.20625C24.891 3.60625 24.891 4.20625 24.491 4.60625L13.491 15.6062C13.291 15.8062 12.991 15.9062 12.791 15.9062C12.591 15.9062 12.291 15.8062 12.091 15.6062L9.09102 12.6062C8.69102 12.2063 8.69102 11.6062 9.09102 11.2063C9.49102 10.8063 10.091 10.8063 10.491 11.2063L12.791 13.5063L23.091 3.20625Z"
        fill={color}
      />
      <mask
        id="mask0_295_12607"
        maskUnits="userSpaceOnUse"
        x="1"
        y="1"
        width="24"
        height="23"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M23.791 12.9062V12.0063C23.791 11.4062 23.391 11.0063 22.791 11.0063C22.191 11.0063 21.791 11.4062 21.791 12.0063V12.9062C21.791 17.9062 17.791 21.9062 12.791 21.9062C7.79102 21.9062 3.79102 17.9062 3.79102 12.9062C3.79102 7.90625 7.79102 3.90625 12.791 3.90625C14.091 3.90625 15.291 4.20625 16.491 4.70625C16.991 4.90625 17.591 4.70625 17.791 4.20625C17.991 3.70625 17.791 3.10625 17.291 2.90625C15.891 2.20625 14.291 1.90625 12.791 1.90625C6.69102 1.90625 1.79102 6.80625 1.79102 12.9062C1.79102 19.0063 6.69102 23.9062 12.791 23.9062C18.891 23.9062 23.791 19.0063 23.791 12.9062ZM23.091 3.20625C23.491 2.80625 24.091 2.80625 24.491 3.20625C24.891 3.60625 24.891 4.20625 24.491 4.60625L13.491 15.6062C13.291 15.8062 12.991 15.9062 12.791 15.9062C12.591 15.9062 12.291 15.8062 12.091 15.6062L9.09102 12.6062C8.69102 12.2063 8.69102 11.6062 9.09102 11.2063C9.49102 10.8063 10.091 10.8063 10.491 11.2063L12.791 13.5063L23.091 3.20625Z"
          fill={color}
        />
      </mask>
      <g mask="url(#mask0_295_12607)">
        <rect
          x="0.791016"
          y="0.90625"
          width={width}
          height={height}
          fill={color}
        />
      </g>
    </svg>
  );
};

export default SuccessIcon;
