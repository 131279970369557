// Built-in import
import * as React from "react"

// External import
import { Stack, SxProps } from "@mui/material"

interface IStructureCard {
  cardBgColor: string
  cardIcon: React.ReactElement
  children?: React.ReactNode
  sxProps?: SxProps
}

const StructureCard: React.FC<IStructureCard> = ({
  cardBgColor,
  cardIcon,
  sxProps,
  children
}) => {
  return (
    <Stack
      direction={{ xs: "column", sm: "row" }}
      sx={{
        bgcolor: cardBgColor,
        minHeight: "100%",
        height: ["fit-content", "100%"],
        maxWidth: "100%",
        width: "100%",
        borderRadius: "10px",
        overflowX: "hidden",
        mb: 2,
        ...sxProps
      }}
      alignItems="center"
    >
      {cardIcon && (
        <Stack
          sx={{
            minWidth: "40px",
            width: "40px",
            height: "100%",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
            color: "white",
            py: [0.5, 0],
            [`& svg`]: {
              color: (t) => t.palette.common.white
            }
          }}
        >
          {cardIcon}
        </Stack>
      )}
      {children}
    </Stack>
  )
}

export default StructureCard
