import React, { useEffect, useRef, useState } from "react"
import { Stack, TextField, TextFieldProps } from "@mui/material"

interface OTPPureHandlerProps extends Omit<TextFieldProps, "onChange"> {
  length: number
  onChange?: (otp: string) => void
  onCompleted?: (otp: string) => void
}

const OTPPureHandler = (props: OTPPureHandlerProps & any) => {
  const { length, onChange, onCompleted, ...rest } = props
  const [otp, setOTP] = useState<string[]>(Array(length).fill(""))
  const refs = useRef<(HTMLInputElement | null)[]>([])
  const lastRef = useRef<HTMLInputElement | null>(null)

  useEffect(() => {
    refs.current[0]?.focus()
  }, [])

  const handleChange = (index: number, value: string) => {
    const newOTP = [...otp]
    if (/^\d$/.test(value)) {
      newOTP[index] = value
    } else {
      newOTP[index] = ""
    }
    setOTP(newOTP)
    if (value === "" && index > 0) {
      refs.current[index - 1]?.focus()
    } else if (index < length - 1 && value !== "") {
      refs.current[index + 1]?.focus()
    }
    if (newOTP.every((v) => v !== "")) {
      onCompleted?.(newOTP.join(""))
    }
    if (onChange) onChange(newOTP.join(""))
  }

  const handleKeyDown = (
    index: number,
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === "Backspace" && index > 0 && otp[index] === "") {
      refs.current[index - 1]?.focus()
    }
  }

  const handlePaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
    event.preventDefault()
    const pasteData = event.clipboardData.getData("text/plain").slice(0, length)
    const newOTP = [...otp]
    pasteData.split("").forEach((value, index) => {
      if (/^\d$/.test(value)) {
        newOTP[index] = value
      }
    })
    setOTP(newOTP)
    refs.current[pasteData.length]?.focus()
    if (newOTP.every((v) => v !== "")) {
      onCompleted?.(newOTP.join(""))
    }
    onChange(newOTP.join(""))
  }

  return (
    <Stack
      direction="row"
      alignItems="center"
      sx={{ direction: "rtl", mx: "-8px !important" }}
    >
      {otp.map((value, index) => (
        <TextField
          key={index}
          inputRef={(el) => {
            refs.current[index] = el
            if (index === length - 1) {
              lastRef.current = el
            }
          }}
          value={value}
          onChange={(event: any) => handleChange(index, event.target.value)}
          onKeyDown={(event: any) => handleKeyDown(index, event)}
          onPaste={handlePaste}
          inputProps={{
            maxLength: 1,
            style: {
              textAlign: "center"
            }
          }}
          sx={{ mx: "8px !important" }}
          {...rest}
        />
      ))}
    </Stack>
  )
}

export default OTPPureHandler
