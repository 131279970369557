import * as React from "react"
import { currentStepSelector } from "@/store/slices/auth/selectors"
import { useAppSelector } from "@/store/store"
import { CompleteInfoType } from "@/types/auth"

import { SajamSystemInformation, UserTypeSelection } from "./partials"
import { useDispatch } from "react-redux"
import {
  actionSetCurrentStep,
  actionSetSelectedType,
} from "@/store/slices/auth"

import { RealSuccessRegistration } from "./real-investor"
import {
  BasicInformation,
  CompleteInformation,
  LegalSuccessRegistration,
  UploadDocuments,
} from "./legal-investor"

interface ICompleteUserInformationProps {
  onClose?: () => void
}

const CompleteUserInformation: React.FunctionComponent<
  ICompleteUserInformationProps
> = ({ onClose }) => {
  //getting currentStep from redux
  const currentStep = useAppSelector(currentStepSelector)
  //getting redux dispatcher
  const dispatcher = useDispatch()
  const [step, setStep] = React.useState<CompleteInfoType | null>(currentStep)

  const handleChangeStep = (type: "Real" | "Legal") => {
    if (type === CompleteInfoType.Real) {
      dispatcher(actionSetCurrentStep(CompleteInfoType.SajamSystemInformation))
    } else {
      dispatcher(actionSetCurrentStep(CompleteInfoType.BasicInformation))
    }
    dispatcher(actionSetSelectedType(type as "Real" | "Legal"))
  }

  React.useEffect(() => {
    setStep(currentStep)
  }, [currentStep])

  const RenderContent = () => {
    switch (step) {
      case CompleteInfoType.SajamSystemInformation:
        return <SajamSystemInformation onClose={onClose} />
      case CompleteInfoType.RealUpdatedSuccess:
        return <RealSuccessRegistration />
      case CompleteInfoType.BasicInformation:
        return <BasicInformation />
      case CompleteInfoType.CompleteInformation:
        return <CompleteInformation />
      case CompleteInfoType.UploadDocuments:
        return <UploadDocuments />
      case CompleteInfoType.LegalUpdatedSuccess:
        return <LegalSuccessRegistration />
      default:
        return (
          <UserTypeSelection
            handleChangeStep={(type) =>
              handleChangeStep(type as "Real" | "Legal")
            }
          />
        )
    }
  }

  return <RenderContent />
}

export default CompleteUserInformation
