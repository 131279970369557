import * as React from "react";
import TitleWithValue from "../../title-with-value";

interface IFinancialDetailItemProps {
  title: string;
  value: string | number | React.ReactNode;
}

const FinancialDetailItem: React.FunctionComponent<
  IFinancialDetailItemProps
> = ({ title, value }) => {
  return (
    <TitleWithValue
      title={title}
      variantValue={"smallMedium"}
      hasDoubleDot={false}
      value={value}
      justifyContent={"space-between"}
    />
  );
};

export default FinancialDetailItem;
