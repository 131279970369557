// Built-i import
import * as React from "react";
// External import
import { Stack, Typography, alpha } from "@mui/material";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import { IServerProperty } from "@/store/slices/property";
import { useTranslation } from "react-i18next";
import { humanDate } from "@/utils";

interface ITimeCardProps {
  item: IServerProperty;
}

const TimeCard: React.FC<ITimeCardProps> = ({ item }) => {
  // Getting translation object
  const { t } = useTranslation(["common"]);

  return (
    <Stack direction="row" spacing={1.5} alignItems={"center"}>
      <Stack
        sx={{
          background: (t) => alpha(t.palette.primary.main, 0.15),
          p: 1,
          borderRadius: 1,
        }}
      >
        <CalendarTodayOutlinedIcon color="primary" />
      </Stack>
      <Stack spacing={[0.5, , 0]}>
        <Typography variant="extraSmallBold" color="text.primary">
          {t("common:the_deadline_for_fundraising")}
        </Typography>
        <Typography variant="extraSmallMedium" color="grey.400">
          {humanDate(item.expires_at)}
        </Typography>
      </Stack>
    </Stack>
  );
};

export default TimeCard;
