import * as React from "react";
import { Accordion, AccordionDetails, Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import CustomAccordionSummary from "./partial/accordion-summary";

interface IMyAccordionProps {
  id: string | number;
  summary: React.ReactNode;
  children: React.ReactNode;
}

const MyAccordion: React.FunctionComponent<IMyAccordionProps> = ({
  id,
  summary,
  children,
}) => {
  const [expanded, setExpanded] = React.useState(false);

  const handleChangeExpand = React.useCallback(() => {
    setExpanded((crt) => !crt);
  }, []);
  //Getting translation object
  const { t } = useTranslation();

  return (
    <Box>
      <Accordion square={true} expanded={expanded} id={`panel${id}d-accordion`}>
        {/*Accordion Summary part */}
        <CustomAccordionSummary
          expanded={expanded}
          handleChange={handleChangeExpand}
        >
          {summary}
        </CustomAccordionSummary>
        {/*Accordion Summary part */}
        <AccordionDetails>{children}</AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default MyAccordion;
