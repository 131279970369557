//Built-in import
import * as React from "react";

import { ITicket, ITicketMessage } from "@/types/ticket";
//External import
import { Stack, alpha } from "@mui/material";
import { api, getEndpoint } from "@/api";

//Internal import
import ChatboxMeessages from "./messages";
import MessageInputHandler from "./input";
import { getUrlWithQueryParams } from "@/utils";
import { toaster } from "../toaster";

interface IChatBoxProps {
  ticket: ITicket | null;
  items: ITicketMessage[];
  renderAgain?: () => void;
}

//Initial message text
const initiailMessageText = "";

const ChatBox: React.FunctionComponent<IChatBoxProps> = ({
  ticket,
  items = [],
  renderAgain,
}) => {
  //handling message text
  const [messageText, setMessageText] = React.useState(initiailMessageText);

  //Messages
  const [messages, setMessages] = React.useState<ITicketMessage[]>([]);

  //handling message method
  const handleSetMessageText = (val: string) => {
    setMessageText(val);
  };
  React.useEffect(() => {
    setMessages(items);
  }, [items]);

  //Ref of chatbox messages
  const chatboxRef = React.useRef<HTMLDivElement>(null);

  //Handling messages
  const [loading, setLoading] = React.useState(false);
  const handleAddMessage = React.useCallback(
    async (message: string = messageText, attachment: File | null = null) => {
      //When we have no message text
      if (!message) return;

      setLoading(true);
      //Posting a message
      try {
        const formData = new FormData();
        formData.append("message", message);
        if (attachment) formData.append("attachments[0]", attachment);
        const res = await api.post(
          getUrlWithQueryParams(
            getEndpoint("ticketMessages", {
              id: ticket.id,
            }),
            {
              expand: "media",
            }
          ),
          formData
        );

        setMessages((crt) => [...crt, res.data?.data?.item as ITicketMessage]);

        //Reset message
        setMessageText(initiailMessageText);
        //Go bottom of chatbox ref
        if (chatboxRef.current !== null) {
          {
            //Timeout for scrolling after adding
            setTimeout(() => {
              chatboxRef.current.scrollTop = chatboxRef.current?.scrollHeight;
              // chatboxRef.current.scrollTop = 0
            }, 500);
          }
        }
      } catch (e) {
        toaster.error(e);
      } finally {
        setLoading(false);
      }
    },
    [messageText, chatboxRef]
  );
  return (
    <Stack
      sx={{
        border: 1,
        borderColor: (t) => alpha(t.palette.common.black, 0.12),
        borderRadius: 2,
        overflow: "hidden",
        height: "auto",
      }}
    >
      <ChatboxMeessages ref={chatboxRef} items={messages} />
      {/* IsTypingSample */}
      {/* <ChatboxPartialIsSending username="Omid Haghshenas" /> */}
      {/* IsTypingSample */}
      <MessageInputHandler
        messageText={messageText}
        handleSetMessageText={handleSetMessageText}
        addMessage={handleAddMessage}
      />
    </Stack>
  );
};

export default ChatBox;
