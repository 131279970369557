// Built-in import
import * as React from "react"

import FutureCardContent from "./future"
import { IEvent } from "@/types/event"
// External import
import { Stack } from "@mui/material"
// Internal import
import { TitleWithValue } from "@/components/shared"
import { useTranslation } from "react-i18next"

interface IChildrenDefaultEvent {
  item?: IEvent
}

const ChildrenDefaultEvent: React.FC<IChildrenDefaultEvent> = ({ item }) => {
  // Getting translation object
  const { t } = useTranslation(["common"])

  if (item.expires_at) return <FutureCardContent item={item} />

  return (
    <Stack direction="column" pb={1} spacing={1}>
      <TitleWithValue
        title={item.title}
        value={""}
        hasDoubleDot={false}
        justifyContent="space-between"
        colorTitle={"text.secondary"}
        variantTitle="bodyBold"
        variantValue={"bodyMedium"}
        alignItems="flex-start"
      />
      {item.revenue_percent && (
        <TitleWithValue
          title={t("common:depsoited_profit")}
          value={`${item.revenue_percent}%`}
          hasDoubleDot={false}
          justifyContent="space-between"
          colorTitle={"text.secondary"}
          variantTitle="bodyBold"
          variantValue={"bodyMedium"}
          alignItems="flex-start"
        />
      )}
      <TitleWithValue
        title={item.description}
        value={""}
        hasDoubleDot={false}
        justifyContent="space-between"
        colorTitle={"text.secondary"}
        variantTitle="bodyBold"
        variantValue={"bodyMedium"}
        alignItems="flex-start"
      />
    </Stack>
  )
}

export default ChildrenDefaultEvent
