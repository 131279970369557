import * as React from "react"

import { Box, IconButton, Stack, Typography } from "@mui/material"

import { AllVariants } from "@/mui/theme/declarations"
import CloseIcon from "@mui/icons-material/Close"
import { useGlobalContext } from "@/context/global-context"

interface IModalContentWrapperProps {
  title?: string
  children: React.ReactNode
  titleVariant?: AllVariants
  onFetch?: () => void
  onClose?: () => void
}

const ModalContentWrapper: React.FunctionComponent<
  IModalContentWrapperProps
> = ({ title, children, titleVariant = "bodyBold", onFetch, onClose }) => {
  // Getting global context
  const { closeModal } = useGlobalContext()

  const closeModalHandler = React.useCallback(() => {
    closeModal()
    if (onClose) onClose()
    if (onFetch) onFetch()
  }, [closeModal, onClose, onFetch])

  return (
    <Stack spacing={4} className="modal-content-wrapper">
      {Boolean(title) && (
        <Stack
          direction="row"
          justifyContent="center"
          sx={{
            position: "sticky",
            top: 0,
            px: 1,
            zIndex: 1,
            bgcolor: (t) => t.palette.common.white,
            borderBottom: 1,
            borderColor: (t) => t.palette.divider,
          }}
          className="title-holder"
        >
          <Typography
            variant={titleVariant}
            color="text.primary"
            sx={{
              py: 2,
            }}
          >
            {title}
          </Typography>
          {/* Closing modal */}
          <IconButton
            onClick={closeModalHandler}
            sx={{
              position: "absolute",
              top: "50%",
              transform: "translateY(-50%)",
              right: 10,
            }}
          >
            <CloseIcon />
          </IconButton>
          {/* Closing modal */}
        </Stack>
      )}
      <Box className="modal-content-children">{children}</Box>
    </Stack>
  )
}

export default ModalContentWrapper
