import * as React from "react"

import { IconButton, InputAdornment, Stack, TextField } from "@mui/material"
import { TextFieldNumberFormat, TitleWithValue } from "@/components/shared"

import AddIcon from "@mui/icons-material/Add"
import RemoveIcon from "@mui/icons-material/Remove"

// Internal import

interface ICounter {
  title: string
  placeholder?: string
  handleChangeShare?: (share: string) => void
  onErrorMax?: (max: number) => void
  step?: number
  max?: number
}

const Counter: React.FC<ICounter> = ({
  title,
  placeholder,
  handleChangeShare,
  step = 1,
  max,
  onErrorMax
}) => {
  // number calculator
  const [shareCount, setShareCount] = React.useState<number | string>(0)

  const handleIncrease = () => {
    setShareCount((prevValue) => {
      let currentValue: string = "" + prevValue
      let convertedVal = currentValue.replaceAll(",", "")
      let newValue = Math.min(+convertedVal + +step, 10000000000000)

      if (max && newValue > max) {
        newValue = +prevValue
        if (onErrorMax) onErrorMax(max)
      } else {
        if (handleChangeShare) handleChangeShare("" + newValue)
      }

      return newValue
    })
  }

  const handleDecrease = () => {
    setShareCount((prevValue) => {
      let currentValue: string = "" + prevValue
      let convertedVal = currentValue.replaceAll(",", "")
      let newValue = Math.max(+convertedVal - +step, 1)
      if (+prevValue < 1) return 1
      if (handleChangeShare) handleChangeShare("" + newValue)
      return newValue
    })
  }

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value
    if (event.target.value === "") {
      setShareCount("")
      if (handleChangeShare) handleChangeShare("0")
    } else {
      setShareCount(newValue)
      if (handleChangeShare) handleChangeShare(newValue)
    }
  }

  return (
    <Stack
      spacing={1.5}
      sx={{ "& .MuiInputBase-root": { px: "0 !important" } }}
    >
      <Stack spacing={0.5}>
        <TitleWithValue
          direction="column"
          alignItems="flex-start"
          variantTitle="bodyMedium"
          hasDoubleDot={false}
          title={title}
          value={
            <TextFieldNumberFormat
              value={"" + shareCount}
              onChange={handleInputChange}
              fullWidth
              size="small"
              placeholder={placeholder}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleIncrease}>
                      <AddIcon color="success" />
                    </IconButton>
                    <IconButton onClick={handleDecrease}>
                      <RemoveIcon color="error" />
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
          }
        />
      </Stack>
    </Stack>
  )
}

export default Counter
