// Built-in import
import * as React from "react"

import {
  AdditionalInformation,
  BasicPlanInformation,
  CapitalizatinDetails,
  ExecutiveCalender,
} from "./steps"
import { useAuth, useQuery } from "@/hook"
import { useNavigate, useParams } from "react-router-dom"

import { AddPropertyWizard } from "./wizard"
import CompleteProfileRoleAlert from "../no-items/complete-profile-role"
import CompleteProfileTypeAlert from "../no-items/complete-profile-type"
import { IEvent } from "@/types/event"
import { IServerProperty } from "@/store/slices/property"
import { Report } from "@/services"
import { Stack } from "@mui/material"
import { propertySelector } from "@/store/slices/property/selectors"
import { toaster } from "../toaster"
import { useAppSelector } from "@/store/store"
import { useTranslation } from "react-i18next"
import routes from "@/navigation/routes"

// External import

interface IAddNewProperty {}

const AddNewProperty: React.FC<IAddNewProperty> = () => {
  const [wizardStep, setWizardStep] = React.useState(0)

  //Getting translation
  const { t } = useTranslation(["common"])

  //Getting user
  const { user, isRole } = useAuth()

  //access to query type
  const navigator = useNavigate()
  const query = useQuery()
  //get Type of step
  const stepType = query.get("type")
  //checking for that we are in edit page or not
  const { id: isEdit } = useParams()

  const [receivedEvents, setReceivedEvents] = React.useState<IEvent[]>([])

  React.useEffect(() => {
    if (stepType) setWizardStep(+stepType)
  }, [stepType])

  //Getting property
  const property = useAppSelector(propertySelector) as IServerProperty
  //getting react router navigator
  const handleFetchAllEvents = async () => {
    if (property.id) {
      try {
        const res = await Report.RecieveProjectEvents(property?.id, "all", {})
        const newEvents = res.data.data.items.data
        setReceivedEvents(newEvents)
      } catch (error) {
        navigator(
          `/${routes.properties.index}/${routes.properties["my-properties"].index}`
        )
        toaster.error(error.response.data)
      } finally {
      }
    }
  }

  React.useEffect(() => {
    if (Boolean(property.id) && !isEdit) {
      handleFetchAllEvents()
    }
  }, [property.id])

  const RenderContent = () => {
    switch (wizardStep) {
      case 0:
        return (
          <BasicPlanInformation property={property} isEdit={Boolean(isEdit)} />
        )
      case 1:
        return (
          <CapitalizatinDetails property={property} isEdit={Boolean(isEdit)} />
        )
      case 2:
        return (
          <AdditionalInformation property={property} isEdit={Boolean(isEdit)} />
        )
      case 3:
        return (
          <ExecutiveCalender
            onFetchAllEvents={handleFetchAllEvents}
            events={receivedEvents}
          />
        )
      default:
        return <BasicPlanInformation />
    }
  }

  if (!user.type) return <CompleteProfileTypeAlert />

  if (!isRole("funder")) return <CompleteProfileRoleAlert />

  return (
    <Stack>
      <AddPropertyWizard activeSteps={wizardStep} isEdit={Boolean(isEdit)} />
      <RenderContent />
    </Stack>
  )
}

export default AddNewProperty
