import React, { useState } from "react";
import TextField, { TextFieldProps } from "@mui/material/TextField";

type Props = {
  label: string;
  onChange?: (value: string) => void;
  defaultValue?: string | number;
} & any;

const ShebaTextField: React.FC<Props & TextFieldProps> = ({
  label,
  onChange,
  defaultValue,
  ...props
}) => {
  const [value, setValue] = useState("");

  const handleValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    const formattedValue = formatShebaCode(inputValue);
    setValue(formattedValue);

    if (onChange) {
      onChange(formattedValue);
    }
  };

  const formatShebaCode = (value: string): string => {
    // Remove all non-numeric characters from the input value
    const numericValue = value.replace(/[^0-9]/g, "");
    // Add a space every 4 digits
    const formattedValue = numericValue.replace(/\B(?=(\d{4})+(?!\d))/g, "");
    // Add "IR" to the beginning of the value
    return `IR${formattedValue}`;
  };

  React.useEffect(() => {
    if (defaultValue) setValue(defaultValue);
  }, [defaultValue]);

  return (
    <TextField
      label={label}
      inputProps={{
        inputMode: "numeric",
        maxLength: 26,
        ...props.inputProps,
      }}
      {...props}
      fullWidth
      onChange={handleValueChange}
      value={value}
    />
  );
};

export default ShebaTextField;
