// Built-in import
import * as React from "react"
// External Import
import { useTranslation } from "react-i18next"
import { Button } from "@mui/material"
// Internal Import
import FileIcon from "@/components/icons/file-icon"
import UnpaidAction from "./unpaid-action"
import AwaitingConfirmationAction from "./awaiting-confirmation-action"

interface IActionRendererProps {
  item: any
}

const ActionRenderer: React.FunctionComponent<IActionRendererProps> = ({
  item
}) => {
  // Getting Translation object
  const { t } = useTranslation(["common"])

  const RenderContent = (): any => {
    switch (item.status) {
      case "paid":
        return (
          <Button variant="outlined" startIcon={<FileIcon color="#E29314" />}>
            {t("common:print_details")}
          </Button>
        )
      case "unpaid":
        return <UnpaidAction />
      case "awaiting-confirmation":
        return <AwaitingConfirmationAction />
      default:
        return <Button>ss</Button>
    }
  }
  return <RenderContent />
}

export default ActionRenderer
