import * as React from "react";

import { Grid, Stack } from "@mui/material";

import GalleryItem from "./item";
import { Uploader } from "..";
import { useTranslation } from "react-i18next";
import { useUploader } from "@/hook";

interface IGalleryItemsProps {
  items: any[];
  handleAddMedia: (file: any) => void;
  handleRemove: (index: number) => void;
  onItemClick?: (item: any, index: number) => void;
  primaryIndex: number;
  hasPrimaryChange?: boolean;
  uploadOnce?: boolean;
  showActions?: boolean;
  lgCols?: number;
}

const GalleryItems: React.FunctionComponent<IGalleryItemsProps> = ({
  items = [],
  handleAddMedia,
  handleRemove,
  onItemClick,
  primaryIndex,
  lgCols = 6,
  uploadOnce = false,
  hasPrimaryChange = false,
  showActions = true,
}) => {
  const { t } = useTranslation(["common"]);

  const { hiddenInput, file } = useUploader();

  React.useEffect(() => {
    if (!file) return;
    handleAddMedia(file);
  }, [file]);

  return (
    <Stack spacing={2} width={"100%"}>
      <Stack>
        <Grid container spacing={2}>
          {items.map((item, index) => (
            <Grid item key={index} lg={lgCols} md={6} xs={12}>
              <GalleryItem
                item={item}
                index={index}
                uploadOnce={uploadOnce}
                hiddenInput={hiddenInput}
                handleRemove={handleRemove}
                onItemClick={onItemClick}
                isPrimary={primaryIndex === +index}
                hasPrimaryChange={hasPrimaryChange}
                showActions={showActions}
              />
            </Grid>
          ))}
        </Grid>
      </Stack>
      {showActions && (
        <>
          {Boolean(file && items.length && uploadOnce) ? (
            <></>
          ) : (
            <Uploader
              title={t("common:click_to_add_new_image")}
              description={t("common:description_uploader_image")}
              hiddenInput={hiddenInput}
            />
          )}
        </>
      )}
    </Stack>
  );
};

export default GalleryItems;
