import * as React from "react";
import { IIconType } from "@/types/icon";

interface HomeIconProps extends IIconType {}

const HomeIcon: React.FunctionComponent<HomeIconProps> = ({
  color = "#E29314",
  width = 47,
  height = 46,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 57 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M42.0098 53.06H14.9898C8.5965 53.06 3.4165 47.8567 3.4165 41.4634V24.1734C3.4165 21 5.3765 17.01 7.8965 15.05L20.4732 5.25004C24.2532 2.31004 30.2965 2.17004 34.2165 4.92337L48.6365 15.0267C51.4132 16.9634 53.5832 21.1167 53.5832 24.5V41.4867C53.5832 47.8567 48.4032 53.06 42.0098 53.06ZM22.6198 8.00337L10.0432 17.8034C8.3865 19.11 6.9165 22.0734 6.9165 24.1734V41.4634C6.9165 45.92 10.5332 49.56 14.9898 49.56H42.0098C46.4665 49.56 50.0832 45.9434 50.0832 41.4867V24.5C50.0832 22.26 48.4732 19.1567 46.6298 17.8967L32.2098 7.79337C29.5498 5.9267 25.1632 6.02004 22.6198 8.00337Z"
        fill={color}
      />
      <path
        d="M32 43.75H25C20.17 43.75 16.25 39.83 16.25 35V28C16.25 23.17 20.17 19.25 25 19.25H32C36.83 19.25 40.75 23.17 40.75 28V35C40.75 39.83 36.83 43.75 32 43.75ZM25 22.75C22.1067 22.75 19.75 25.1067 19.75 28V35C19.75 37.8933 22.1067 40.25 25 40.25H32C34.8933 40.25 37.25 37.8933 37.25 35V28C37.25 25.1067 34.8933 22.75 32 22.75H25Z"
        fill={color}
      />
      <path
        d="M28.5 43.75C27.5433 43.75 26.75 42.9567 26.75 42V21C26.75 20.0433 27.5433 19.25 28.5 19.25C29.4567 19.25 30.25 20.0433 30.25 21V42C30.25 42.9567 29.4567 43.75 28.5 43.75Z"
        fill={color}
      />
      <path
        d="M39 33.25H18C17.0433 33.25 16.25 32.4567 16.25 31.5C16.25 30.5433 17.0433 29.75 18 29.75H39C39.9567 29.75 40.75 30.5433 40.75 31.5C40.75 32.4567 39.9567 33.25 39 33.25Z"
        fill={color}
      />
    </svg>
  );
};

export default HomeIcon;
