// Built-in import
import * as React from "react"
import * as Yup from "yup"

// Internal import
import { AuthSubmitButton, TitleWithValue } from "@/components/shared"
// External import
import { Grid, MenuItem, Stack, TextField } from "@mui/material"
import { actionSetCurrentStep, actionUpdateUserInfo } from "@/store/slices/auth"

import { CompleteInfoType } from "@/types/auth"
import { IUser } from "@/types/user"
import MyDatepicker from "@/components/shared/datepicker"
import { useDispatch } from "react-redux"
import { useFormik } from "formik"
import { useTranslation } from "react-i18next"

interface ILegalInvestorForm {
  userInfo?: IUser
}

const genderListItems = [
  { id: 1, title: "مرد", value: "Male" },
  { id: 2, title: "زن", value: "Female" },
]

const LegalInvestorForm: React.FC<ILegalInvestorForm> = ({ userInfo }) => {
  // Getting translation object
  const { t } = useTranslation(["common"])

  const dispatcher = useDispatch()
  // Formik
  const {
    handleSubmit,
    getFieldProps,
    errors,
    touched,
    values,
    setFieldValue,
  } = useFormik({
    initialValues: {
      first_name: userInfo?.first_name || "",
      national_code: userInfo?.national_code || "",
      last_name: userInfo?.last_name || "",
      father_name: userInfo?.father_name || "",
      gender: userInfo?.gender || "",
      birthday: userInfo?.birthday,
      identity_code: userInfo?.identity_code || "",
      email: userInfo?.email || "",
    },
    validationSchema: Yup.object().shape({
      first_name: Yup.string().required(t("validation:this_field_is_required")),
      identity_code: Yup.string().required(
        t("validation:this_field_is_required")
      ),
      gender: Yup.string().required(t("validation:this_field_is_required")),
      last_name: Yup.string().required(t("validation:this_field_is_required")),
      father_name: Yup.string().required(
        t("validation:this_field_is_required")
      ),

      national_code: Yup.string()
        .min(10, t("validation:enter_10_digit_number"))
        .max(10, t("validation:enter_10_digit_number"))
        .required(t("validation:this_field_is_required")),
      email: Yup.string()
        .matches(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
          t("validation:please_enter_valid_email")
        )
        .required(t("validation:this_field_is_required")),
    }),
    onSubmit: (values, actions) => {
      dispatcher(actionUpdateUserInfo(values))
      dispatcher(actionSetCurrentStep(CompleteInfoType.CompleteInformation))
    },
  })

  return (
    <Stack component="form" spacing={3} onSubmit={handleSubmit}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <TitleWithValue
            title={t("common:name")}
            hasDoubleDot={false}
            colorTitle={"text.secondary"}
            value={
              <TextField
                value={values.first_name}
                fullWidth
                {...getFieldProps("first_name")}
                error={Boolean(touched.first_name && errors.first_name)}
                helperText={touched.first_name && errors.first_name}
              />
            }
            direction={"column"}
            alignItems="flex-start"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TitleWithValue
            title={t("common:identity_code")}
            hasDoubleDot={false}
            colorTitle={"text.secondary"}
            value={
              <TextField
                fullWidth
                value={values.identity_code}
                {...getFieldProps("identity_code")}
                error={Boolean(touched.identity_code && errors.identity_code)}
                helperText={touched.identity_code && errors.identity_code}
              />
            }
            direction={"column"}
            alignItems="flex-start"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TitleWithValue
            title={t("common:last_name")}
            hasDoubleDot={false}
            colorTitle={"text.secondary"}
            value={
              <TextField
                fullWidth
                value={values.last_name}
                {...getFieldProps("last_name")}
                error={Boolean(touched.last_name && errors.last_name)}
                helperText={touched.last_name && errors.last_name}
              />
            }
            direction={"column"}
            alignItems="flex-start"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TitleWithValue
            title={t("common:father_name")}
            hasDoubleDot={false}
            colorTitle={"text.secondary"}
            value={
              <TextField
                fullWidth
                value={values.father_name}
                {...getFieldProps("father_name")}
                error={Boolean(touched.father_name && errors.father_name)}
                helperText={touched.father_name && errors.father_name}
              />
            }
            direction={"column"}
            alignItems="flex-start"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TitleWithValue
            title={t("common:gender")}
            hasDoubleDot={false}
            colorTitle={"text.secondary"}
            value={
              <TextField
                fullWidth
                select
                value={values.gender}
                onChange={(e) => setFieldValue("gender", e.target.value)}
              >
                {genderListItems.map((gender) => (
                  <MenuItem key={gender.id} value={gender.value}>
                    {gender.title}
                  </MenuItem>
                ))}
              </TextField>
            }
            direction={"column"}
            alignItems="flex-start"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TitleWithValue
            title={t("common:birthday")}
            hasDoubleDot={false}
            colorTitle={"text.secondary"}
            value={
              <MyDatepicker
                value={values.birthday}
                onChangeDate={(date: Date) => {
                  setFieldValue("birthday", new Date(date).toISOString())
                }}
                title={t("common:date")}
              />
            }
            direction={"column"}
            alignItems="flex-start"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TitleWithValue
            title={t("common:national_code")}
            hasDoubleDot={false}
            colorTitle={"text.secondary"}
            value={
              <TextField
                fullWidth
                value={values.national_code}
                {...getFieldProps("national_code")}
                error={Boolean(touched.national_code && errors.national_code)}
                helperText={touched.national_code && errors.national_code}
              />
            }
            direction={"column"}
            alignItems="flex-start"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TitleWithValue
            title={t("common:email")}
            hasDoubleDot={false}
            colorTitle={"text.secondary"}
            value={
              <TextField
                fullWidth
                value={values.email}
                {...getFieldProps("email")}
                error={Boolean(touched.email && errors.email)}
                helperText={touched.email && errors.email}
              />
            }
            direction={"column"}
            alignItems="flex-start"
          />
        </Grid>
      </Grid>
      <Stack
        alignItems={{ xs: "initial", md: "flex-start" }}
        justifyContent="start"
      >
        <AuthSubmitButton
          title={t("next_step")}
          btnType={"submit"}
          sxProps={{
            width: ["100%", , "200px"],
          }}
        />
      </Stack>
    </Stack>
  )
}

export default LegalInvestorForm
