// Built-in import
import * as React from "react"

import { IServerProperty } from "@/store/slices/property"
import { Link } from "react-router-dom"
// Internal import
import { MyTabs } from "@/components/shared"
// External import
import { Paper } from "@mui/material"
import SpecificationsTabContent from "./specifications-tab-content"
import StatusTabContent from "./status-tab-content"
import StructureTabContent from "./tab-content"
import routes from "@/navigation/routes"
import { useTranslation } from "react-i18next"

interface IMyPropertiesCard {
  item?: IServerProperty
}

const MyPropertiesCard: React.FC<IMyPropertiesCard> = ({ item }) => {
  // Getting translation object
  const { t } = useTranslation(["common"])
  return (
    <Paper
      sx={{
        py: 1.5,
        px: 2,
        height: "100%",
        boxShadow: "0px 0px 15px #99999930",
        mx: "auto",
        minHeight: ["500px", , "100%"],
        ["& .MuiTabs-root"]: {
          bgcolor: "transparent"
        }
      }}
    >
      <MyTabs
        tabId="order-history"
        items={[
          {
            label: t("common:specifications"),
            children: (
              <StructureTabContent item={item}>
                <SpecificationsTabContent item={item} />
              </StructureTabContent>
            )
          },
          {
            label: t("common:status"),
            children: (
              <StructureTabContent item={item}>
                <StatusTabContent item={item} />
              </StructureTabContent>
            )
          }
        ]}
        sxParent={{
          "& .MuiTabs-root": {
            background: "none",
            mb: 0.5,
            borderRadius: 0
          },
          [`& .MuiTab-root`]: {
            width: ["50% !important", "50% !important"],
            flexGrow: 0
          },
          "& .MuiTabs-scroller": {
            flexGrow: [1, , 0],
            width: ["100%", , "fit-content"],
            pb: 2,
            overflow: "scroll"
          },
          "& .MuiButtonBase-root.MuiTab-root": {
            borderRadius: "5px !important",
            transition: "0.2s",
            py: 1.5
          },
          "& .Mui-selected": {
            boxShadow: "0px 5px 10px #3f37c950"
          }
        }}
      />
    </Paper>
  )
}

export default MyPropertiesCard
