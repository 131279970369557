// Built-in import
import * as React from "react"

// External import
import { Stack, useMediaQuery, useTheme } from "@mui/material"

import { ASSETS_URL } from "@/constants"
import { IMedia } from "@/types/media"
import { IServerProperty } from "@/store/slices/property"
import { useMedia } from "@/hook"

interface IStructureTabContent {
  item: IServerProperty
  children: React.ReactNode
}

const StructureTabContent: React.FC<IStructureTabContent> = ({
  item,
  children
}) => {
  const background = useMedia(item?.media, "background") as IMedia
  const defaultBackground = useMedia(item?.media, "default") as IMedia

  // Getting mui theme
  const theme = useTheme()
  // Mobile viewport
  const isMobile = useMediaQuery(theme.breakpoints.down("md"))

  const targetImageUrl =
    background?.original_url || defaultBackground?.original_url

  return (
    <Stack
      direction={isMobile ? "column" : "row"}
      spacing={2}
      alignItems="start"
    >
      <Stack
        sx={{
          minWidth: ["100%", , "140px"],
          minHeight: ["200px", , "140px"],
          width: ["100%", , "140px"],
          height: ["200px", , "140px"],
          bgcolor: (t) => `${t.palette.divider} !important`,
          alignItems: "center",
          justifyContent: "center",
          borderRadius: "6px",
          "& img": {
            objectFit: "cover",
            width: targetImageUrl ? "100%" : "110px",
            height: targetImageUrl ? "100%" : "auto",
            borderRadius: "6px"
          }
        }}
      >
        {
          <img
            src={targetImageUrl || `${ASSETS_URL}/main-logo.png`}
            alt="project-cover"
          />
        }
      </Stack>
      {children}
    </Stack>
  )
}

export default StructureTabContent
