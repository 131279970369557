import * as React from "react"

import { Button } from "@mui/material"
import NoItemsWasFound from "."
import WarningIcon from "@mui/icons-material/Warning"
import routes from "@/navigation/routes"
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"

interface ICompleteProfileTypeAlertProps {
  onClose?: () => void
}

const CompleteProfileTypeAlert: React.FunctionComponent<
  ICompleteProfileTypeAlertProps
> = ({ onClose }) => {
  const { t } = useTranslation(["common"])

  const navigator = useNavigate()

  const navigateToProfile = React.useCallback(() => {
    navigator(`/${routes.profile.index}`)

    if (onClose) onClose()
  }, [onClose, navigator])

  return (
    <NoItemsWasFound
      sxProps={{ py: 2 }}
      title={t("common:please_complete_profile")}
      icon={<WarningIcon />}
      afterTitle={
        <Button onClick={navigateToProfile} variant="outlined">
          {t("common:go_to_profile")}
        </Button>
      }
    />
  )
}

export default CompleteProfileTypeAlert
