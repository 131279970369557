import * as React from "react"

import { Avatar, Stack, SxProps, Theme, Typography } from "@mui/material"

import { IMedia } from "@/types/media"
import { IUser } from "@/types/user"
import { useMedia } from "@/hook"
import { useTranslation } from "react-i18next"

interface IUserInformationProps {
  user: IUser
  direction?: "column" | "column-reverse" | "row" | "row-reverse"
  title?: string
  sxProps?: SxProps<Theme>
}

const UserInformation: React.FunctionComponent<IUserInformationProps> = ({
  user,
  direction = "column",
  title = "",
  sxProps = {}
}) => {
  const { t } = useTranslation(["common"])

  const avatar = useMedia(user?.media, "avatar") as IMedia

  if (!user) return null

  return (
    <Stack direction="row" spacing={1} alignItems="center" sx={sxProps}>
      <Avatar
        src={avatar?.original_url || "#"}
        alt={`${user?.first_name} ${user?.last_name}`}
        sx={{
          width: 40,
          height: 40
        }}
      />
      <Stack direction={direction}>
        <Typography variant="smallBold" color="text.secondary">
          {user?.name || "-"}
        </Typography>
        {Boolean(title) && (
          <Typography variant="extraSmallRegular" color={"text.disabled"}>
            {title}
          </Typography>
        )}
      </Stack>
    </Stack>
  )
}

export default UserInformation
