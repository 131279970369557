// Built-in import
import * as React from "react";
// External import
import { Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import { LoadingButton } from "@mui/lab";

interface IEventDetailsActions {
  loading?: boolean;
}

const EventDetailsActions: React.FC<IEventDetailsActions> = ({
  loading = false,
}) => {
  // Getting translation object
  const { t } = useTranslation(["common"]);
  // Modal

  return (
    <Stack width="100%" justifyContent={"flex-end"} spacing={2} direction="row">
      <LoadingButton
        variant="contained"
        color="primary"
        type="submit"
        loading={loading}
      >
        {t("common:save_changes")}
      </LoadingButton>
    </Stack>
  );
};

export default EventDetailsActions;
