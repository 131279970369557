// Built-in import
import * as React from "react"

// External import
import { Stack, Typography, useMediaQuery, useTheme } from "@mui/material"

import { IServerProperty } from "@/store/slices/property"
import { limitChar } from "@/utils"
import { useTranslation } from "react-i18next"

interface ISpecificationsTabContent {
  item: IServerProperty
}

const SpecificationsTabContent: React.FC<ISpecificationsTabContent> = ({
  item
}) => {
  // Getting props as item
  const { title, description } = item
  // Getting translation object
  const { t } = useTranslation(["common"])

  return (
    <Stack
      sx={{ width: "fit-content", flexGrow: 1 }}
      spacing={{ xs: 3, md: 2 }}
    >
      <Stack spacing={{ xs: 1, md: 0.5 }}>
        <Typography variant="bodyBold">{title}</Typography>
        {Boolean(item?.excerpt) && (
          <Typography variant="smallRegular" textAlign="justify">
            {limitChar(item.excerpt, 100)}
          </Typography>
        )}
      </Stack>
    </Stack>
  )
}

export default SpecificationsTabContent
