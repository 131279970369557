import { Stack, Typography } from "@mui/material"
import {
  LoadingEmbraced,
  OTPPureHandler,
  UserAuthGuidance,
} from "@/components/shared"
import { useEffect, useState } from "react"

import { useDispatch } from "react-redux"
import { useTimer } from "@/hook"
import { useTranslation } from "react-i18next"
import convertNumToEnglish from "@/utils/convertPersianDigitsToEnglish"
import { Auth } from "@/services"
import {
  actionSetToken,
  actionSetUser,
  actionUpdateUserInfo,
} from "@/store/slices/auth"

type Props = {
  national_code: string
  onPrevStep: () => void
  onClose?: () => void
}

export default function EnterSejamOtp({
  national_code,
  onPrevStep,
  onClose,
}: Props) {
  const { t } = useTranslation(["common"])

  const { time, startTimer, isDisabled } = useTimer(10000)
  //start timer when we open the login field for fist time
  useEffect(() => {
    startTimer()
  }, [])

  //define resend code title when counter start or stop
  const resendCodeTitle = isDisabled
    ? time.seconds && time.minutes
      ? t("common:until_get_again_with_sec", time)
      : !time.minutes
      ? t("common:until_get_again_sec", { seconds: time.seconds })
      : t("common:until_get_again", { minutes: time.minutes })
    : t("common:resend_code")

  const dispatcher = useDispatch()

  const [isSubmitting, setIsSubmitting] = useState(false)
  const stepTwoHandler = async (code: string) => {
    if (onClose) onClose()
    setIsSubmitting(true)
    try {
      const res = await Auth.authsejamStepTwo(
        convertNumToEnglish(national_code),
        code
      )
      const accessToken = res.data.data["access-token"]
      const accessUser = res.data.data.user
      dispatcher(actionSetUser({ user: accessUser }))
      dispatcher(actionUpdateUserInfo({ user: accessUser }))
      dispatcher(actionSetToken({ token: accessToken }))
      if (onClose) onClose()
    } catch (e) {
    } finally {
      setIsSubmitting(false)
    }
  }

  const sendOtpCodeAgain = () => {
    onPrevStep()
  }

  let view = (
    <Stack
      spacing={2}
      sx={{ width: 300, maxWidth: "100%", margin: "0 auto", pt: 5 }}
    >
      <Typography variant="bodyLight">
        {t("common:please_insert_otp_code")}
      </Typography>
      <OTPPureHandler length={5} onCompleted={stepTwoHandler} />
      <UserAuthGuidance
        isDisabled={isDisabled}
        handleClick={sendOtpCodeAgain}
        question={t("common:didnt_receive_confirm_code")}
        description={resendCodeTitle}
      />
    </Stack>
  )

  if (isSubmitting) view = <LoadingEmbraced />

  return <Stack sx={{ minHeight: 200 }}>{view}</Stack>
}
