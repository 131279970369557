import * as React from "react";
import { ITransaction } from "@/types/transaction";
import { Stack } from "@mui/material";
import StatusBox from "../../status-box";
import FinancialSummaryTitle from "./title";

interface IFinancialSummaryProps {
  item: any;
}

const FinancialSummary: React.FunctionComponent<IFinancialSummaryProps> = ({
  item,
}) => {
  return (
    <Stack
      direction={"row"}
      alignItems="center"
      spacing={0}
      width="100%"
      justifyContent={"space-between"}
    >
      <FinancialSummaryTitle title={item?.titleOfProperty} />
      <FinancialSummaryTitle
        title={item.amountOfTransaction?.withCommas()?.withPriceUnit()}
      />
      <FinancialSummaryTitle title={item?.date} />
      <StatusBox status={item?.status} />
    </Stack>
  );
};

export default FinancialSummary;
