import React, { useState } from "react";
import TextField, { TextFieldProps } from "@mui/material/TextField";

import { t } from "i18next";

type Props = {
  label: string;
  onChange?: (value: string, bankType?: string) => void;
  defaultValue?: string | number;
} & any;

const CardNumberTextField: React.FC<Props & TextFieldProps> = ({
  label,
  onChange,
  defaultValue,
  ...props
}) => {
  const [value, setValue] = useState("");

  const handleValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    const formattedValue = formatCardNumber(inputValue);
    setValue(formattedValue);

    const bankType = detectBankType(formattedValue);
    if (onChange) {
      onChange(formattedValue, bankType);
    }
  };

  const formatCardNumber = (value: string): string => {
    // Remove all non-numeric characters from the input value
    const numericValue = value.replace(/[^0-9]/g, "");
    // Add a space every 4 digits
    const formattedValue = numericValue.replace(/\B(?=(\d{4})+(?!\d))/g, "");
    return formattedValue;
  };

  const detectBankType = (cardNumber: string): string | undefined => {
    // Remove all non-numeric characters from the input value
    const numericValue = cardNumber.replace(/[^0-9]/g, "").replaceAll(" ", "");

    const firstFourDigits = parseInt(numericValue.substring(0, 4), 10);

    switch (firstFourDigits) {
      case 6037:
        // Iran Zamin Credit Card
        return t("iranzamin_bank");
      case 6039:
        // Iran Kish Credit Card
        return t("irankish_bank");
      case 5057:
        // Tose'e Ta'avon Bank Credit Card
        return t("tosee_taavon_bank");
      case 5041:
        return t("resalat_bank");
      case 6274:
      case 6104:
        // Mellat Bank Credit Card
        return t("mellat_bank");
      case 6362:
        // Shahr Bank Debit Card
        return t("shahr_bank");
      case 5892:
        // Sepah Bank Debit Card
        return t("sepah_bank");
      case 6273:
        // Saderat Bank Debit Card
        return t("melli_bank");
      case 5022:
        // Saderat Bank Debit Card
        return t("pasargad_bank");
      case 5894:
        // Saderat Bank Debit Card
        return t("refah_bank");
      case 6037:
      case 6395:
        // Iran Zamin Debit Card
        return t("iranzamin_bank");
      case 6280:
      case 6281:
        // Mellat Bank Debit Card or Tejarat Bank Debit Card
        return t("mellat_bank");
      case 6219:
        // Saman Bank Debit Card
        return t("saman_bank");
      default:
        // Unknown bank type
        return undefined;
    }
  };

  React.useEffect(() => {
    if (defaultValue) setValue(defaultValue);
  }, [defaultValue]);

  return (
    <TextField
      label={label}
      inputProps={{ maxLength: 19 }} // Maximum length of card number with spaces is 19
      {...props}
      //   sx={{ direction: "rtl" }}
      value={value}
      onChange={handleValueChange}
    />
  );
};

export default CardNumberTextField;
