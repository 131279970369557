import * as React from "react";

import GalleryItems from "./items";

interface IGalleryUploaderProps {
  defaultItems?: any[];
  onChange?: (items: any[]) => void;
  onItemClick?: (item: any, index: number) => void;
  primaryIndex?: number;
  isSingular?: boolean;
  uploadOnce?: boolean;
  showActions?: boolean;
  lgCols?: number;
}

const GalleryUploader: React.FunctionComponent<IGalleryUploaderProps> = ({
  defaultItems,
  onChange,
  onItemClick,
  primaryIndex,
  isSingular = false,
  uploadOnce = false,
  showActions = true,
  lgCols,
}) => {
  const [items, setItems] = React.useState<any[]>([]);
  React.useEffect(() => {
    if (defaultItems) {
      setItems(defaultItems);
    }
    return () => setItems([]);
  }, [defaultItems]);

  const handleRemove = (index: number) => {
    setItems((crt) => {
      const newItems = crt.filter((item, i) => i !== index);
      if (onChange) onChange(newItems);
      return newItems;
    });
  };

  const handleAddMedia = (file: any) => {
    // When we use singular uploader
    if (isSingular) {
      setItems(() => {
        const newItems = [file];
        if (onChange) onChange(newItems);
        return newItems;
      });
      return;
    }

    setItems((crt) => {
      const newItems = [...crt, file];

      if (onChange) onChange(newItems);

      return newItems;
    });
  };

  return (
    <GalleryItems
      lgCols={lgCols}
      items={items}
      uploadOnce={uploadOnce}
      handleRemove={handleRemove}
      handleAddMedia={handleAddMedia}
      onItemClick={onItemClick}
      primaryIndex={primaryIndex}
      hasPrimaryChange={primaryIndex !== undefined}
      showActions={showActions}
    />
  );
};

export default GalleryUploader;
