//Built-in Import
import * as React from "react";
//External Import
import ReactApexChart from "react-apexcharts";
import { useTranslation } from "react-i18next";
import { Paper, Stack, Typography } from "@mui/material";
//Internal Import
import { LoadingEmbraced, MyDatePicker } from "@/components/shared";
import useFetch from "@/hook/use-fetch";
import { IResponseForFetchEntity } from "@/api/types";
import { ApexOptions } from "apexcharts";
import { getUrlWithQueryParams, humanDate } from "@/utils";
import moment from "jalali-moment";
import { jalaliToTimestamp } from "@/utils";
// type IChartTypes =
//   | "area"
//   | "line"
//   | "bar"
//   | "histogram"
//   | "pie"
//   | "donut"
//   | "radialBar"
//   | "scatter"
//   | "bubble"
//   | "heatmap"
//   | "candlestick"
//   | "boxPlot"
//   | "radar"
//   | "polarArea"
//   | "rangeBar"
//   | "treemap";
type IChartTypes = any;

interface IAxisChartProps {
  title: string;
  endpoint: string;
  chartOptions?: ApexOptions;
  chartType?: IChartTypes;
  chartColor?: string;
  seriesName?: string;
}

const AxisChart: React.FunctionComponent<IAxisChartProps> = ({
  title,
  endpoint,
  chartOptions,
  chartType = "area",
  chartColor = "#E29314",
  seriesName = "",
}) => {
  // Getting Translation object
  const { t } = useTranslation(["common"]);

  const [queries, setQueries] = React.useState({});

  const handleChangeQuery = (key: string, value: any) => {
    setQueries((crt) => ({ ...crt, [key]: value }));
  };
  //current endpoint that received
  const [currentEndpoint, setCurrentEndpoint] = React.useState<string | null>(
    null
  );

  const { data: statisticsData, loading: statisticsLoading } = useFetch<
    IResponseForFetchEntity<any[]>
  >(
    getUrlWithQueryParams(currentEndpoint, { ...queries }),
    Boolean(currentEndpoint),
    [queries]
  );

  const statisticsItems = (statisticsData && statisticsData?.data.item) || [];

  React.useEffect(() => {
    if (endpoint) setCurrentEndpoint(endpoint);
  }, [endpoint]);

  //converted days from api
  const selectedDays = React.useMemo(() => {
    if (statisticsItems.length) {
      return statisticsItems.map((item, index) => {
        return humanDate(item.date, "jMMMM");
      });
    }
  }, [statisticsItems]);

  //converted values from api
  const selectedValues = React.useMemo(() => {
    if (statisticsItems.length) {
      return statisticsItems.map((item, index) => {
        return +item.aggregate;
      });
    }
  }, [statisticsItems]);

  return (
    <Paper
      sx={{
        height: "100%",
        justifyContent: "space-between",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {statisticsLoading || statisticsData === undefined ? (
        <LoadingEmbraced />
      ) : (
        <>
          <Stack
            direction='row'
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Typography variant='bodyMedium'>{title}</Typography>
            <MyDatePicker
              title={t("select_date")}
              onChangeDate={(date) => {
                handleChangeQuery(
                  "from",
                  moment(date).locale("fa").startOf("jYear").unix()
                );
                handleChangeQuery(
                  "to",
                  moment(date).locale("fa").endOf("jYear").unix()
                );
              }}
              calendarHeight={40}
              calendarWidth={120}
            />
          </Stack>
          <ReactApexChart
            options={{
              ...chartOptions,
              ...(chartColor && { colors: [chartColor] }),
              chart: { ...chartOptions["chart"], type: chartType },
              xaxis: {
                categories: selectedDays,
              },
            }}
            series={[{ name: seriesName, data: selectedValues }]}
            type={chartType}
            height={240}
          />
        </>
      )}
    </Paper>
  );
};

export default AxisChart;
