import * as React from "react";
import {
  InputBase,
  Stack,
  MenuItem,
  SxProps,
  Theme,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import TextField from "@mui/material/TextField";
import { alpha } from "@mui/material/styles";

interface IForcastInputDateProps {
  onChange?: (value: string) => void;
}

const ForcastInputDate: React.FunctionComponent<IForcastInputDateProps> = ({
  onChange,
}) => {
  //getting translation object
  const { t } = useTranslation();
  const [dateValue, setDateValue] = React.useState("");
  const [inputValue, setInputValue] = React.useState("");

  //select date item types
  const selectItemTypes = [
    {
      id: 1,
      title: t("day"),
      value: "day",
    },
    {
      id: 2,
      title: t("month"),
      value: "month",
    },
  ];

  const selectInputStyle: SxProps<Theme> = {
    "& label": {
      top: "50%",
      left: "-35px !important",
      overflow: "unset",
      color: (t) => t.palette.primary.main,
      display: dateValue.length ? "none" : "block",
      zIndex: 0,
    },

    "& .MuiOutlinedInput-root .MuiOutlinedInput-input": {
      width: "20px",
      color: (t) => t.palette.primary.main,
      py: "5px !important",
      "& ~ input": {
        color: (t) => t.palette.primary.main,
      },
      "& ~ svg": {
        fill: (t) => t.palette.primary.main,
      },
      "& ~ fieldset": {
        borderWidth: "0px",
      },
    },
  };

  const changeValueTypeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDateValue(e.target.value);
  };
  const changeInputValHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
    if (onChange) onChange(e.target.value);
  };

  return (
    <Stack direction={"row"} spacing={2} alignItems="center">
      <Stack
        direction={"row"}
        spacing={1}
        alignItems="center"
        sx={{
          border: "1px solid",
          borderColor: (t) => `${alpha(t.palette.common.black, 0.2)}`,
          p: 1,
          borderRadius: "6px",
        }}
      >
        <InputBase
          onChange={changeInputValHandler}
          type="number"
          placeholder={t("enter_number")}
        />
        <TextField
          value={dateValue}
          label={t("select")}
          select
          InputLabelProps={{ shrink: true }}
          onChange={changeValueTypeHandler}
          sx={selectInputStyle}
        >
          {selectItemTypes.map((item) => {
            return (
              <MenuItem key={item.id} value={item.value}>
                {item.title}
              </MenuItem>
            );
          })}
        </TextField>
      </Stack>
      <Typography variant="smallRegular" sx={{ minWidth: "105px" }}>
        {t("after_starting_plan")}
      </Typography>
    </Stack>
  );
};

export default ForcastInputDate;
