import * as Yup from "yup"

import { Stack, TextField } from "@mui/material"

import { LoadingButton } from "@mui/lab"
import { useFormik } from "formik"
import { useTranslation } from "react-i18next"
import convertNumToEnglish from "@/utils/convertPersianDigitsToEnglish"
import { SajamSystemInformationTitle } from "../subtitle"
import { UserAuthGuidance } from "@/components/shared"
import { Auth } from "@/services"
import { toaster } from "@/components/shared/toaster"

interface IEnterNationalCodeProps {
  onSubmit: () => void
  onChange: (key: string, value: string) => void
}

const EnterNationalCode: React.FunctionComponent<IEnterNationalCodeProps> = ({
  onSubmit,
  onChange,
}) => {
  //Getting translation object
  const { t } = useTranslation(["common", "validation"])

  const { getFieldProps, errors, touched, handleSubmit, isSubmitting } =
    useFormik({
      initialValues: {
        national_code: "",
      },
      validationSchema: Yup.object().shape({
        national_code: Yup.string()
          .required(t("validation:this_field_is_required"))
          .min(10, t("common:please_insert_10_char_national_code"))
          .max(10, t("common:please_insert_10_char_national_code")),
      }),
      onSubmit: async (values, actions) => {
        if (onSubmit) onSubmit()
        if (onChange) onChange("national_code", values.national_code)
        try {
          const res = await Auth.authSajamStepOne(
            convertNumToEnglish(values.national_code)
          )
          toaster.success(res.data.message)
        } catch (error) {
          let textError = error?.response?.data.message?.national_code
          toaster.warning(textError)
        }
      },
    })

  return (
    <Stack pt={2} spacing={3}>
      {/* title */}
      <SajamSystemInformationTitle />
      {/* form */}
      <Stack spacing={2} component={"form"} onSubmit={handleSubmit}>
        <TextField
          label={t("common:national_code")}
          error={Boolean(touched.national_code && errors.national_code)}
          helperText={touched.national_code && errors.national_code}
          {...getFieldProps("national_code")}
        />
        <LoadingButton
          fullWidth
          variant="contained"
          type="submit"
          loading={isSubmitting}
        >
          {t("common:confirm")}
        </LoadingButton>
      </Stack>
      <UserAuthGuidance
        question={`${t("dont_have_sejam_account_yet")}؟`}
        description={t("click_here")}
        handleClick={() => {
          window.open("https://sejam.ir")
        }}
      />
    </Stack>
  )
}

export default EnterNationalCode
