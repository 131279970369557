// Built-in import
import * as React from "react"
// External Import
import { useTranslation } from "react-i18next"
import { Button, Stack } from "@mui/material"
// Internal Import
import FileIcon from "@/components/icons/file-icon"
import { useGlobalContext } from "@/context/global-context"
import EnterPaymentDetails from "./enter-payment-details-modal"

interface IUnpaidActionProps {}

const UnpaidAction: React.FunctionComponent<IUnpaidActionProps> = (props) => {
  // Getting Translation object
  const { t } = useTranslation(["common"])
  // Getting Show Modal
  const { showModal } = useGlobalContext()
  return (
    <Stack direction="row" spacing={1}>
      <Button variant="outlined" startIcon={<FileIcon color="#E29314" />}>
        {t("common:print_details")}
      </Button>
      <Button
        variant="contained"
        onClick={() => {
          showModal(<EnterPaymentDetails />)
        }}
      >
        {t("common:register_payment")}
      </Button>
    </Stack>
  )
}

export default UnpaidAction
