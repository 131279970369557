const routes = {
  index: "/",
  login: "/login",
  "forget-password": "/forget-password",
  register: "/register",
  dashboard: {
    index: "dashboard",
  },
  profile: {
    index: "dashboard/profile",
  },
  authentication: {
    index: "dashboard/authentication",
  },
  notifications: {
    index: "dashboard/notifications",
  },
  properties: {
    index: "dashboard/properties",
    "my-properties": {
      index: "my-properties",
      show: "show/:id",
      calender: "calender/:id",
    },
    add: "add-property",
    show: "show/:id",
  },
  tickets: {
    index: "dashboard/tickets",
    add: "create-ticket",
    show: "show/:id",
  },
  messages: {
    index: "dashboard/messages",
  },
  financial: {
    index: "dashboard/financial",
  },
  transaction: {
    index: "/transaction",
  },
  syncLogin: "/sync-login",
  syncLogout: "/sync-logout",
};

//publish/show/3

export default routes;
