import * as React from "react";
import ModalContentWrapper from "@/components/shared/modal/modal-content-wrapper";
import { useTranslation } from "react-i18next";
import AddDepositDate from "./add-deposit-date";
import ReportForcastDate from "./forcast-date";
import SaveReportEvents from "./save-events";
import { Stack } from "@mui/material";

interface IProfitDepositProps {}

export enum ProfitDepositTypes {
  AddDepositDate = 0,
  ForcastDate = 1,
  SaveEvents = 2,
}
export const ProfitDeposit: React.FunctionComponent<IProfitDepositProps> = (
  props
) => {
  //Getting transaltion object
  const { t } = useTranslation();
  const [step, setStep] = React.useState(ProfitDepositTypes.AddDepositDate);

  const RenderContent = () => {
    switch (step) {
      case ProfitDepositTypes.AddDepositDate:
        return <AddDepositDate changeStepHandler={setStep} />;
      case ProfitDepositTypes.ForcastDate:
        return <ReportForcastDate changeStepHandler={setStep} />;
      case ProfitDepositTypes.SaveEvents:
        return <SaveReportEvents changeStepHandler={setStep} />;
      default:
        return <AddDepositDate changeStepHandler={setStep} />;
    }
  };

  return (
    <ModalContentWrapper
      title={t("predicting_date_of_deposit_of_interest")}
      titleVariant="subtitleBold"
    >
      <Stack
        minHeight={["calc(100vh - 100px)", , 400]}
        justifyContent={"space-between"}
        alignItems="start"
        height={"auto"}
        width={{ xs: "100%", md: "800px" }}
        maxWidth={"800px"}
      >
        <RenderContent />
      </Stack>
    </ModalContentWrapper>
  );
};
