import * as React from "react";
import { LinearProgress, Stack, Typography } from "@mui/material";
import { SxProps } from "@mui/material/styles";

interface IProgressWithValueProps {
  value: string | number;
  sxProps?: SxProps;
}

const ProgressWithValue: React.FunctionComponent<IProgressWithValueProps> = ({
  value,
  sxProps,
}) => {
  return (
    <Stack
      sx={{
        position: "relative",
        "& .MuiLinearProgress-bar": {
          background: "linear-gradient(to right, #9C5F17, #E29314)",
        },
        ...sxProps,
      }}
    >
      <Typography
        variant="extraSmallMedium"
        sx={{
          position: "absolute",
          zIndex: (t) => t.zIndex.appBar,
          color: (t) =>
            value >= 44 ? t.palette.common.white : t.palette.common.black,
          right: "50%",
          top: "50%",
          transform: "translate(50%, -50%)",
        }}
      >
        {value}%
      </Typography>
      <LinearProgress
        color={"primary"}
        variant="determinate"
        value={Number(value)}
        sx={{
          height: "20px",
          borderRadius: 10,
          transform: "",
          background: (t) => t.palette.grey[300] + "!important",
        }}
      />
    </Stack>
  );
};

export default ProgressWithValue;
