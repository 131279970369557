import { Navigate, useLocation, useNavigate } from "react-router-dom";
import React from "react";
import routes from "./routes";
import { useAuth } from "@/hook";

type PrivateRouteProps = {
  element: React.ReactNode;
};

/**
 * @param {React.ReactNode} element the component to be privated
 * @example `<Route path="path" element={<PrivateRoute element={<HomePage />} />} />`
 */
const PrivateRoute: React.FC<PrivateRouteProps> = ({ element }) => {
  const { login } = routes;

  // Get the location of current page and redirect to it after login
  const location = useLocation();
  const { isLoggedIn } = useAuth();

  const navigator = useNavigate();
  /**
   * This function will return Route element based on user Authorization.
   * @returns Element our redirect to login page
   */
  if (!isLoggedIn)
    window.location.href = `${process.env.REACT_APP_SEPEHRINO_LANDING}/sync-logout`;
  return <>{element}</>;
};

export default PrivateRoute;
