// Built-in import
import * as React from "react";
// External import
import { Stack, Divider } from "@mui/material";
// Internal import
import UploadDocumentsSubtitle from "./subtitle";
import UploadDocumentsForm from "./form";
import { UpdateInfoModalWrapper } from "../../partials";
import { useDispatch } from "react-redux";
import { actionSetCurrentStep } from "@/store/slices/auth";
import { CompleteInfoType } from "@/types/auth";

interface IUploadDocuments {
  userType?: string;
}

const UploadDocuments: React.FC<IUploadDocuments> = (props) => {
  //getting redux dispatcher
  const dispatcher = useDispatch();
  const handleChangeStep = () => {
    dispatcher(actionSetCurrentStep(CompleteInfoType.CompleteInformation));
  };
  return (
    <UpdateInfoModalWrapper handleBack={handleChangeStep}>
      <Stack spacing={1}>
        {/* title */}
        <UploadDocumentsSubtitle />
        <Divider sx={{ flexGrow: 1 }} />
        {/* form */}
        <UploadDocumentsForm />
      </Stack>
    </UpdateInfoModalWrapper>
  );
};

export default UploadDocuments;
