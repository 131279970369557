// Built-in import
import * as React from "react"

import { IServerProperty } from "@/store/slices/property"
import PriceCard from "@/components/shared/property-card/details/price"
import ProgressShared from "@/components/pages/dashboard/single-property/invest-receiver/content/show-property/details/sidebar/investment/progress-shared"
// External import
import { Stack } from "@mui/material"
import StatusBox from "../../status-box"
// Internal import
import { Tag } from "@/components/shared"
import { useTranslation } from "react-i18next"

interface IStatusTabContent {
  item: IServerProperty
}
const StatusTabContent: React.FC<IStatusTabContent> = ({ item }) => {
  // Getting props as item

  const { t } = useTranslation(["common"])

  return (
    <Stack width="100%" spacing={{ xs: 2, md: 1 }} py={[1, , 0]}>
      <Stack
        direction={"row"}
        width="100%"
        justifyContent={"space-between"}
        alignItems="center"
      >
        {Boolean(item.status) && (
          <Tag
            title={t(`common:property_status_${item.status}`)}
            color={item.status}
          />
        )}
      </Stack>
      <PriceCard price={item.min_investment_quantity} />
      <ProgressShared item={item} />
    </Stack>
  )
}

export default StatusTabContent
