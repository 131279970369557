import { IMedia } from "@/types/media";
import { Stack } from "@mui/material";

type Props = {
  image: IMedia;
};

export default function Image({ image }: Props) {
  return (
    <Stack
      sx={{
        height: 240,
        backgroundSize: "cover",
        borderRadius: 3,
        cursor: "pointer",
      }}
      className='w-full h-[240px] bg-cover rounded-md cursor-pointer'
      style={{
        backgroundImage: `url("${image.original_url}")`,
      }}
    ></Stack>
  );
}
