export const financialSingleData = {
  receiver: {
    name: "محمد",
    lastName: "ابن علی",
    nationalCode: "۱۲۷۸۷۹۸۲۳۴",
    shebaNumber: "۰۰۰۲۳۰۱۹۳۷۸۴۶۱۹۱۲۳۲۰",
    accountNumber: "۶۰۳۷۸۷۶۷۵۶۴۳۹۸۱۷",
    bankName: "بانک ملی",
  },
  paymentDetails: {
    date: "۱۴۰۱/۲/۴۵",
    status: "unpaid",
    transactionTrackingCode: "۲۴۱۷۲۶۱۹۳",
    titleOfProperty: "فناوری تحلیل بیگ دیتا",
  },
};
