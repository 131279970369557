import * as React from "react";
import * as Yup from "yup";

import {
  Divider,
  InputAdornment,
  Paper,
  Stack,
  TextField,
  Theme,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  IProperty,
  changeProperty,
  IServerProperty,
} from "@/store/slices/property";

import { AddPropertyStepTypes } from "@/types/properties";
import { AddPropertyStepsActions } from "../../actions";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Radios from "@/components/shared/radios";
import SliderPrice from "@/components/shared/slider-price";
import TextFieldNumberFormat from "@/components/shared/number-format-textfield";
import TitleWithElement from "@/components/shared/title-with-element";
import UntilAmountCapital from "./until";
import convertNumToEnglish from "@/utils/convertPersianDigitsToEnglish";
import { dataRadios } from "../additional-info/data";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Project } from "@/services";
import { toaster } from "@/components/shared/toaster";

interface ICapitalizatinDetails {
  property: IServerProperty;
  isEdit?: boolean;
}

const CapitalizatinDetails: React.FC<ICapitalizatinDetails> = ({
  property,
  isEdit = false,
}) => {
  //get translation object
  const { t } = useTranslation(["common", "validation"]);
  //get react router navigator
  const navigator = useNavigate();
  //setting save project changes loading

  const [saveLoading, setSaveLoading] = React.useState(false);
  //Dispatcher
  const dispatcher = useDispatch();
  //Getting theme
  const theme = useTheme<Theme>();

  //isMobile
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  // Formik
  const {
    values,
    handleSubmit,
    getFieldProps,
    errors,
    touched,
    setFieldValue,
    setFieldError,
  } = useFormik({
    initialValues: {
      monthly_wage: property.monthly_wage || 0,
      min_investment_quantity: property.min_investment_quantity || 500000000,
      max_investment_quantity: property.max_investment_quantity || 500000000000,
      investment_ratio: property.investment_ratio || 0,
      risk_statement: property.risk_statement || "",
      period_months: property.period_months || 0,
      is_guaranteed: property.is_guaranteed ? true : false,
    },
    validationSchema: Yup.object().shape({
      monthly_wage: Yup.string().required(
        t("validation:this_field_is_required")
      ),
      min_investment_quantity: Yup.number().required(
        t("validation:this_field_is_required")
      ),
      max_investment_quantity: Yup.number().required(
        t("validation:this_field_is_required")
      ),
      investment_ratio: Yup.string().required(
        t("validation:this_field_is_required")
      ),
      risk_statement: Yup.string().required(
        t("validation:this_field_is_required")
      ),
    }),
    onSubmit: (values) => {
      //Dispatch to the store
      dispatcher(
        changeProperty({
          ...values,
          investment_ratio: convertNumToEnglish(values.investment_ratio),
          monthly_wage: convertNumToEnglish(values.monthly_wage),
        })
      );
      navigator(`?type=${AddPropertyStepTypes.AdditionalInfo}`);
    },
  });

  const handleChangeMonthInput = (type: "increase" | "decrease") => {
    setFieldValue(
      "period_months",
      type === "increase"
        ? +values.period_months + 1
        : +values.period_months > 1
        ? +values.period_months - 1
        : 1
    );
  };

  //save property in current step
  const handleSaveProject = async () => {
    setSaveLoading(true);
    let finalData = { ...property, ...values };
    try {
      await Project.Update(property.id, finalData);
      toaster.success(t("your_project_updated_successfully"));
      dispatcher(
        changeProperty({
          ...values,
          investment_ratio: convertNumToEnglish(values.investment_ratio),
          monthly_wage: convertNumToEnglish(values.monthly_wage),
        })
      );
    } catch (error) {
      toaster.error(error.response.data);
    } finally {
      setSaveLoading(false);
    }
  };

  return (
    <Stack>
      <Paper
        sx={{
          my: 1,
          p: [2, , 4],
          py: [4, , 4],
          border: "1px solid",
          borderRadius: "12px",
          borderColor: (t) => t.palette.grey[300],
        }}
      >
        <Stack spacing={8}>
          <Stack spacing={2}>
            {/* section monthly interest percentage equivalent & project duration months */}
            <Stack
              direction={{ xs: "column", lg: "row" }}
              spacing={{ xs: 3, lg: 2 }}
            >
              <Stack width={{ xs: "100%", lg: "33.33%" }}>
                <TitleWithElement
                  spacing={0.5}
                  title={t("common:monthly_interest_percentage_equivalent")}
                  element={
                    <TextField
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Typography variant="subtitleBold" color="primary">
                              %
                            </Typography>
                          </InputAdornment>
                        ),
                      }}
                      {...getFieldProps("monthly_wage")}
                      error={Boolean(
                        touched.monthly_wage && errors.monthly_wage
                      )}
                      helperText={touched.monthly_wage && errors.monthly_wage}
                      type="number"
                    />
                  }
                />
              </Stack>
              <Stack width={{ xs: "100%", lg: "33.33%" }}>
                <TitleWithElement
                  spacing={0.5}
                  title={t("common:project_duration_months")}
                  element={
                    <TextField
                      value={values.period_months}
                      {...getFieldProps("period_months")}
                      type="number"
                      sx={{ "& input": { textAlign: "center" } }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment
                            position="start"
                            sx={{ cursor: "pointer" }}
                            onClick={() => handleChangeMonthInput("decrease")}
                          >
                            <KeyboardArrowDownIcon color="primary" />
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment
                            position="end"
                            sx={{ cursor: "pointer" }}
                            onClick={() => handleChangeMonthInput("increase")}
                          >
                            <KeyboardArrowUpIcon color="primary" />
                          </InputAdornment>
                        ),
                        inputProps: { min: 0, max: 10 },
                      }}
                    />
                  }
                />
              </Stack>
              <Stack direction="row" width={{ xs: "100%", lg: "33.33%" }}>
                <TitleWithElement
                  spacing={0.5}
                  title={t("common:is_guaranteed")}
                  element={
                    <Radios
                      items={dataRadios}
                      defaultValue={"" + Number(values.is_guaranteed)}
                      onChange={(val) => {
                        setFieldValue("is_guaranteed", Boolean(+val));
                      }}
                      sxProps={{
                        "& .MuiFormGroup-root": {
                          flexDirection: "row",
                        },
                      }}
                    />
                  }
                />
              </Stack>
            </Stack>
            {/* section monthly interest percentage equivalent & project duration months */}
            <Divider />
            {/* section amount capital required */}
            <Stack spacing={0.5}>
              <Typography variant="bodyBold" color="text.primary">
                {t("common:amount_capital_required")}:
              </Typography>
              <Stack spacing={3}>
                <Stack px={3}>
                  <SliderPrice
                    min={5000000}
                    max={5000000000}
                    step={50}
                    value={[
                      values.min_investment_quantity,
                      values.max_investment_quantity,
                    ]}
                    scale={(val: number) => val}
                    valueLabelFormat={(val: number) =>
                      `${val.withCommas()} ${t("common:toman")}`
                    }
                    onChange={(value) => {
                      setFieldValue("min_investment_quantity", value[0]);
                      setFieldValue("max_investment_quantity", value[1]);
                    }}
                  />
                </Stack>

                <Stack
                  component="form"
                  direction="column"
                  spacing={{ xs: 5, md: 3 }}
                >
                  <Stack
                    direction={{ xs: "column", md: "row" }}
                    spacing={{ xs: 2, md: 3 }}
                    sx={{
                      "& .title-with-element": {
                        flexGrow: 1,
                        width: ["100%", , "initial"],
                      },
                    }}
                    alignItems="center"
                  >
                    <TitleWithElement
                      spacing={0.5}
                      title={t("common:minimum_required_capital")}
                      element={
                        <TextFieldNumberFormat
                          placeholder={`2000000000`.withCommas()}
                          {...getFieldProps("min_investment_quantity")}
                          onChange={(
                            e: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            setFieldValue(
                              "min_investment_quantity",
                              +e.target.value.replaceAll(",", "")
                            );
                          }}
                          value={values.min_investment_quantity}
                          error={Boolean(
                            touched.min_investment_quantity &&
                              errors.min_investment_quantity
                          )}
                          helperText={
                            touched.min_investment_quantity &&
                            errors.min_investment_quantity
                          }
                        />
                      }
                    />
                    {!isMobile && <UntilAmountCapital />}
                    <TitleWithElement
                      spacing={0.5}
                      title={t("common:maximum_required_capital")}
                      element={
                        <TextFieldNumberFormat
                          {...getFieldProps("max_investment_quantity")}
                          onChange={(
                            e: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            setFieldValue(
                              "max_investment_quantity",
                              +e.target.value.replaceAll(",", "")
                            );
                          }}
                          placeholder={`2000000000`.withCommas()}
                          value={values.max_investment_quantity}
                          error={Boolean(
                            touched.max_investment_quantity &&
                              errors.max_investment_quantity
                          )}
                          helperText={
                            touched.max_investment_quantity &&
                            errors.max_investment_quantity
                          }
                        />
                      }
                    />
                  </Stack>

                  <Stack
                    direction="row"
                    sx={{
                      "& .title-with-element": {
                        width: ["100%", , "50%", "40%"],
                      },
                    }}
                  >
                    <TitleWithElement
                      spacing={0.5}
                      title={
                        <Stack
                          direction={"row"}
                          alignItems="center"
                          spacing={1}
                          width="fit-content"
                        >
                          <Typography>
                            {t("common:factor_of_investment_amount_toman")}
                          </Typography>
                          <Tooltip title={t("common:lorem")} arrow>
                            <InfoOutlinedIcon color="info" />
                          </Tooltip>
                        </Stack>
                      }
                      element={
                        <TextFieldNumberFormat
                          placeholder={`4300000000`
                            .withCommas()
                            .withPriceUnit()}
                          {...getFieldProps("investment_ratio")}
                          onChange={(
                            e: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            const investmentRatio = +e.target.value?.replaceAll(
                              ",",
                              ""
                            );
                            setFieldValue("investment_ratio", investmentRatio);
                          }}
                          error={Boolean(
                            touched.investment_ratio && errors.investment_ratio
                          )}
                          helperText={
                            touched.investment_ratio && errors.investment_ratio
                          }
                        />
                      }
                    />
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
            {/* section amount capital required */}
            <Divider />
            {/* section Risk Statement */}
            <Stack>
              <TitleWithElement
                spacing={2}
                title={
                  <Stack
                    direction={"row"}
                    alignItems="center"
                    spacing={1}
                    width="fit-content"
                  >
                    <Typography variant="bodyBold">
                      {t("common:risk_statement")}:
                    </Typography>

                    <Tooltip title={t("common:lorem")} arrow>
                      <InfoOutlinedIcon color="info" />
                    </Tooltip>
                  </Stack>
                }
                element={
                  <TextField
                    multiline
                    rows={10}
                    {...getFieldProps("risk_statement")}
                    error={Boolean(
                      touched.risk_statement && errors.risk_statement
                    )}
                    helperText={touched.risk_statement && errors.risk_statement}
                  />
                }
              />
            </Stack>
            {/* section Risk Statement */}
          </Stack>
          <AddPropertyStepsActions
            isEdit={isEdit}
            saveLoading={saveLoading}
            handleSaveProperty={handleSaveProject}
            handlePrevStep={() =>
              navigator(`?type=${AddPropertyStepTypes.BasicPlanInfo}`)
            }
            handleNextStep={handleSubmit}
          />
        </Stack>
      </Paper>
    </Stack>
  );
};

export default CapitalizatinDetails;
