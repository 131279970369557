import "react-modern-calendar-datepicker/lib/DatePicker.css"

// Built-in import
import * as React from "react"
// External import
import * as Yup from "yup"

import {
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography
} from "@mui/material"
// Internal import
import { MyDatePicker, TitleWithValue } from "@/components/shared"

import InputAdornment from "@mui/material/InputAdornment"
import { LoadingButton } from "@mui/lab"
import { Report } from "@/services"
import convertNumToEnglish from "@/utils/convertPersianDigitsToEnglish"
import { toaster } from "@/components/shared/toaster"
import { useFormik } from "formik"
import { useGlobalContext } from "@/context/global-context"
import { useTranslation } from "react-i18next"

interface IProfitDepositForm {
  projectId: string
  onFetchAllEvents?: () => void
}

export const ProfitDepositForm: React.FC<IProfitDepositForm> = ({
  projectId,
  onFetchAllEvents
}) => {
  //get translation object
  const { t } = useTranslation()
  // modalmodal
  const { closeModal } = useGlobalContext()
  const [expiresAt, setExpiresAt] = React.useState<null | number>(null)
  // formik
  const {
    values,
    isSubmitting,
    touched,
    errors,
    getFieldProps,
    handleSubmit,
    setSubmitting,
    setFieldValue
  } = useFormik({
    initialValues: {
      title: t("deposit_interest_account"),
      revenue_percent: 0
    },
    validationSchema: Yup.object().shape({
      revenue_percent: Yup.string().required(t("common:this_field_is_required"))
    }),
    onSubmit: async (values, actions) => {
      actions.setSubmitting(true)

      try {
        await Report.Create(projectId, "Finance", values)
        toaster.success(t("common:your_event_has_been_added"))
        onFetchAllEvents()
        closeModal()
      } catch (e) {
        toaster.error(e)
      } finally {
        actions.setSubmitting(false)
      }
    }
  })

  const [type, setType] = React.useState(0)

  const handleAddReportInFuture = async () => {
    if (!expiresAt) {
      toaster.warning(t("common:please_select_the_date"))
      return
    }

    setSubmitting(true)
    try {
      await Report.Create(projectId, "Finance", {
        expires_at: expiresAt,
        revenue_percent: values.revenue_percent
      })
      toaster.success(t("common:your_event_has_been_added"))
      onFetchAllEvents()
      closeModal()
    } catch (e) {
      toaster.error(e)
    } finally {
      setSubmitting(false)
    }
  }

  return (
    <Stack
      minHeight={{ xs: "calc(100vh - 100px)", md: 400 }}
      px={2}
      justifyContent={"space-between"}
      alignItems="start"
      spacing={3}
      component="form"
      width={{ xs: "100%", md: "800px", lg: "1000px" }}
      onSubmit={handleSubmit}
      sx={{
        height: "100%",
        maxHeight: "700px",
        overflowY: "scroll"
      }}
    >
      <FormControl fullWidth>
        <RadioGroup
          defaultValue={type}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setType(+e.target.value)
          }
        >
          <Grid container spacing={3} flexWrap="wrap-reverse">
            <Grid item xs={12} md={6}>
              <Stack spacing={2}>
                {/* radio button */}
                <FormControlLabel
                  value={0}
                  control={<Radio />}
                  label={t("common:register_new_report")}
                />

                <Stack spacing={3}>
                  {/* deposited interest percentage */}
                  <TitleWithValue
                    title={t(
                      "common:percentage_of_interest_deposited_at_this_stage"
                    )}
                    hasDoubleDot={false}
                    value={
                      <TextField
                        fullWidth
                        size="small"
                        {...getFieldProps("revenue_percent")}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          setFieldValue(
                            "revenue_percent",
                            convertNumToEnglish(e.target.value)
                          )
                        }
                        error={Boolean(
                          touched.revenue_percent && errors.revenue_percent
                        )}
                        helperText={
                          touched.revenue_percent && errors.revenue_percent
                        }
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <Typography
                                variant="subtitleBold"
                                color="primary"
                              >
                                %
                              </Typography>
                            </InputAdornment>
                          )
                        }}
                      />
                    }
                    direction="column"
                    alignItems={"flex-start"}
                  />
                  {/* interest deposit code */}
                </Stack>
              </Stack>
            </Grid>
            <Grid item xs={12} md={6}>
              <Stack
                sx={{
                  borderLeft: ["none", , "1px solid"],
                  borderColor: (t) => ["none", , t.palette.grey[300]],
                  height: "100%",
                  pl: [0, , 3]
                }}
                spacing={2}
              >
                {/* radio button */}
                <FormControlLabel
                  value={1}
                  control={<Radio />}
                  label={t("common:anticipate_future_reports")}
                />

                {/* date picker */}
                <TitleWithValue
                  title={t("common:time_of_publication_of_report")}
                  hasDoubleDot={false}
                  value={
                    <Stack width="100%">
                      <MyDatePicker
                        onChange={(date) => {
                          setExpiresAt(date.getTime() / 1000)
                        }}
                        title={t("common:date_you_expect_to_report")}
                      />
                    </Stack>
                  }
                  direction="column"
                  alignItems={"flex-start"}
                />

                {/* deposited interest percentage */}
                <TitleWithValue
                  title={t("common:interest_rates")}
                  hasDoubleDot={false}
                  value={
                    <TextField
                      fullWidth
                      size="small"
                      placeholder={t(
                        "common:profit_percentage_you_predict_for_this_stage"
                      )}
                      {...(type === 1 ? getFieldProps("revenue_percent") : {})}
                      error={Boolean(
                        touched.revenue_percent && errors.revenue_percent
                      )}
                      helperText={
                        touched.revenue_percent && errors.revenue_percent
                      }
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Typography
                              variant="subtitleBold"
                              color="text.secondary"
                            >
                              %
                            </Typography>
                          </InputAdornment>
                        )
                      }}
                    />
                  }
                  direction="column"
                  alignItems={"flex-start"}
                />
              </Stack>
            </Grid>
          </Grid>
        </RadioGroup>
      </FormControl>

      {/* submit */}
      <Stack width="100%" alignItems={"flex-end"}>
        {type === 0 && (
          <LoadingButton
            loading={isSubmitting}
            variant="contained"
            type="submit"
          >
            {t("common:record_report")}
          </LoadingButton>
        )}
        {type === 1 && (
          <LoadingButton
            loading={isSubmitting}
            variant="contained"
            onClick={handleAddReportInFuture}
          >
            {t("common:record_report")}
          </LoadingButton>
        )}
      </Stack>
    </Stack>
  )
}
