// Built-in import
import * as React from "react";
// External import
import { Stack, Divider } from "@mui/material";
// Internal import
import LegalInvestorSubtitle from "./subtitle";
import LegalInvestorForm from "./form";
import { useAppSelector } from "@/store/store";
import { updatedUserSelector } from "@/store/slices/auth/selectors";
import { UpdateInfoModalWrapper } from "../../partials";
import { useDispatch } from "react-redux";
import {
  actionSetCurrentStep,
  actionSetSelectedType,
} from "@/store/slices/auth";

interface IBasicInformation {}

const BasicInformation: React.FC<IBasicInformation> = (props) => {
  const userInfo = useAppSelector(updatedUserSelector);

  const dispatcher = useDispatch();
  const handleChangeStep = () => {
    dispatcher(actionSetCurrentStep(null));
    dispatcher(actionSetSelectedType(null));
  };
  return (
    <UpdateInfoModalWrapper handleBack={handleChangeStep}>
      <Stack height="100%" spacing={2}>
        {/* title */}
        <LegalInvestorSubtitle />
        <Divider sx={{ flexGrow: 1 }} />
        {/* form */}
        <LegalInvestorForm userInfo={userInfo} />
      </Stack>
    </UpdateInfoModalWrapper>
  );
};

export default BasicInformation;
