import * as React from "react";
import TitleWithValue from "@/components/shared/title-with-value";
import { useTranslation } from "react-i18next";
import { Button } from "@mui/material";
import { PerformanceReportActionButtons } from "../actions-button";
import { useGlobalContext } from "@/context/global-context";
import AddEventModalContent from "../../..";
import AddIcon from "@mui/icons-material/Add";

export enum AddPerformanceReportTypes {
  AddReport = 0,
  ForcastDate = 1,
  SaveEvents = 2,
}
interface IAddReportProps {
  changeStepHandler: (step: AddPerformanceReportTypes) => void;
}

const AddReport: React.FunctionComponent<IAddReportProps> = ({
  changeStepHandler,
}) => {
  //get translation object
  const { t } = useTranslation();
  //get showModal and close modal from context
  const { showModal, closeModal } = useGlobalContext();
  return (
    <React.Fragment>
      <TitleWithValue
        direction={"column"}
        hasDoubleDot={false}
        spacing={2}
        alignItems={"flex-start"}
        title={t(
          "enter_times_you_expect_to_report_to_investors_during_project_in_this_section"
        )}
        value={
          <Button
            variant="contained"
            onClick={() =>
              changeStepHandler(AddPerformanceReportTypes.ForcastDate)
            }
            startIcon={<AddIcon />}
            sx={{ minWidth: "220px" }}
          >
            {t("add_report")}
          </Button>
        }
      />
      <PerformanceReportActionButtons
        handlePrevStep={() => {
          closeModal();
          showModal(<AddEventModalContent />, { width: "830px" });
        }}
      />
    </React.Fragment>
  );
};

export default AddReport;
