import * as React from "react";

import {
  Grid,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import { WizardStepSlice } from "./step-slice";
import { useTranslation } from "react-i18next";

interface IAddPropertyWizardProps {
  activeSteps: number;
  isEdit?: boolean;
}

export const AddPropertyWizard: React.FunctionComponent<
  IAddPropertyWizardProps
> = ({ activeSteps, isEdit = false }) => {
  // Getting Translation object
  const { t } = useTranslation();
  // Getting mui theme
  const theme = useTheme();
  //Checking viewport environment
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const wizardItems = [
    {
      id: 1,
      title: t("basic_plan_information"),
      step: 0,
    },
    {
      id: 2,
      title: t("capitalization_details"),
      step: 1,
    },
    {
      id: 3,
      title: t("additional_information"),
      step: 2,
    },
    ...(!isEdit
      ? [
          {
            id: 4,
            title: t("executive_calender"),
            step: 3,
          },
        ]
      : []),
  ];

  return (
    <Stack
      sx={{
        background: (t) => ["none", , t.palette.common.white],
        border: ["none", , "1px solid"],
        borderColor: (t) => t.palette.grey[300] + "!important",
        borderRadius: "6px",
        p: 2,
        py: 1.5,
        pb: 2,
      }}
      spacing={2}
      alignItems='center'
    >
      {isMobile && (
        <Typography variant='bodyBold' color='primary'>
          {wizardItems[activeSteps].title}
        </Typography>
      )}

      <Stack width='100%'>
        <Grid container spacing={2}>
          {wizardItems.map((item) => {
            const isActive = item.step <= activeSteps;
            return (
              <Grid item xs={isEdit ? 4 : 3} key={item.id}>
                <WizardStepSlice title={item.title} isActive={isActive} />
              </Grid>
            );
          })}
        </Grid>
      </Stack>
    </Stack>
  );
};
