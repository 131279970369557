import * as React from "react"

import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  SxProps,
  Theme
} from "@mui/material"

import { IRadioItem } from "@/types/radios"

interface IRadiosProps {
  items: IRadioItem[]
  defaultValue?: any
  onChange?: (value: string) => void
  isDisabled?: boolean
  sxProps?: SxProps<Theme>
  direction?: "column" | "row"
}

const Radios: React.FunctionComponent<IRadiosProps> = ({
  items,
  defaultValue,
  isDisabled,
  onChange,
  sxProps = {},
  direction
}) => {
  const [value, setValue] = React.useState("")
  React.useEffect(() => {
    setValue(defaultValue)
  }, [defaultValue])

  const handleChangeRadio = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newVal = e.target.value
    setValue(newVal)
    if (onChange) onChange(newVal)
  }

  return (
    <FormControl sx={{ ...sxProps }}>
      <RadioGroup
        aria-labelledby="controlled-radio-buttons-group"
        onChange={handleChangeRadio}
        value={value}
        sx={{ direction: direction }}
      >
        {items.map((item) => {
          const isActive = item.value !== value
          return (
            <FormControlLabel
              sx={{
                [`& .MuiTypography-root`]: {
                  fontSize: "14px",
                  color: "text.primary"
                }
              }}
              control={<Radio {...(isDisabled && { disabled: isActive })} />}
              key={item.id}
              value={item.value}
              label={item.title}
              disabled={item.disabled || false}
            />
          )
        })}
      </RadioGroup>
    </FormControl>
  )
}

export default Radios
