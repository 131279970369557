import * as React from "react";

import { Stack, Typography } from "@mui/material";

import { IServerProperty } from "@/store/slices/property";
import { ProgressWithValue } from "@/components/shared";

interface IProgressSharedProps {
  item: IServerProperty;
  hasAmount?: boolean;
}

const ProgressShared: React.FunctionComponent<IProgressSharedProps> = ({
  item,
  hasAmount = true,
}) => {
  const percent =
    item?.total_investment_percent > 100 ? 100 : item?.total_investment_percent;

  return (
    <Stack spacing={1}>
      {hasAmount && (
        <Typography variant="smallMedium" color="text.primary">
          {(item.total_investment_quantity &&
            item.total_investment_quantity.withCommas().withPriceUnit()) ||
            "-"}
        </Typography>
      )}
      <ProgressWithValue
        value={percent}
        sxProps={{
          "& .MuiLinearProgress-root": { borderRadius: "8px" },
        }}
      />
    </Stack>
  );
};

export default ProgressShared;
