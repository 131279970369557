import "dayjs/locale/fa";

import * as React from "react";

import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";

import { AdapterDateFnsJalali } from "@mui/x-date-pickers/AdapterDateFnsJalali";
import { humanDateToTime } from "@/utils";

interface IMyDatepickerProps {
  onChange?: Function;
  onChangeDate?: Function;
  onChangeJalali?: Function;
  title?: string;
  value?: any;
  disabled?: boolean;
  disablePast?: boolean;
  defaultValue?: string;
  calendarHeight?: number;
  calendarWidth?: number | string;
}

const MyDatepicker: React.FunctionComponent<IMyDatepickerProps> = ({
  onChange,
  onChangeDate,
  onChangeJalali,
  value,
  disabled = false,
  disablePast = false,
  calendarHeight = 48,
  calendarWidth = "100%",
  title = "Select date ...",
}) => {
  const [currentValue, setCurrentValue] = React.useState(new Date());
  const handleChangeDate = (date) => {
    setCurrentValue(date);
    if (onChange) onChange(date);
    if (onChangeDate) onChangeDate(date);
  };

  React.useEffect(() => {
    if (value) setCurrentValue(new Date(value));
  }, [value]);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFnsJalali}>
      <DatePicker
        sx={{
          width: "auto",
          [`& input`]: {
            padding: "0 16px",
            height: 44,
          },
        }}
        // {...(currentValue && { value: currentValue })}
        value={currentValue}
        defaultValue={new Date()}
        disabled={disabled}
        onChange={handleChangeDate}
        disablePast={disablePast}
      />
    </LocalizationProvider>
  );
};

export default MyDatepicker;
