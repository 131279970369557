// Built-in import
import * as React from "react";
// External import
import { Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

interface IUploadDocumentsFormWarning {}

const UploadDocumentsFormWarning: React.FC<
  IUploadDocumentsFormWarning
> = () => {
  // Getting translation object
  const { t } = useTranslation(["common"]);
  return (
    <Stack>
      <Typography variant="bodyMedium" color="text.secondary">
        {t("common:upload_following_to_zip_file")}:
      </Typography>
      <Typography variant="bodyMedium" color="text.secondary">
        {"1. "}
        {t("common:official_gazette_of_latest_changes")}
      </Typography>
      <Typography variant="bodyMedium" color="text.secondary">
        {"2. "}
        {t("common:official_gazette_of_authorized_signatories")}
      </Typography>
    </Stack>
  );
};

export default UploadDocumentsFormWarning;
