import * as React from "react";

interface IDollarIconProps {
  color?: string;
  width?: number;
  height?: number;
}

const DollarIcon: React.FunctionComponent<IDollarIconProps> = ({
  color = "#EA5455",
  width = 21,
  height = 20,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.2261 9.16667H10.9761V5H14.3095C14.8095 5 15.1428 4.66667 15.1428 4.16667C15.1428 3.66667 14.8095 3.33333 14.3095 3.33333H10.9761V0.833333C10.9761 0.333333 10.6428 0 10.1428 0C9.64281 0 9.30948 0.333333 9.30948 0.833333V3.33333H8.05948C5.97615 3.33333 4.30948 5 4.30948 7.08333C4.30948 9.16667 5.97615 10.8333 8.05948 10.8333H9.30948V15H5.14281C4.64281 15 4.30948 15.3333 4.30948 15.8333C4.30948 16.3333 4.64281 16.6667 5.14281 16.6667H9.30948V19.1667C9.30948 19.6667 9.64281 20 10.1428 20C10.6428 20 10.9761 19.6667 10.9761 19.1667V16.6667H12.2261C14.3095 16.6667 15.9761 15 15.9761 12.9167C15.9761 10.8333 14.3095 9.16667 12.2261 9.16667ZM8.05948 9.16667C6.89281 9.16667 5.97615 8.25 5.97615 7.08333C5.97615 5.91667 6.89281 5 8.05948 5H9.30948V9.16667H8.05948ZM10.9761 15H12.2261C13.3928 15 14.3095 14.0833 14.3095 12.9167C14.3095 11.75 13.3928 10.8333 12.2261 10.8333H10.9761V15Z"
        fill={color}
      />
      <mask
        id="mask0_649_13878"
        maskUnits="userSpaceOnUse"
        x="4"
        y="0"
        width="12"
        height="20"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M12.2261 9.16667H10.9761V5H14.3095C14.8095 5 15.1428 4.66667 15.1428 4.16667C15.1428 3.66667 14.8095 3.33333 14.3095 3.33333H10.9761V0.833333C10.9761 0.333333 10.6428 0 10.1428 0C9.64281 0 9.30948 0.333333 9.30948 0.833333V3.33333H8.05948C5.97615 3.33333 4.30948 5 4.30948 7.08333C4.30948 9.16667 5.97615 10.8333 8.05948 10.8333H9.30948V15H5.14281C4.64281 15 4.30948 15.3333 4.30948 15.8333C4.30948 16.3333 4.64281 16.6667 5.14281 16.6667H9.30948V19.1667C9.30948 19.6667 9.64281 20 10.1428 20C10.6428 20 10.9761 19.6667 10.9761 19.1667V16.6667H12.2261C14.3095 16.6667 15.9761 15 15.9761 12.9167C15.9761 10.8333 14.3095 9.16667 12.2261 9.16667ZM8.05948 9.16667C6.89281 9.16667 5.97615 8.25 5.97615 7.08333C5.97615 5.91667 6.89281 5 8.05948 5H9.30948V9.16667H8.05948ZM10.9761 15H12.2261C13.3928 15 14.3095 14.0833 14.3095 12.9167C14.3095 11.75 13.3928 10.8333 12.2261 10.8333H10.9761V15Z"
          fill={color}
        />
      </mask>
      <g mask="url(#mask0_649_13878)">
        <rect x="0.142822" width="20" height="20" fill={color} />
      </g>
    </svg>
  );
};

export default DollarIcon;
