import * as React from "react";
import { LoadingButton } from "@mui/lab";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { SxProps } from "@mui/material";

interface IAuthSubmitButtonProps {
  title: string;
  disabled?: boolean;
  btnType?: "submit" | "button";
  handleClick?: Function | any;
  sxProps?: SxProps;
  loading?: boolean;
}

const AuthSubmitButton: React.FunctionComponent<IAuthSubmitButtonProps> = ({
  title,
  disabled,
  btnType = "submit",
  handleClick,
  loading = false,
  sxProps,
}) => {
  return (
    <LoadingButton
      loading={loading}
      disabled={disabled}
      type={btnType}
      fullWidth
      {...((btnType === "button" && { onClick: () => handleClick() }) || {})}
      variant="contained"
      startIcon={<ArrowForwardIosIcon fontSize="inherit" />}
      sx={{
        color: (t) => t.palette.common.white,
        ...sxProps,
      }}
    >
      {title}
    </LoadingButton>
  );
};

export default AuthSubmitButton;
