import { api, getEndpoint } from "@/api"
import { getUrlWithQueryParams } from "@/utils"

class Project {
  public static Create(data: object = {}) {
    const formData = new FormData()
    for (let key of Object.keys(data)) {
      formData.append(key, data[key])
      if (key === "media") {
        formData.delete("media")
        for (let index in data[key]) {
          formData.append(`media[${index}]`, data[key][index])
        }
      }

      console.log("key", key)

      if (key === "collaterals") {
        formData.delete("collaterals")
        for (let index in data[key]) {
          formData.append(`collaterals[${index}]`, data[key][index])
        }
      }

      if (key === "is_guaranteed") {
        formData.delete("is_guaranteed")
        if (data["is_guaranteed"] === true) {
          formData.append("is_guaranteed", JSON.stringify(Number(true)))
        } else {
          formData.append("is_guaranteed", JSON.stringify(Number(false)))
        }
      }
    }

    return api.post(
      getUrlWithQueryParams(getEndpoint("projects"), { expand: "media" }),
      formData
    )
  }

  public static Update(id: string, data: object = {}) {
    const formData = new FormData()
    formData.append("_method", "PUT")
    for (let key of Object.keys(data)) {
      formData.append(key, data[key])
      if (key === "is_guaranteed") {
        formData.delete("is_guaranteed")
        if (data["is_guaranteed"] === true) {
          formData.append("is_guaranteed", JSON.stringify(Number(true)))
        } else {
          formData.append("is_guaranteed", JSON.stringify(Number(false)))
        }
      }
      if (key === "media") {
        formData.delete("media")
        for (let index in data["media"]) {
          if (Object.keys(data["media"][index]).includes("collection_name")) {
            formData.append(`media[${index}]`, data["media"][index].id)
          } else {
            if (data?.["background"]?.name === data?.["media"]?.[index]?.name) {
              formData.delete(`media[${index}`)
            } else {
              formData.append(`media[${index}]`, data[key][index])
            }
          }
        }
      }
      if (key === "background") {
        formData.delete("background")
        //exist media ids
        const mediaIds = []
        const targetFile = []
        if (data?.["media"]?.length > 0) {
          for (let item of data["media"]) {
            if (Object.keys(item).includes("collection_name")) {
              mediaIds.push(item.id)
            } else if (item?.name === data?.["background"]?.name) {
              targetFile.push(item)
            }
          }
        }

        if (Object.keys(data["background"]).includes("collection_name")) {
          if (mediaIds.includes(data["background"].id)) {
            formData.append("background", data["background"].id)
          }
        } else if (targetFile.length > 0) {
          formData.append("background", data["background"])
        }
      }
      if (key === "total_shares") {
        formData.delete("total_shares")
      }
      if (key === "meta") {
        formData.delete("meta")
      }
      if (key === "average_profit") {
        formData.delete("average_profit")
      }
      if (key === "finance_statement") {
        if (!data[key]) {
          formData.delete("finance_statement")
        }
      }
      if (key === "company_statement") {
        if (!data[key]) {
          formData.delete("company_statement")
        }
      }
      if (key === "market_statement") {
        if (!data[key]) {
          formData.delete("market_statement")
        }
      }
      if (key === "advantage_statement") {
        if (!data[key]) {
          formData.delete("advantage_statement")
        }
      }
    }

    return api.post(
      getUrlWithQueryParams(getEndpoint("singleProject", { id }), {
        expand: "media",
      }),
      formData
    )
  }
  public static CalcProjectProfit(
    projectId: number | string,
    quantity: number
  ) {
    return api.post(
      getEndpoint("calcProjectProfit", { project_id: projectId }),
      { quantity }
    )
  }
}
export default Project
