// Built-in import
import * as React from "react";
// External import
import { Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
// Internal import
import { TitleWithDescription, Tips } from "@/components/shared";
import { dataTipsEdit } from "./data";

interface IEditExecutiveCalenderSidebar {}

const EditExecutiveCalenderSidebar: React.FC<
  IEditExecutiveCalenderSidebar
> = () => {
  // Getting translation object
  const { t } = useTranslation(["common"]);

  return (
    <Stack spacing={{ xs: 3, md: 2 }} mt={2}>
      {/* project execution calendar */}
      <TitleWithDescription
        primaryColor="text.primary"
        headerVariant="subtitleBold"
        title={
          <Stack direction="row" alignItems="center" spacing={1}>
            <CalendarTodayOutlinedIcon />
            <Typography variant="bodyBold">
              {t("common:create_your_plan_execution_calendar")}
            </Typography>
          </Stack>
        }
        description={t("create_projects_event_description")}
        alignment="justify"
      />
      {/* guide to using calendar */}
      <TitleWithDescription
        primaryColor="text.primary"
        headerVariant="subtitleBold"
        title={
          <Stack direction="row" alignItems="center" spacing={1}>
            <InfoOutlinedIcon />
            <Typography variant="bodyBold">
              {t("common:guide_to_using_calendar")}
            </Typography>
          </Stack>
        }
        description={t("common:guide_to_using_calendar_description")}
        alignment="justify"
      />
      {/* events color */}
      <Stack spacing={2}>
        <Typography variant="bodyBold">{t("common:event_colors")}:</Typography>
        <Stack direction="column" alignItems="start" spacing={1}>
          {dataTipsEdit.map((data) => (
            <Tips
              title={data.title}
              key={data.id}
              color={data.color}
              size="medium"
            />
          ))}
        </Stack>
      </Stack>
    </Stack>
  );
};

export default EditExecutiveCalenderSidebar;
