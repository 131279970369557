import * as React from "react";
import { TitleWithDescription } from "@/components/shared";
import { useAuth } from "@/hook";
import { useTranslation } from "react-i18next";

interface IRegisterAsInvestorTitleProps {}

export const RegisterAsInvestorTitle: React.FunctionComponent<
  IRegisterAsInvestorTitleProps
> = (props) => {
  const { isRole } = useAuth();
  const isFunder = isRole("funder");
  const { t } = useTranslation();

  return (
    <TitleWithDescription
      title={t("register_as_type", {
        type: isFunder ? t("invest_receiver") : t("investor"),
      })}
      spacing={1}
      headerVariant={"subtitleBlack"}
      descriptionVariant={"smallRegular"}
      primaryColor={"text.primary"}
      description={t("specify_your_type_in_sepehrino_system")}
      alignment={{ xs: "center", md: "left" }}
    />
  );
};
