// Built-in import
import * as React from "react"
// External Import
import { useTranslation } from "react-i18next"
import { Button, Stack } from "@mui/material"
// Internal Import
import FileIcon from "@/components/icons/file-icon"

interface IAwaitingConfirmationActionProps {}

const AwaitingConfirmationAction: React.FunctionComponent<IAwaitingConfirmationActionProps> =
  (props) => {
    // Getting Translation object
    const { t } = useTranslation(["common"])
    return (
      <Stack direction="row" spacing={1}>
        <Button variant="outlined" startIcon={<FileIcon color="#E29314" />}>
          {t("common:print_details")}
        </Button>
        <Button variant="contained" startIcon={<FileIcon color="#fff" />}>
          {t("common:transaction_confirmation")}
        </Button>
      </Stack>
    )
  }

export default AwaitingConfirmationAction
