import { IMedia } from "@/types/media";
import { Stack } from "@mui/material";

type Props = {
  image: File;
};

export default function LocalImage({ image }: Props) {
  return (
    <Stack
      sx={{
        height: 240,
        backgroundSize: "cover",
        borderRadius: 3,
        cursor: "pointer",
      }}
      style={{
        backgroundImage: `url(${URL.createObjectURL(image)})`,
      }}
    ></Stack>
  );
}
