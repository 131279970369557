import { ICustomizedPalette } from "../palettes/light"
import VazirBold from "@/assets/fonts/vazir/Vazir-Bold-FD-WOL.woff2"
import VazirLight from "@/assets/fonts/vazir/Vazir-Light-FD-WOL.woff2"
import VazirMedium from "@/assets/fonts/vazir/Vazir-Medium-FD-WOL.woff2"
import VazirRegular from "@/assets/fonts/vazir/Vazir-FD-WOL.woff2"
import VazirThin from "@/assets/fonts/vazir/Vazir-Thin-FD-WOL.woff2"
/**
 * Since creating all font-faces takes lots of code lines, i placed them here for code organization.
 */

const cssBaselineOverrides = (palette: ICustomizedPalette) => {
  return {
    styleOverrides: `
      body {
        background-color:${palette?.paper?.main}
      }
      input{
        font-family:Vazir !important;
        font-size:14px !important
      }
      a {
        text-decoration:none
      }
      .DatePicker input{
        border-color:rgba(0,0,0,0.23)
      }
      @font-face {
        font-family: 'Vazir';
        src: url(${VazirBold}) format('woff');
        font-weight: Bold;
      }
      @font-face {
        font-family: 'Vazir';
        src: url(${VazirMedium}) format('woff');
        font-weight: 500;
      }
      @font-face {
        font-family: 'Vazir';
        src: url(${VazirRegular}) format('woff');
        font-weight: 400;
      }
      @font-face {
        font-family: 'Vazir';
        src: url(${VazirLight}) format('woff');
        font-weight: 300;
      }
      @font-face {
        font-family: 'Vazir';
        src: url(${VazirThin}) format('woff');
        font-weight: 100;
      }

      /* hide number controller from input in Chrome, Safari, Edge, Opera */
      input[type=number]::-webkit-inner-spin-button,  
      input[type=number]::-webkit-outer-spin-button {  
        -webkit-appearance: none; 
        -moz-appearance: none; 
        appearance: none; 
        margin: 0;  
      }
      .swiper {
        width: 100%;
        height: 100%;
      }
      .swiper-slide {
        text-align: center;
        font-size: 18px;
        background: #fff;
        display: -webkit-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        -webkit-justify-content: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        -webkit-align-items: center;
        align-items: center;
      }
      .swiper-slide img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      .swiper {
        width: 100%;
        height: 300px;
        margin-left: auto;
        margin-right: auto;
      }
      .swiper-slide {
        background-size: cover;
        background-position: center;
      }
      .mySwiper2 {
        height: 80%;
        width: 100%;
      }
      .mySwiper {
        height: 20%;
        box-sizing: border-box;
        padding: 10px 0;
      }
      .mySwiper .swiper-slide {
        width: 25%;
        height: 100%;
        opacity: 0.4;
      }
      .mySwiper .swiper-slide-thumb-active {
        opacity: 1;
      }
      .swiper-slide img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      .MuiDateCalendar-root {
        background-color: #fff;
        box-shadow: 0px 2px 16px rgba(0,0,0,0.12);
        border-radius: 8px;
      }
      .MuiPickersPopper-root .MuiPaper-root {
        background-color: transparent;
      }
    `
  }
}

export default cssBaselineOverrides
