// Built-in import
import * as React from "react";
// External import
import { useTranslation } from "react-i18next";
import { Stack, Button } from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
// Internal import
import { TitleWithDescription } from "@/components/shared";
import { useNavigate } from "react-router-dom";

interface ICompleteInformationSubtitle {}

const CompleteInformationSubtitle: React.FC<
  ICompleteInformationSubtitle
> = () => {
  // Getting translation object
  const { t } = useTranslation();

  return (
    <Stack
      direction={{ xs: "column-reverse", md: "row" }}
      justifyContent={"space-between"}
      width="100%"
      spacing={{ xs: 2, md: 0 }}
    >
      <TitleWithDescription
        title={t("completion_of_legal_entity_information")}
        spacing={1}
        headerVariant={"headingBlack"}
        descriptionVariant={"bodyMedium"}
        primaryColor={"text.primary"}
        description={t(
          "to_register_with_legal_identity_complete_the_following_information"
        )}
        alignment={{ xs: "center", md: "left" }}
      />
    </Stack>
  );
};
export default CompleteInformationSubtitle;
