import { IMedia } from "@/types/media";
import { ChangeEvent, memo, useCallback, useEffect, useState } from "react";
import { toaster } from "../toaster";
import { t } from "i18next";
import Upload from "@mui/icons-material/Upload";
import Preview from "./Preview";
import { IconButton, Stack, alpha } from "@mui/material";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
type Props = {
  onUpload: (item: File) => void;
  onDelete?: () => void;
  defaultMedia?: IMedia | File;
  label?: string;
};
function Uploader({ label, defaultMedia, onUpload, onDelete }: Props) {
  const [fileUploaded, setFileUploaded] = useState<IMedia | File | null>(null);
  useEffect(() => {
    if (defaultMedia !== undefined) setFileUploaded(defaultMedia);
  }, [defaultMedia]);
  const handleDelete = useCallback(
    (e: any) => {
      e.preventDefault();
      e.stopPropagation();
      setFileUploaded(null);
      if (onDelete) onDelete();
    },
    [onDelete]
  );

  const handleUpload = async (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files === null) return;

    const targetFile = e.target.files[0];

    if (!targetFile)
      return toaster.warning(t("please_select_valid_file_to_upload"));

    if (onUpload) onUpload(targetFile);
  };

  let uploaderContent = (
    <Stack
      alignItems={"center"}
      justifyContent={"center"}
      sx={{
        position: "relative",
        height: 240,
        borderRadius: 3,
        bgcolor: (t) => alpha(t.palette.common.black, 0.02),
        transition: "0.3s all",
        ":hover": {
          bgcolor: (t) => alpha(t.palette.common.black, 0.1),
        },
        [`& input[type="file"]`]: {
          opacity: 0,
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          cursor: "pointer",
        },
      }}
    >
      <Stack alignItems={"center"} justifyContent={"center"}>
        <Upload />
        <span>{t("please_click_here_to_upload_something")}</span>
      </Stack>
      <input
        type='file'
        className='absolute top-0 left-0 w-full h-full opacity-0 cursor-pointer'
        onChange={handleUpload}
      />
    </Stack>
  );

  if (fileUploaded) {
    uploaderContent = <Preview file={fileUploaded} />;
  }

  return (
    <div className='flex flex-col w-full gap-y-2'>
      {!!label && <strong>{label}</strong>}
      <Stack sx={{ position: "relative", width: "100%" }}>
        {fileUploaded && (
          <IconButton
            sx={{
              position: "absolute",
              top: 8,
              right: 8,
              backgroundColor: (t) => alpha(t.palette.common.black, 0.7),
              ":hover": {
                backgroundColor: (t) => alpha(t.palette.common.black, 0.9),
              },
            }}
            className='absolute top-2 left-2'
            onClick={handleDelete}
            type='button'
          >
            <DeleteOutlineOutlinedIcon
              sx={{ color: (t) => t.palette.common.white }}
            />
          </IconButton>
        )}
        {uploaderContent}
      </Stack>
    </div>
  );
}

export default memo(Uploader);
