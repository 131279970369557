import { t } from "i18next"

export const dataTipsAdd = [
  {
    id: 0,
    title: `${t("common:create_least_one_reporting_event")}.`,
    color: "primary.main"
  }
]
export const dataTipsEdit = [
  {
    id: 1,
    title: t("common:success_event"),
    color: "success.main"
  },
  {
    id: 2,
    title: t("common:financial_event"),
    color: "info.light"
  },
  {
    id: 3,
    title: t("common:performance_report"),
    color: "purple.main"
  },
  {
    id: 4,
    title: t("common:event_deleted"),
    color: "grey.300"
  }
]
