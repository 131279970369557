// Built-in import
import * as React from "react"

// External Import
import {
  Box,
  Button,
  Divider,
  Grid,
  InputAdornment,
  MenuItem,
  Stack,
  TextField,
  alpha
} from "@mui/material"

import DollarIcon from "@/components/icons/dollar-icon"
import { IResponseForFetchPaginationEntity } from "@/api/types"
import { IServerProperty } from "@/store/slices/property"
import MyDatepicker from "../datepicker"
import RepeatIcon from "@/components/icons/repeat-icon"
import SearchBoxWithExport from "../search-box-with-export"
import { getEndpoint } from "@/api"
import { getUrlWithQueryParams } from "@/utils"
import moment from "jalali-moment"
import { useAppSelector } from "@/store/store"
import { useAuth } from "@/hook"
import useFetch from "@/hook/use-fetch"
import { useTranslation } from "react-i18next"
import { userSelector } from "@/store/slices/auth/selectors"

// Internal Import

interface IFinancialTableFilterProps {
  handleReset?: () => void
  values: any
  handleChangeQueries: (key: string, val: any) => void
}

const FinancialTableFilter: React.FunctionComponent<
  IFinancialTableFilterProps
> = ({ handleChangeQueries, handleReset, values }) => {
  // Getting Translation object
  const { t } = useTranslation(["common"])
  // Select Handler And State
  const [selectedType, setSelectedType] = React.useState<String | null>(null)
  const handleChangeSelectedType = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const selectedType = event.target.value

    setSelectedType(selectedType)
    if (handleChangeQueries)
      handleChangeQueries("transactionable_id", selectedType)
  }

  const userInfo = useAppSelector(userSelector)
  const { isRole } = useAuth()
  //checking user role
  const isFunder = isRole("funder")

  //getting projects

  const { data: projectsData } = useFetch<
    IResponseForFetchPaginationEntity<IServerProperty>
  >(
    getUrlWithQueryParams(getEndpoint("projects"), {
      ...(isFunder ? { owner_id: userInfo.id } : { invested: true })
    })
  )

  const projectsItems = (projectsData && projectsData.data.items.data) || []
  // date state
  const [date, setDate] = React.useState(new Date())
  // status state
  const [status, setStatus] = React.useState(null)
  React.useEffect(() => {
    setStatus(values?.status)
  }, [values?.status])

  const formRef = React.useRef<HTMLFormElement>()

  const handleResetForm = () => {
    if (formRef.current) formRef.current.reset()
    if (handleReset) handleReset()
    if (handleChangeQueries) {
      // handleChangeQueries("quantity", 0);
      // handleChangeQueries("status", "pending");
      handleChangeQueries("date_from", "")
      handleChangeQueries("date_to", "")
    }
  }

  return (
    <Stack component="form" ref={formRef}>
      <SearchBoxWithExport handleChangeQueries={handleChangeQueries} />
      <Divider sx={{ my: 2 }} />
      <Box
        sx={{
          my: 1,
          [`& .MuiInputBase-root`]: {
            bgcolor: (t) => alpha(t.palette.common.black, 0.03),
            borderRadius: "12px !important",
            [`& .MuiSelect-outlined `]: {
              pr: "32px !important",
              py: "8.5px !important"
            }
          },
          [`& .MuiInputBase-input`]: {
            fontWeight: 500,
            [`&::placeholder`]: {
              // color: "text.primary",
              opacity: 1
            }
          },
          [`& .MuiFormLabel-root `]: {
            fontSize: "14px",
            fontWeight: 500
          },
          [`& .DatePicker`]: {
            height: "100%",
            [`& input`]: {
              borderRadius: "12px",
              height: "40px !important",
              bgcolor: (t) => `${alpha(t.palette.common.black, 0.03)}!important`
            }
          }
        }}
      >
        <Grid container spacing={2}>
          <Grid item lg={2} md={12} xs={12}>
            <TextField
              placeholder={t("common:amount_of_transaction")}
              size="small"
              variant="outlined"
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="end">
                    <DollarIcon color="#5E5873" width={15} />
                  </InputAdornment>
                )
              }}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                if (handleChangeQueries)
                  handleChangeQueries("quantity", +e.target.value)
              }}
              value={values?.quantity}
            >
              {/* {[t("common:most"), t("common:least")].map((item, key) => (
                  <MenuItem key={key} value={item}>
                    {item}
                  </MenuItem>
                ))} */}
            </TextField>
          </Grid>
          <Grid item lg={2} md={12} xs={12}>
            <TextField
              select
              value={selectedType}
              onChange={handleChangeSelectedType}
              label={t("common:select_project")}
              size="small"
              variant="outlined"
              fullWidth
            >
              {projectsItems
                .map((project) => {
                  return { title: project.title, value: project.id }
                })
                .map((item, key) => (
                  <MenuItem key={key} value={item.value}>
                    {item.title}
                  </MenuItem>
                ))}
            </TextField>
          </Grid>
          <Grid item lg={2} md={12} xs={12}>
            <TextField
              select
              onChange={(e) => {
                setStatus(e.target.value)
                if (handleChangeQueries)
                  handleChangeQueries("status", e.target.value)
              }}
              label={t("common:payment_status")}
              size="small"
              fullWidth
              value={status}
            >
              {["pending", "payback-pending", "verified", "rejected"].map((item, key) => (
                <MenuItem key={key} value={item}>
                  {t(`common:${item}`)}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item lg={3} md={12} xs={12}>
            <MyDatepicker
              value={date}
              onChange={(date) => {
                setDate(date)
              }}
              onChangeDate={(date) => {
                if (handleChangeQueries) {
                  const dateFrom = moment(date).unix()
                  const dateTo = moment(date).add("day", 1).unix()

                  handleChangeQueries("date_from", dateFrom)
                  handleChangeQueries("date_to", dateTo)
                }
              }}
              title={t("common:date")}
            />
          </Grid>
          <Grid item lg={3} md={12} xs={12}>
            <Button
              onClick={handleResetForm}
              startIcon={<RepeatIcon />}
              disableRipple
            >
              {t("common:show_default_mode")}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Stack>
  )
}

export default FinancialTableFilter
