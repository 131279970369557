import * as React from "react";

import FinancialDetails from "./details";
import FinancialSummary from "./summary";
import { ITransaction } from "@/types/transaction";
import MyAccordion from "../../accordion";
import { Stack } from "@mui/material";
interface IFinancialAccordionProps {
  items: ITransaction[];
}

const FinancialAccordion: React.FunctionComponent<IFinancialAccordionProps> = ({
  items,
}) => {
  return (
    <Stack spacing={1.5}>
      {items.map((item) => {
        return (
          <MyAccordion id={item.id} summary={<FinancialSummary item={item} />}>
            <FinancialDetails item={item} />
          </MyAccordion>
        );
      })}
    </Stack>
  );
};

export default FinancialAccordion;
