// Built-in import
import * as React from "react";

import FinancialStep from "./steps/financial";
import { IEvent } from "@/types/event";
import LoadingEmbraced from "@/components/shared/loading-embraced";
import NewsStep from "./steps/news";
import NoItemSelected from "./no-item-selected";
import ReportStep from "./steps/report";
// External import
import { Stack } from "@mui/material";

interface IExecutiveCalenderDetails {
  event?: IEvent;
  loading?: boolean;
  isEditable?: boolean;
  onChangeEvents?: () => void;
}

const ExecutiveCalenderDetails: React.FC<IExecutiveCalenderDetails> = ({
  event,
  loading,
  isEditable = false,
  onChangeEvents,
}) => {
  const [eventItem, setEventItem] = React.useState<IEvent | null>(null);

  const RenderContent = () => {
    switch (eventItem?.type) {
      case "Finance":
        return (
          <FinancialStep
            isEditable={isEditable}
            event={eventItem}
            onChangeEvents={onChangeEvents}
          />
        );
      case "Report":
        return (
          <ReportStep
            isEditable={isEditable}
            event={eventItem}
            onChangeEvents={onChangeEvents}
          />
        );
      case "News":
        return <NewsStep event={eventItem} />;
      default:
        return <NoItemSelected />;
    }
  };

  React.useEffect(() => {
    setEventItem(event);
  }, [event]);

  if (loading) return <LoadingEmbraced />;

  return (
    <Stack
      sx={{
        border: ["none", , "1px solid"],
        borderColor: (t) => ["none", , t.palette.grey[300]],
        height: "100%",
        px: [1, 3],
        py: [2, , 3],
        borderRadius: "12px",
      }}
      spacing={5}
    >
      {!eventItem ? <NoItemSelected /> : <RenderContent />}
    </Stack>
  );
};

export default ExecutiveCalenderDetails;
