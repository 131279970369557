// Built-in import
import * as React from "react";

// External Import
import { Box, Button, Grid } from "@mui/material";

import FileIcon from "@/components/icons/file-icon";
// Internal Import
import { SearchBox } from "@/components/shared";
import { useTranslation } from "react-i18next";
import { api, getEndpoint } from "@/api";
import { toaster } from "../toaster";
import { LoadingButton } from "@mui/lab";

interface ISearchBoxWithExportProps {
  handleChangeQueries: (key: string, val: any) => void;
}

const SearchBoxWithExport: React.FunctionComponent<
  ISearchBoxWithExportProps
> = ({ handleChangeQueries }) => {
  // Getting Translation object
  const { t } = useTranslation(["common"]);

  //set loading for export excel btn
  const [loading, setLoading] = React.useState(false);
  const [s, setSearch] = React.useState("");

  const handleChange = (val) => {
    setSearch(val);
    if (handleChangeQueries) handleChangeQueries("s", val);
  };

  const handleExportExcel = () => {
    setLoading(true);
    api({
      method: "GET",
      url: getEndpoint("exportExcelTranasction"),
    })
      .then((res) => {
        window.open(res.data.data.item);
        setLoading(false);
      })
      .catch((err) => {
        toaster.error(err.response.data);
        setLoading(false);
      });
  };

  return (
    <Box>
      <Grid container spacing={2} alignItems="center">
        <Grid item lg={9.5} md={12} xs={12}>
          <SearchBox
            sxProps={{
              my: 0,
            }}
            handleChange={handleChange}
          />
        </Grid>
        <Grid item lg={2.5} md={12} xs={12}>
          <LoadingButton
            startIcon={<FileIcon color="#E29314" />}
            variant="outlined"
            loading={loading}
            fullWidth
            onClick={handleExportExcel}
          >
            {t("common:get_table_output")}
          </LoadingButton>
        </Grid>
      </Grid>
    </Box>
  );
};

export default SearchBoxWithExport;
