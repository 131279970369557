import * as React from "react";

import { Stack, CircularProgress } from "@mui/material";

type LoadingEmbracedType =
  | "inherit"
  | "primary"
  | "secondary"
  | "error"
  | "info"
  | "success"
  | "warning";
interface ILoadingEmbracedProps {
  color?: LoadingEmbracedType;
  size?: number;
}

const LoadingEmbraced: React.FunctionComponent<ILoadingEmbracedProps> = ({
  color = "inherit",
  size = 24,
}) => {
  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      sx={{ width: "100%", height: "100%", minHeight: 300 }}
    >
      <CircularProgress size={size} color={color} />
    </Stack>
  );
};

export default LoadingEmbraced;
