import * as React from "react"

import { Chip, Theme, useTheme } from "@mui/material"
import { alpha as MuiAlpha } from "@mui/material"

import { useTranslation } from "react-i18next"

interface IStatusBoxProps {
  status?: string
  alpha?: number
  label?: string
}

const StatusBox: React.FunctionComponent<IStatusBoxProps> = ({
  status,
  alpha = 1,
  label,
}) => {
  // Getting Translation object
  const { t } = useTranslation(["common"])
  // Getting mui theme
  const theme = useTheme<Theme>()
  // color box status
  let colorStatus: string
  // color text status
  let colorText: string
  // text status
  let textStatus: string = ""

  switch (status) {
    case "success": {
      colorText = alpha
        ? theme.palette.success.main
        : theme.palette.common.white
      colorStatus = MuiAlpha(theme.palette.success.main, alpha)
      textStatus = label || t("common:paid")
      break
    }
    case "seeding": {
      colorText = alpha
        ? theme.palette.success.main
        : theme.palette.common.white
      colorStatus = MuiAlpha(theme.palette.success.main, alpha)
      textStatus = label || t("common:seeding")
      break
    }
    case "Enabled": {
      colorText = alpha
        ? theme.palette.success.main
        : theme.palette.common.white
      colorStatus = MuiAlpha(theme.palette.success.main, alpha)
      textStatus = label || t("common:enabled")
      break
    }
    case "paid": {
      colorText = alpha
        ? theme.palette.success.main
        : theme.palette.common.white
      colorStatus = MuiAlpha(theme.palette.success.main, alpha)
      textStatus = label || t("common:paid")
      break
    }
    case "sent": {
      colorText = alpha
        ? theme.palette.success.main
        : theme.palette.common.white
      colorStatus = MuiAlpha(theme.palette.success.main, alpha)
      textStatus = label || t("common:sent")
      break
    }
    case "error": {
      colorText = alpha ? theme.palette.error.main : theme.palette.common.white
      colorStatus = MuiAlpha(theme.palette.error.main, alpha)
      textStatus = label || t("common:error")
      break
    }
    case "unpaid": {
      colorText = alpha ? theme.palette.error.main : theme.palette.common.white
      colorStatus = MuiAlpha(theme.palette.error.main, alpha)
      textStatus = label || t("common:unpaid")
      break
    }
    case "failed": {
      colorText = alpha ? theme.palette.error.main : theme.palette.common.white
      colorStatus = MuiAlpha(theme.palette.error.main, alpha)
      textStatus = label || t("common:didnt_send")
      break
    }
    case "disabled":
    case "Disabled": {
      colorText = alpha ? theme.palette.error.main : theme.palette.common.white
      colorStatus = MuiAlpha(theme.palette.error.main, alpha)
      textStatus = label || t("common:disabled")
      break
    }
    case "sending": {
      colorText = alpha
        ? theme.palette.primary.main
        : theme.palette.common.white
      colorStatus = MuiAlpha(theme.palette.primary.main, alpha)
      textStatus = label || t("common:sending")
      break
    }
    case "planned": {
      colorText = alpha ? theme.palette.info.main : theme.palette.common.white
      colorStatus = MuiAlpha(theme.palette.info.main, alpha)
      textStatus = label || t("common:planned")
      break
    }
    case "warning": {
      colorText = alpha
        ? theme.palette.warning.main
        : theme.palette.common.white
      colorStatus = MuiAlpha(theme.palette.warning.main, alpha)
      textStatus = label || t("common:paying")
      break
    }
    case "Pending": {
      colorText = alpha
        ? theme.palette.warning.main
        : theme.palette.common.white
      colorStatus = MuiAlpha(theme.palette.warning.main, alpha)
      textStatus = label || t("common:pending")
      break
    }
    case "info": {
      colorText = alpha
        ? theme.palette.warning.main
        : theme.palette.common.white
      colorStatus = MuiAlpha(theme.palette.warning.main, alpha)
      textStatus = label || t("common:pending")
      break
    }
    case "rejected":
    case "Rejected":
      colorText = alpha ? theme.palette.error.main : theme.palette.common.white
      colorStatus = MuiAlpha(theme.palette.error.main, alpha)
      textStatus = label || t("common:rejected")
      break
    case "Accepted": {
      colorText = alpha
        ? theme.palette.success.main
        : theme.palette.common.white
      colorStatus = MuiAlpha(theme.palette.success.main, alpha)
      textStatus = label || t("common:accepted")
      break
    }
    case "suspended": {
      colorText = alpha ? theme.palette.error.main : theme.palette.common.white
      colorStatus = MuiAlpha(theme.palette.error.main, alpha)
      textStatus = label || t("common:suspended")
      break
    }
    default: {
      colorText = alpha
        ? theme.palette.primary.main
        : theme.palette.common.white
      colorStatus = MuiAlpha(theme.palette.primary.main, alpha)
      textStatus = label || status
      break
    }
  }

  if (alpha === 1) {
    colorText = "#fff !important"
  }

  console.log(status, "STATUS")

  return (
    <Chip
      label={textStatus}
      sx={{ background: colorStatus, color: colorText }}
    />
  )
}

export default StatusBox
