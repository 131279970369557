// Built-in import
import * as React from "react";

import { Box, Stack, SxProps, Theme } from "@mui/material";
import { IProperty, IServerProperty } from "@/store/slices/property";
//External import
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
} from "@mui/lab";

// Internal import
import AddEventButton from "./add-event-button";
import EditEventModal from "./edit-event-modal";
import { EventCard } from "@/components/shared";
import { IEvent } from "@/types/event";
import { IEventType } from "@/types/event";
import NoItemsWasFound from "@/components/shared/no-items";
import { useAppSelector } from "@/store/store";
import { useGlobalContext } from "@/context/global-context";
import { userSelector } from "@/store/slices/auth/selectors";
import { useAuth } from "@/hook";

interface IExecutiveCalendarCards {
  property?: IProperty & IServerProperty;
  propertyId?: string;
  events?: IEvent[];
  handleSelectEventId?: (event: IEvent, isEditable: boolean) => void;
  onFetchAllEvents?: () => void;
  sx?: SxProps<Theme>;
  isInvestor?: boolean;
}

const ExecutiveCalendarCards: React.FC<IExecutiveCalendarCards> = ({
  propertyId,
  property,
  events = [],
  onFetchAllEvents,
  handleSelectEventId,
  isInvestor = false,
  sx = {},
}) => {
  //getting userInfo from store
  const userInfo = useAppSelector(userSelector);
  //getting modal from global context
  const { showModal } = useGlobalContext();

  // check for project is mine or not
  const isMyProject = Boolean(userInfo?.id === property?.owner_id);
  // Style timeline
  const styleTimeLine = {
    alignItems: "start",
    justifyContent: "start",
    textAlign: "left",
    "& .MuiTimelineItem-root": {
      width: "100%",
      "& .MuiTimelineConnector-root": {
        display: events.length ? "block" : "none",
      },
      "&::before": {
        p: "0 !important",
        m: "0 !important",
        width: "1px !important",
        display: "none",
      },
    },
  };
  // Style main timeline
  const styleMainTimeLine: any = {
    borderRadius: 2,
    border: "1px solid #99999940",
    overflowY: "scroll",
    overflowX: ["scroll", "hidden"],
    height: "100%",
    minHeight: "600px",
    px: { xs: 0, md: 2 },
  };
  //style for edit event modal
  const editEventModalStyle: SxProps<Theme> = {
    width: "100vw",
    height: "100vh",
    maxHeight: "auto",
    borderTopRightRadius: "none",
    borderTopLeftRadius: "none",
    borderBottomRightRadius: "none",
    borderBottomLeftRadius: "none",
  };

  const handleOpenEditEventModal = (event: IEvent) => {
    showModal(
      <EditEventModal
        isInvestor={Boolean(!isMyProject && isInvestor)}
        item={event}
        projectId={propertyId}
        onFetch={onFetchAllEvents}
      />,
      editEventModalStyle
    );
  };

  // if (!events.length) return <NoItemsWasFound />;
  return (
    <Stack sx={{ ...styleMainTimeLine, ...sx }}>
      <Timeline
        sx={{
          ...styleTimeLine,
        }}
      >
        {/* executive calender card */}
        {events.length ? (
          events.map((event, key) => {
            const isFirstCard = key === 0;
            //checking for select deleted item from client and awaiting admin confirmation
            const isPendingDelete = Boolean(
              event.status === "Deleted" && event.deleted_at === null
            );
            return (
              <TimelineItem
                key={event.id}
                className={
                  !isFirstCard || isPendingDelete || event.status === "Deleted"
                    ? "isDisabled"
                    : ""
                }
              >
                <TimelineSeparator>
                  <TimelineDot />
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                  {Boolean(isMyProject || isInvestor) ? (
                    <Box
                      sx={{ cursor: "pointer" }}
                      onClick={() => handleOpenEditEventModal(event)}
                    >
                      <EventCard item={event} />
                    </Box>
                  ) : (
                    <Box
                      {...(handleSelectEventId && {
                        onClick: () => handleSelectEventId(event, isFirstCard),
                      })}
                      sx={{ cursor: "pointer" }}
                    >
                      <EventCard item={event} />
                    </Box>
                  )}
                </TimelineContent>
              </TimelineItem>
            );
          })
        ) : (
          <NoItemsWasFound />
        )}
        {/* button add time line */}
        {isMyProject ? (
          <AddEventButton
            propertyId={property.id}
            onFetchAllEvents={onFetchAllEvents}
          />
        ) : null}
      </Timeline>
    </Stack>
  );
};

export default ExecutiveCalendarCards;
