import * as React from "react";
import { Stack, Typography } from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import routes from "@/navigation/routes";
import { useQuery } from "@/hook";
import { ForgetPasswordSteps, LoginSteps } from "@/types/auth";

interface IEditMobileNumberProps {}

export const EditMobileNumber: React.FunctionComponent<
  IEditMobileNumberProps
> = (props) => {
  const { t } = useTranslation();

  const navigator = useNavigate();

  const query = useQuery();
  const resetWay = query.get("reset_way");

  //getting router navigator
  const location = useLocation();

  const EditNumberLink = resetWay
    ? `${routes["forget-password"]}?type=${ForgetPasswordSteps.EnterMobileNUmber}&input=${resetWay}`
    : location.pathname.includes("login")
    ? `${routes.login}?type=${LoginSteps.EnterMobileNumber}`
    : -1;

  return (
    <Stack
      direction={"row"}
      alignItems={"center"}
      spacing={0.5}
      sx={{ "& a": { color: (t) => t.palette.primary.main, fontSize: "12px" } }}
    >
      <Link to={EditNumberLink as any}>{t("edit")}</Link>
      <Typography variant="extraSmallRegular">{t("mobile_number")}</Typography>
    </Stack>
  );
};
