import "./i18n/i18n"

import { persistor, store } from "./store/store"

import { CssBaseline } from "@mui/material"
import { GlobalContextProvider } from "./context"
import { PersistGate } from "redux-persist/integration/react"
import { Provider } from "react-redux"
import RTL from "./mui/rtl/RTL"
import RouteElements from "./navigation"
import { ToastContainer } from "./components/shared/toaster"
import { t } from "i18next"

function App() {
  return (
    <RTL>
      <Provider store={store}>
        <GlobalContextProvider>
          <PersistGate
            loading={<>{t("common:loading")}</>}
            persistor={persistor}
          >
            <CssBaseline />
            <RouteElements />
            <ToastContainer />
          </PersistGate>
        </GlobalContextProvider>
      </Provider>
    </RTL>
  )
}

export default App
