//Built-in Import
import * as React from "react"

//External Import
import { Stack, alpha, Typography, SxProps, Theme } from "@mui/material"

//Internal Import

interface IBoxWithTitleProps {
  title: string
  children?: React.ReactNode
  sxProps?: SxProps<Theme>
}

const BoxWithTitle: React.FunctionComponent<IBoxWithTitleProps> = ({
  title,
  children,
  sxProps
}) => {
  return (
    <Stack
      sx={{
        bgcolor: (t) => t.palette.common.white,
        borderRadius: "6px",
        ...sxProps
      }}
    >
      <Stack
        className="title-holder"
        sx={{
          borderBottom: "1px solid",
          borderColor: (t) => alpha(t.palette.common.black, 0.12),
          p: 2
        }}
      >
        <Typography variant="bodyBold" color="text.primary">
          {title}
        </Typography>
      </Stack>
      <Stack
        className="children-holder"
        sx={{
          p: 2
        }}
      >
        {children}
      </Stack>
    </Stack>
  )
}

export default BoxWithTitle
