import { IMedia } from "@/types/media";
import VideoCameraBackIcon from "@mui/icons-material/VideoCameraBack";
import { Button, Stack } from "@mui/material";
import { Link } from "react-router-dom";

type Props = {
  video: IMedia;
};

export default function Video({ video }: Props) {
  return (
    <Stack
      sx={{
        height: 240,
        backgroundSize: "cover",
        borderRadius: 3,
        cursor: "pointer",
      }}
    >
      <div className='flex flex-col h-full gap-y-4 items-center justify-center'>
        <VideoCameraBackIcon />
        <span className='whitespace-pre-wrap w-[70%] text-ellipsis overflow-hidden'>
          {video.name}
        </span>
        <Link to={`${video.original_url}`} target='_blank'>
          <Button type='button'>Open Video</Button>
        </Link>
      </div>
    </Stack>
  );
}
