import TitleWithValue from "@/components/shared/title-with-value";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { alpha, Stack, Button } from "@mui/material";
import { PerformanceReportActionButtons } from "../actions-button";
import { ProfitDepositTypes } from "..";
import ForcastInputDate from "./date-input";
import YourEstimatedPercentage from "./estimated-percentage";
interface IReportForcastDate {
  changeStepHandler: (step: ProfitDepositTypes) => void;
}

const ReportForcastDate: React.FunctionComponent<IReportForcastDate> = ({
  changeStepHandler,
}) => {
  //getting translation object
  const { t } = useTranslation();

  const [value, setValue] = React.useState("");

  return (
    <React.Fragment>
      <Stack
        p={3}
        sx={{
          border: "1px solid",
          borderColor: (t) => `${alpha(t.palette.common.black, 0.2)}`,
          borderRadius: 2,
        }}
      >
        <Stack
          direction={{ xs: "column", md: "row" }}
          spacing={1}
          width={"fit-content"}
          alignItems="start"
        >
          <TitleWithValue
            direction={"column"}
            spacing={1}
            variantTitle={"smallRegular"}
            hasDoubleDot={false}
            alignItems={"flex-start"}
            title={t("expected_report_presentation_date")}
            value={
              <ForcastInputDate onChange={(value: string) => setValue(value)} />
            }
          />

          <TitleWithValue
            direction={"column"}
            spacing={1}
            variantTitle={"smallRegular"}
            hasDoubleDot={false}
            alignItems={"flex-start"}
            title={t("your_estimated_percentage")}
            value={
              <YourEstimatedPercentage
                onChange={(value: string) => setValue(value)}
              />
            }
          />
        </Stack>
        <Stack direction={"row"} mt={2}>
          <Button
            disabled={Boolean(!value.length)}
            variant="contained"
            onClick={() => changeStepHandler(ProfitDepositTypes.SaveEvents)}
          >
            {t("submit_report_event")}
          </Button>
        </Stack>
      </Stack>

      <PerformanceReportActionButtons
        handlePrevStep={() =>
          changeStepHandler(ProfitDepositTypes.AddDepositDate)
        }
      />
    </React.Fragment>
  );
};

export default ReportForcastDate;
