//Built-in import
import * as React from "react";

//External import
import {
  IconButton,
  Stack,
  SxProps,
  Theme,
  Typography,
  alpha,
} from "@mui/material";
import { useAuth, useMedia } from "@/hook";

import AttachFileIcon from "@mui/icons-material/AttachFile";
import { IMedia } from "@/types/media";
import { ITicketMessage } from "@/types/ticket";
import TicketMessageImage from "./message-image";
import UserInformation from "@/components/shared/user-information";
import { humanDate } from "@/utils";

//Internal import

interface IChatboxMessageItemProps {
  item: ITicketMessage;
  beforeMessage: ITicketMessage | null;
}

const ChatboxMessageItem: React.FunctionComponent<IChatboxMessageItemProps> = ({
  item,
  beforeMessage,
}) => {
  const { user } = useAuth();

  //Getting user id
  const currentUserId = user.id;

  //Detecting this user id is mine or not
  const isMe = Boolean(currentUserId === item.user_id);

  //isMe style
  const isMeStyle: SxProps<Theme> = {
    bgcolor: (t) => alpha(t.palette.primary.main, 0.2),
    color: (t) => t.palette.common.black,
    border: 1,
    borderColor: (t) => t.palette.primary.main,
  };
  //isNotMe style
  const isNotMeStyle: SxProps<Theme> = {
    bgcolor: (t) => t.palette.common.white,
    color: (t) => t.palette.primary.main,
    border: 1,
    borderColor: (t) => t.palette.common.black,
  };

  //Check last message user id is same or not
  const isShowUserInformation = Boolean(
    item.user_id !== beforeMessage?.user_id
  );

  //Align Items direction
  const alignItemsDirection = isMe ? "flex-start" : "flex-end";

  const MessageItemHolder = ({
    children,
    minWidth = true,
  }: {
    children: React.ReactNode;
    minWidth?: boolean;
  }) => (
    <Stack
      sx={{
        mb: 0.5,
        p: 1,
        borderRadius: "8px",
        position: "relative",
        ...(minWidth
          ? {
              minWidth: 180,
            }
          : {}),
        maxWidth: "70%",
        ...(isMe ? isMeStyle : isNotMeStyle),
      }}
    >
      {children}
    </Stack>
  );

  const DateShower = () => (
    <Typography
      variant="smallBold"
      component="span"
      color="common.balck"
      sx={{ fontSize: 10 }}
    >
      {humanDate(item.created_at, "HH:mm - jMMMM jD")}
    </Typography>
  );

  const attachment = useMedia(item?.media, "attachments") as IMedia;
  const isImage = attachment && attachment.mime_type.includes("image");

  return (
    <Stack
      alignItems={alignItemsDirection}
      sx={{ width: "100%", mt: 0 }}
      spacing={1}
    >
      {isShowUserInformation && <UserInformation user={item?.user} />}
      <Stack alignItems={"flex-start"} direction="column">
        {attachment && (
          <MessageItemHolder minWidth={false}>
            {isImage && <TicketMessageImage media={attachment} />}
            {!isImage && (
              <IconButton href={attachment.original_url} target="_blank">
                <AttachFileIcon />
              </IconButton>
            )}
          </MessageItemHolder>
        )}
        <MessageItemHolder>
          <Typography variant="smallLight" component={"span"}>
            {item.message}
          </Typography>
        </MessageItemHolder>
        <DateShower />
      </Stack>
    </Stack>
  );
};

export default ChatboxMessageItem;
