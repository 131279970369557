//Built-in import
import * as React from "react";

//External import
import { Box } from "@mui/material";
//Internal import
import ChatboxInputButton from "./button";
import { useTranslation } from "react-i18next";

interface IMessageTextFieldProps {
  handleChange: Function;
  addMessage: Function;
  val: string;
}

const MessageTextField: React.FunctionComponent<IMessageTextFieldProps> = ({
  handleChange,
  addMessage,
  val,
}) => {
  const [file, setFile] = React.useState<null | File>(null);
  const handleChangeFile = React.useCallback((file: File) => {
    setFile(file);
  }, []);
  const [text, setText] = React.useState("");

  //Getting translation  object
  const { t } = useTranslation(["common"]);

  const handleAddingMessage = () => {
    addMessage(text, file);
    setFile(null);
    setText("");
  };

  //Detect onEnter
  const onEnterInput = (e: React.KeyboardEvent<HTMLInputElement>) => {
    //Detect when user keyUp the enter
    if (e.keyCode === 13) {
      //Add message function
      handleAddingMessage();
    }
  };

  return (
    <Box
      sx={{
        width: "100%",
        position: "relative",
        [`& input`]: {
          border: 0,
          width: "100%",
          outline: 0,
          p: 2,
          fontSize: 18,
        },
      }}
    >
      <input
        type="text"
        value={text}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          const newVal = e.target.value;
          handleChange(newVal);
          setText(newVal);
        }}
        onKeyUp={onEnterInput}
        placeholder={t("common:enter_your_message_or_request_text")}
      />
      <ChatboxInputButton
        handleChangeFile={handleChangeFile}
        text={val}
        addMessage={handleAddingMessage}
      />
    </Box>
  );
};

export default React.memo(MessageTextField);
