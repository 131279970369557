"use client";
import { memo, useCallback, useEffect, useState } from "react";
import Uploader from ".";
import { Grid, Stack, Typography } from "@mui/material";
import { IMedia } from "@/types/media";

type Props = {
  title?: string;
  defaultItems?: (File | IMedia)[];
  onChange: (items: (File | IMedia)[]) => void;
};
function GalleryHandler({ title, defaultItems, onChange }: Props) {
  const [items, setItems] = useState<(File | IMedia)[]>([]);
  useEffect(() => {
    if (defaultItems !== undefined) setItems(defaultItems);
  }, [defaultItems]);
  const handleDelete = useCallback(
    (item: File | IMedia) => {
      const nItems = items.filter((x) => x.name !== item.name);
      setItems(nItems);
      if (onChange) onChange(nItems);
    },
    [onChange]
  );

  const handleAdd = (item: File) => {
    const nItems = [...items, item];
    setItems(nItems);
    if (onChange) onChange(nItems);
  };

  const addNode = <Uploader onUpload={handleAdd} />;

  return (
    <Stack>
      {!!title && (
        <Typography
          sx={{ mb: 2 }}
          variant='bodyBold'
          className='text-base font-bold'
        >
          {title}
        </Typography>
      )}
      <Grid spacing={2} container>
        {items.map((item, index) => (
          <Grid
            item
            lg={4}
            sm={12}
            md={12}
            xs={12}
            key={`${item.name}-${item.size}-${index + 1}`}
          >
            <Uploader
              defaultMedia={item}
              onUpload={handleAdd}
              onDelete={() => handleDelete(item)}
            />
          </Grid>
        ))}
        <Grid item lg={4} sm={12} md={12} xs={12}>
          {addNode}
        </Grid>
      </Grid>
    </Stack>
  );
}

export default memo(GalleryHandler);
