// withCommas and ...
import "@/extends/extendedPrototypes"

import * as React from "react"

import { Navigate, Route, Routes, json, useLocation } from "react-router-dom"
import {
  actionAuthSetLoading,
  actionAuthSetLoadingOff,
  actionSetLogout,
  actionSetUser,
  actionUpdateUserInfo,
} from "@/store/slices/auth"
import { api, getEndpoint } from "@/api"

import { IResponseForFetchEntity } from "@/api/types"
import { IUser } from "@/types/user"
import LazyRoute from "./lazy"
import PrivateRoute from "./PrivateRoute"
import { getUrlWithQueryParams } from "@/utils"
import routes from "./routes"
import { useAuth } from "@/hook"
import { useDispatch } from "react-redux"

//Panel page
const DashboardPage = React.lazy(() => import("@/components/pages/dashboard"))
//Transaction Page
const TransactionPage = React.lazy(
  () => import("@/components/pages/transaction")
)
// My Properties Page
const MyPropertiesPage = React.lazy(
  () => import("@/components/pages/dashboard/my-properties")
)

//Profile page
const ProfilePage = React.lazy(
  () => import("@/components/pages/dashboard/profile")
)

//Panel page
const DashboardWrapper = React.lazy(
  () => import("@/containers/dashboard-wrapper")
)
//Properties page
const PropertiesPage = React.lazy(
  () => import("@/components/pages/dashboard/properties")
)
//Add Property Page
const AddPropertyPage = React.lazy(
  () => import("@/components/shared/add-property")
)
const SingleProperty = React.lazy(
  () => import("@/components/pages/dashboard/single-property")
)

//Tickets page
const TicketsHolder = React.lazy(
  () => import("@/components/pages/dashboard/tickets")
)
const CreateTicket = React.lazy(
  () => import("@/components/pages/dashboard/tickets/create")
)

//Add Financial Page
const FinancialPage = React.lazy(
  () => import("@/components/pages/dashboard/financial")
)
//Notifications page
const Notifications = React.lazy(
  () => import("@/components/pages/dashboard/notifications")
)
//single ticket view
const ShowSingleTicket = React.lazy(
  () => import("@/components/pages/dashboard/tickets/show")
)
//sync login from landing
const SyncLogin = React.lazy(() => import("@/components/pages/sync-login"))
//sync logout from landing
const SyncLogout = React.lazy(() => import("@/components/pages/sync-logout"))
//not found page

const NotFoundPage = React.lazy(
  () => import("@/components/shared/not-found-page")
)

const RouteElements = () => {
  const { isLoggedIn } = useAuth()

  const dispatcher = useDispatch()

  const getUserProfile = async () => {
    if (!isLoggedIn) return

    //Showing loading
    dispatcher(actionAuthSetLoading)
    try {
      const res = await api.get<IResponseForFetchEntity<IUser>>(
        getUrlWithQueryParams(getEndpoint("profile"), {
          expand: "media,roles",
        })
      )
      const userProfile = res?.data?.data?.item || null
      dispatcher(actionSetUser({ user: userProfile }))
      dispatcher(actionUpdateUserInfo(userProfile))
    } catch (e) {
      //Loggin out user
      dispatcher(actionSetLogout)
    } finally {
      //Disableing loading
      dispatcher(actionAuthSetLoadingOff)
    }
  }

  //Getting profile
  React.useEffect(() => {
    if (isLoggedIn) {
      if (!window.location.href?.includes("sync-logout")) {
        getUserProfile()
      }
    }
  }, [window.location.href, isLoggedIn])

  //deconstract route names from routes
  const {
    dashboard,
    index,
    properties,
    notifications,
    profile,
    financial,
    tickets,
    transaction,
    syncLogin,
    syncLogout,
  } = routes

  return (
    <Routes>
      <Route
        index
        element={<LazyRoute element={<Navigate to={`${dashboard.index}`} />} />}
      />
      <Route path={syncLogin} element={<LazyRoute element={<SyncLogin />} />} />
      <Route
        path={syncLogout}
        element={<LazyRoute element={<SyncLogout />} />}
      />
      <Route
        path={transaction.index}
        element={<LazyRoute element={<TransactionPage />} />}
      />
      <Route
        path={index}
        element={
          <LazyRoute
            element={<PrivateRoute element={<DashboardWrapper />} />}
          />
        }
      >
        <Route path={dashboard.index}>
          <Route index element={<LazyRoute element={<DashboardPage />} />} />
        </Route>
        <Route path={properties.index}>
          <Route index element={<LazyRoute element={<PropertiesPage />} />} />
          <Route
            path={properties.add}
            element={<LazyRoute element={<AddPropertyPage />} />}
          />
          <Route path={properties["my-properties"].index}>
            <Route
              index
              element={<LazyRoute element={<MyPropertiesPage />} />}
            />
          </Route>
          <Route
            path={properties.show}
            element={<LazyRoute element={<SingleProperty />} />}
          />
        </Route>
        <Route
          path={profile.index}
          element={<LazyRoute element={<ProfilePage />} />}
        />
        <Route path={tickets.index}>
          <Route index element={<LazyRoute element={<TicketsHolder />} />} />
          <Route
            path={tickets.add}
            element={<LazyRoute element={<CreateTicket />} />}
          />
          <Route
            path={tickets.show}
            element={<LazyRoute element={<ShowSingleTicket />} />}
          />
        </Route>
        <Route path={notifications.index}>
          <Route index element={<LazyRoute element={<Notifications />} />} />
        </Route>
        <Route
          path={financial.index}
          element={<LazyRoute element={<FinancialPage />} />}
        />
      </Route>
      <Route path="/404" element={<LazyRoute element={<NotFoundPage />} />} />
      <Route path="*" element={<Navigate to={"/404"} replace />} />
    </Routes>
  )
}

export default RouteElements
