// Built-in import
import * as React from "react";
// External Import
import { IconButton, Stack, Typography } from "@mui/material";
import CopyIcon from "@/components/icons/copy-icon";
// Internal Import

interface ICopyClipBoardContentProps {
  content: string;
}

const CopyClipBoardContent: React.FunctionComponent<
  ICopyClipBoardContentProps
> = ({ content }) => {
  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      <Typography variant="smallMedium" color="text.primary">
        {content}
      </Typography>
      <IconButton
        sx={{
          p: 0,
        }}
      >
        <CopyIcon />
      </IconButton>
    </Stack>
  );
};

export default CopyClipBoardContent;
