// Built-in import
import * as React from "react";
// External import
import { Stack, Divider } from "@mui/material";
// Internal import
import CompleteInformationSubtitle from "./subtitle";
import CompleteInformationForm from "./form";
import { useAppSelector } from "@/store/store";
import {
  updatedUserSelector,
  userSelector,
} from "@/store/slices/auth/selectors";
import { UpdateInfoModalWrapper } from "../../partials";
import { useDispatch } from "react-redux";
import { actionSetCurrentStep } from "@/store/slices/auth";
import { CompleteInfoType } from "@/types/auth";

interface ICompleteInformation {}

const CompleteInformation: React.FC<ICompleteInformation> = (props) => {
  const userInfo = useAppSelector(updatedUserSelector);

  const dispatcher = useDispatch();

  const handleChangeStep = () => {
    dispatcher(actionSetCurrentStep(CompleteInfoType.BasicInformation));
  };
  return (
    <UpdateInfoModalWrapper handleBack={handleChangeStep}>
      <Stack height="100%" spacing={2}>
        {/* title */}
        <CompleteInformationSubtitle />
        <Divider sx={{ flexGrow: 1 }} />
        {/* form */}
        <CompleteInformationForm user={userInfo} />
      </Stack>
    </UpdateInfoModalWrapper>
  );
};
export default CompleteInformation;
