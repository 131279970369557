import Image from "./ServerMedia/Image";
import Doc from "./ServerMedia/Doc";
import Video from "./ServerMedia/Video";
import { IMedia } from "@/types/media";
import LocalImage from "./LocalMedia/Image";
import LocalDoc from "./LocalMedia/Doc";
import LocalVideo from "./LocalMedia/Video";
import { memo } from "react";

type Props = {
  file: IMedia | File;
};

const imagesFormat = ["image/webp", "image/jpeg", "image/jpg", "image/png"];
const videoFormat = ["video/mp4", "video/mpeg"];
const documentFormat = [
  "text/plain",
  "application/msword",
  "application/vnd.openxmlformats",
  "application/zip",
];

function Preview({ file }: Props) {
  if ((file as IMedia)?.id) {
    const targetFile = file as IMedia;
    if (imagesFormat.includes(targetFile.mime_type))
      return <Image image={targetFile} />;
    if (documentFormat.includes(targetFile.mime_type))
      return <Doc document={targetFile} />;
    if (videoFormat.includes(targetFile.mime_type))
      return <Video video={targetFile} />;
  } else {
    const targetFile = file as File;
    if (imagesFormat.includes(targetFile.type))
      return <LocalImage image={targetFile} />;
    if (documentFormat.includes(targetFile.type))
      return <LocalDoc document={targetFile} />;
    if (videoFormat.includes(targetFile.type))
      return <LocalVideo video={targetFile} />;
  }
}

export default memo(Preview);
