import * as React from "react";

import { Grid, Stack } from "@mui/material";
import { IEvent, IEventType } from "@/types/event";
import {
  IResponseForFetchEntity,
  IResponseForFetchPaginationEntity,
} from "@/api/types";

import ExecutiveCalendarCards from ".";
import ExecutiveCalenderDetails from "../details";
import LoadingEmbraced from "@/components/shared/loading-embraced";
import ModalContentWrapper from "@/components/shared/modal/modal-content-wrapper";
import { Report } from "@/services";
import { getEndpoint } from "@/api";
import { getUrlWithQueryParams } from "@/utils";
import { toaster } from "@/components/shared/toaster";
import useFetch from "@/hook/use-fetch";
import { useTranslation } from "react-i18next";

interface IEditEventModalProps {
  item: IEvent;
  projectId: string;
  onFetch?: () => void;
  isInvestor?: boolean;
}

const EditEventModal: React.FunctionComponent<IEditEventModalProps> = ({
  item,
  projectId,
  onFetch,
  isInvestor = false,
}) => {
  //getting translation object
  const { t } = useTranslation();
  //single event loading
  const [singleLoading, setSingleLoading] = React.useState<boolean>(false);
  //setting received event Type
  const [eventType, setEventType] = React.useState<IEventType | null>(null);

  //setting current event that fetched by event id
  const [currentEvent, setCurrentEvent] = React.useState<IEvent | null>(null);

  //fetch all event cards based on received type
  const {
    data: eventData,
    loading: eventLoading,
    renderAgain,
  } = useFetch<IResponseForFetchEntity<IEvent>>(
    getEndpoint("event", {
      event_id: item.id,
    }),
    Boolean(eventType),
    [eventType]
  );

  const eventItem = (eventData && eventData.data?.item) || null;

  const getSingleEvent = async (event: IEvent) => {
    setSingleLoading(true);
    try {
      const eventItem = await Report.RecieveSingleEvent(event.id);
      setCurrentEvent(eventItem.data.data.item);
    } catch (error) {
      toaster.error(error.response.data);
    } finally {
      setSingleLoading(false);
    }
  };

  //setting received type in state when type changes
  React.useEffect(() => {
    if (item?.type) setEventType(item.type);
  }, [item]);

  const [isEditable, setEditable] = React.useState(false);

  return (
    <ModalContentWrapper title={t("event_details")} onFetch={onFetch}>
      <Stack sx={{ height: "calc(100vh - 26px)" }}>
        <Grid
          container
          sx={{ minHeight: "600px" }}
          spacing={3}
          flexDirection={{ xs: "column-reverse", md: "initial" }}
        >
          <Grid item xs={12} lg={7}>
            {!eventItem || eventLoading ? (
              <LoadingEmbraced />
            ) : (
              <ExecutiveCalendarCards
                onFetchAllEvents={() => renderAgain()}
                events={
                  eventItem?.ancestors
                    ? [eventItem, ...eventItem.ancestors]
                    : []
                }
                handleSelectEventId={(event, isEditable?: boolean) => {
                  if (isInvestor) {
                    setEditable(false);
                  } else {
                    if (event.status === "Deleted") {
                      setEditable(false);
                    } else {
                      setEditable(isEditable);
                    }
                  }
                  getSingleEvent(event);
                }}
                sx={{
                  [`& .isDisabled`]: {
                    filter: "grayscale(1)",
                  },
                }}
              />
            )}
          </Grid>
          <Grid item xs={12} lg={5}>
            <ExecutiveCalenderDetails
              onChangeEvents={() => {
                renderAgain();
                setCurrentEvent(null);
              }}
              event={currentEvent}
              loading={singleLoading}
              isEditable={isEditable}
            />
          </Grid>
        </Grid>
      </Stack>
    </ModalContentWrapper>
  );
};

export default EditEventModal;
