import { IMedia } from "@/types/media";
import { limitChar } from "@/utils";
import VideoCameraBackIcon from "@mui/icons-material/VideoCameraBack";
import { Button, Stack, alpha } from "@mui/material";
import { t } from "i18next";
import { Link } from "react-router-dom";

type Props = {
  video: File;
};

export default function LocalVideo({ video }: Props) {
  return (
    <Stack
      alignItems={"center"}
      justifyContent={"center"}
      sx={{
        height: 240,
        backgroundColor: (t) => alpha(t.palette.common.black, 0.05),
        ":hover": (t) => alpha(t.palette.common.black, 0.1),
        borderRadius: 3,
      }}
    >
      <Stack spacing={3} alignItems={"center"} justifyContent={"center"}>
        <VideoCameraBackIcon />
        <span className='whitespace-pre-wrap w-[70%] text-ellipsis overflow-hidden'>
          {limitChar(video.name, 24)}
        </span>
        <a href={URL.createObjectURL(video)} target='_blank'>
          <Button type='button'>{t("open_video")}</Button>
        </a>
      </Stack>
    </Stack>
  );
}
