import * as React from "react";
import { Stack, Typography, InputAdornment } from "@mui/material";
import { useTranslation } from "react-i18next";
import TextField from "@mui/material/TextField";
import PercentOutlinedIcon from "@mui/icons-material/PercentOutlined";
interface IYourEstimatedPercentage {
  onChange?: (value: string) => void;
}

const YourEstimatedPercentage: React.FC<IYourEstimatedPercentage> = ({
  onChange,
}) => {
  //getting translation object
  const { t } = useTranslation();
  const [inputValue, setInputValue] = React.useState("");

  const changeInputValHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
    if (onChange) onChange(e.target.value);
  };

  return (
    <Stack direction={"row"} spacing={2} alignItems="center" width="100%">
      <TextField
        onChange={changeInputValHandler}
        type="number"
        placeholder={t("enter_an_estimated_percentage") + "..."}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <PercentOutlinedIcon color="primary" />
            </InputAdornment>
          ),
        }}
        sx={{ minWidth: ["100px", , "200px"], width: ["100%", , "200px"] }}
      />
    </Stack>
  );
};

export default YourEstimatedPercentage;
