// Built-in import
import * as React from "react";
// External import
import { useTranslation } from "react-i18next";
import { Stack } from "@mui/material";

// Internal import
import { TitleWithDescription } from "@/components/shared";

interface IUploadDocumentsSubtitle {}

const UploadDocumentsSubtitle: React.FC<IUploadDocumentsSubtitle> = () => {
  // Getting translation object
  const { t } = useTranslation();
  return (
    <Stack
      direction={{
        xs: "column-reverse",
        md: "row",
      }}
      justifyContent={"space-between"}
      width="100%"
      spacing={{ xs: 1, md: 0 }}
    >
      <TitleWithDescription
        title={t("upload_documents")}
        headerVariant={"headingBlack"}
        descriptionVariant={"bodyMedium"}
        primaryColor={"text.primary"}
        alignment={{ xs: "center", md: "left" }}
      />
    </Stack>
  );
};
export default UploadDocumentsSubtitle;
