// Built-in import
import * as React from "react"

// External import
import { Stack, Typography } from "@mui/material"

import DescriptionCard from "./description"
import { IServerProperty } from "@/store/slices/property"
import PriceCard from "./price"
import ProgressShared from "@/components/pages/dashboard/single-property/invest-receiver/content/show-property/details/sidebar/investment/progress-shared"
import ProjectProfit from "./profit"
import TimeCard from "./time"
// Internal import
import TitleCard from "./title"

interface IDetailsCard {
  item: IServerProperty
}

const DetailsCard: React.FC<IDetailsCard> = ({ item }) => {
  // Getting props as item
  const { excerpt } = item

  return (
    <Stack sx={{ py: 3, px: 2 }} spacing={2}>
      {/* title and tag */}
      <TitleCard item={item} />
      {/* description */}
      <DescriptionCard description={excerpt} />
      <Stack spacing={2}>
        {/* time & date */}
        <TimeCard item={item} />
        {/* price */}
        <Stack spacing={1}>
          <PriceCard price={item.required_investment_quantity} />
          <ProjectProfit item={item} />
        </Stack>
        {/* progress capital */}
        {/* <ProgressInvestment item={item} /> */}
        <ProgressShared item={item} />
        {/* investment users */}
        {/* <InvestmentUsers item={item} /> */}
      </Stack>
    </Stack>
  )
}

export default DetailsCard
