// Built-in import
import * as React from "react";

// External import
import { Grid, Paper, Stack, SxProps, Typography } from "@mui/material";

import AddExecutiveCalenderSidebar from "./sidebar/add";
import EditExecutiveCalenderSidebar from "./sidebar/edit";
import ExecutiveCalendarCards from "./cards";
import { IEvent } from "@/types/event";
import { IServerProperty } from "@/store/slices/property";
import { propertySelector } from "@/store/slices/property/selectors";
import { useAppSelector } from "@/store/store";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

// Internal import

interface IExecutiveCalender {
  sxProps?: SxProps;
  events?: IEvent[];
  onFetchAllEvents: () => void;
  isInvestor?: boolean;
}

const ExecutiveCalender: React.FC<IExecutiveCalender> = ({
  sxProps,
  events = [],
  onFetchAllEvents,
  isInvestor = false,
}) => {
  const property = useAppSelector(propertySelector) as IServerProperty;

  const { id: isEdit } = useParams();

  // Getting translation object
  const { t } = useTranslation(["common"]);

  //setting received events

  const [receivedItems, setReceivedItems] = React.useState<IEvent[]>([]);

  // const handleSubmitProject = async (e) => {
  //   e.preventDefault();
  //   try {
  //     setLoading(true);
  //     await Project.Create(property);
  //     showModal(<SuccessModalExecutive />);
  //   } catch (e) {
  //     toaster.error(e);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  React.useEffect(() => {
    if (events.length) {
      setReceivedItems(events);
    }
  }, [events]);

  return (
    <Stack sx={{ my: [3, , 2], ...sxProps }}>
      <Paper
        sx={{
          px: [0, , 2],
          border: "1px solid",
          py: [2],
          borderRadius: "12px",
          borderColor: (t) => t.palette.grey[300],
        }}
      >
        <Stack spacing={8}>
          <Stack spacing={2}>
            {/* content */}
            <Stack sx={{ width: "100%" }}>
              {Boolean(isEdit) && (
                <Typography
                  variant="bodyMedium"
                  color="text.secondary"
                  sx={{ px: [1.5, , 0] }}
                >
                  {t(
                    "common:to_view_details_each_event_click_event_card_calendar"
                  )}
                </Typography>
              )}
              <Grid
                container
                flexDirection={{ xs: "column-reverse", md: "unset" }}
                spacing={{ xs: 3, md: 2 }}
                sx={{ mt: "8px !important" }}
              >
                {/* right side */}
                <Grid item xs={12} lg={7}>
                  <Stack>
                    <ExecutiveCalendarCards
                      onFetchAllEvents={onFetchAllEvents}
                      property={property as IServerProperty}
                      isInvestor={isInvestor}
                      events={receivedItems}
                      {...(isEdit && { propertyId: property.id })}
                    />
                  </Stack>
                </Grid>
                {/* left side */}
                <Grid item xs={12} lg={5}>
                  <Stack sx={{ px: [2, , 0] }}>
                    {isEdit ? (
                      <EditExecutiveCalenderSidebar />
                    ) : (
                      <AddExecutiveCalenderSidebar />
                    )}
                  </Stack>
                </Grid>
              </Grid>
            </Stack>
          </Stack>
        </Stack>
      </Paper>
    </Stack>
  );
};

export default ExecutiveCalender;

// (
//   <Stack spacing={2} alignItems="center">
//     <ReportProblemRoundedIcon sx={{ fontSize: 64 }} />
//     <Typography>
//       {t(
//         "common:you_can_add_events_after_submitting_the_project"
//       )}
//     </Typography>
//     <AddPropertyStepsActions
//       handlePrevStep={() =>
//         navigator(`?type=${AddPropertyStepTypes.AdditionalInfo}`)
//       }
//       loading={loading}
//     />
//   </Stack>
// )
