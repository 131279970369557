//Built-in Import
import * as React from "react";
//External Import
import ReactApexChart from "react-apexcharts";
import { useTranslation } from "react-i18next";
import { Paper, Stack, Typography } from "@mui/material";
import { ApexOptions } from "apexcharts";
import NoItemsWasFound from "../no-items";

interface IDonutChartProps {
  title: string;
  chartOptions?: ApexOptions;
  colors: string[];
  labels: string[];
  series: number[];
}

const DonutChart: React.FunctionComponent<IDonutChartProps> = ({
  title,
  chartOptions,
  colors,
  series,
  labels,
}) => {
  //check for series items have length or not
  const showChart = Boolean(series.reduce((sum, item) => sum + item, 0) > 0);
  return (
    <Paper
      sx={{
        height: "100%",
        justifyContent: "space-between",
        display: "flex",
        flexDirection: "column",

        [`& *`]: {
          minHeight: "0 !important",
          fontFamily: "Vazir !important",
        },
        [`& .apexcharts-legend`]: {
          flexDirection: "column",
          [`& .apexcharts-legend-series`]: {
            display: "flex !important",
          },
          [`& .apexcharts-legend-marker`]: {
            marginRight: 1,
          },
        },
      }}
    >
      <Stack spacing={2}>
        <Typography variant="bodyMedium">{title}</Typography>
        {showChart ? (
          <ReactApexChart
            options={{
              ...chartOptions,
              chart: { type: "donut" },
              colors,
              labels,
            }}
            series={series}
            type={"donut"}
          />
        ) : (
          <NoItemsWasFound />
        )}
      </Stack>
    </Paper>
  );
};

export default DonutChart;
