/**
 * Place all endpoints here
 */

type ParamsType = string | number | boolean
type NoParamKeys =
  | "projects"
  | "tickets"
  | "events"
  | "profile"
  | "single-project"
  | "authSejam"
  | "authStepOne"
  | "gateway"
  | "register"
  | "authStepTwo"
  | "transactions"
  | "notifications"
  | "notificationsStatistics"
  | "resetPassword"
  | "authResendOtp"
  | "authViaCredential"
  | "authSejamStepOne"
  | "authSejamStepTwo"
  | "exportExcelTranasction"

type ParamKeys =
  | "hasParam"
  | "singleTicket"
  | "ticketMessages"
  | "singleUser"
  | "projectInvestment"
  | "singleNotification"
  | "projectInvestors"
  | "single-project"
  | "event"
  | "calcProjectProfit"
  | "singleProject"
  | "projectsStatistics"
  | "statistics"
  | "projectTransactions"
  | "walletTransaction"
  | "transactionVerify"
  | "submitPayment"

type ParamsGenerator<T extends ParamKeys> = {
  [k in (typeof endpoints)[T]["params"][number]]: ParamsType
}

type FunctionOverload = {
  <T extends NoParamKeys>(key: T): string
  <T extends ParamKeys>(key: T, params: ParamsGenerator<T>): string
}

/**
 * endpoints must have the following naming
 * @example ```js
 * const endpoints = {
 *    "endpoint-key": {
 *      endpoint: "path/to/my/endpoint/" or "path/to/:my/endpoint/:params",
 *      params: false or ["my", "params"]
 *    }
 * }
 * This way typescript will suggest you with required params
 * ```
 */
export const endpoints = {
  projects: {
    endpoint: "/projects",
  },
  "single-project": {
    endpoint: "/projects/:project_id",
    params: ["project_id"],
  },
  authStepOne: {
    endpoint: "/auth/login/via-otp/step-one",
  },
  authSejam: {
    endpoint: "/auth/sajam",
  },
  authViaCredential: {
    endpoint: "/auth/login/via-credential",
  },
  authStepTwo: {
    endpoint: "/auth/login/via-otp/step-two",
  },
  authResendOtp: {
    endpoint: "/auth/resend-otp",
  },
  calcProjectProfit: {
    endpoint: "/projects/:project_id/calculate-profit",
    params: ["project_id"],
  },
  transactionVerify: {
    endpoint: "/wallets/transactions/:transaction_id/verify",
    params: ["transaction_id"],
  },
  projectsStatistics: {
    endpoint: "/projects/statistics/:partial",
    params: ["partial"],
  },
  statistics: {
    endpoint: "/statistics/:partial",
    params: ["partial"],
  },
  exportExcelTranasction: {
    endpoint: "/wallets/transactions/export/excel",
  },
  resetPassword: {
    endpoint: "/users/password",
  },
  register: {
    endpoint: "/users/register",
  },
  singleProject: {
    endpoint: "/projects/:id",
    params: ["id"],
  },
  submitPayment: {
    endpoint: "/wallets/transactions/:transaction_id/reference",
    params: ["transaction_id"],
  },
  tickets: {
    endpoint: "/tickets",
  },
  transactions: {
    endpoint: "/wallets/transactions",
  },
  notifications: {
    endpoint: "/notifications",
  },
  notificationsStatistics: {
    endpoint: "/notifications/statistics",
  },
  singleNotification: {
    endpoint: "/notifications/:id",
    params: ["id"],
  },
  gateway: {
    endpoint: "/gateway",
  },

  singleTicket: {
    endpoint: "/tickets/:id",
    params: ["id"],
  },
  projectInvestment: {
    endpoint: "/projects/:id/investment",
    params: ["id"],
  },
  walletTransaction: {
    endpoint: "/wallets/transactions/:transaction_id",
    params: ["transaction_id"],
  },
  events: {
    endpoint: "/events",
  },
  event: {
    endpoint: "/events/:event_id",
    params: ["event_id"],
  },
  projectInvestors: {
    endpoint: "/projects/:id/investors",
    params: ["id"],
  },
  ticketMessages: {
    endpoint: "/tickets/:id/messages",
    params: ["id"],
  },
  projectTransactions: {
    endpoint: "/projects/:project/transactions",
    params: ["project"],
  },
  singleUser: {
    endpoint: "/users/:id",
    params: ["id"],
  },
  profile: {
    endpoint: "/profile",
  },
  authSejamStepOne: {
    endpoint: "/auth/login/via-sejam/step-one",
  },
  authSejamStepTwo: {
    endpoint: "/auth/login/via-sejam/step-two",
  },
  hasParam: {
    endpoint: "/param/:myParam",
    params: ["myParam"],
  },
} as const

/**
 * this function returns an endpoint based on panel, key, and params
 * @param key endpoint key inside that panel
 * @param params if the selected endpoint had any params(for example /details/:id), the keys in this argument must match the params of that endpoint({ id: 2 } in this example)
 * @returns the endpoint of the API
 */
const getEndpoint: FunctionOverload = (...args: any[]) => {
  const key = args[0] as keyof typeof endpoints
  const params: any = args?.[1]
  let selectedEndpoint: string = endpoints[key].endpoint
  if (params) {
    for (let key in params) {
      let regex = new RegExp(`\/:${key}`)
      selectedEndpoint = selectedEndpoint.replace(regex, "/" + params[key])
    }
  }
  return selectedEndpoint
}

export default getEndpoint
