export interface ITitleItems {
  title: string;
  tag?: string;
}
export interface IProgressInvestmentItems {
  capital: string | number;
  progressValue: string | number;
}
export interface ISummaryItem {
  successful_projects: string | number;
  volume_of_investment: string | number;
  investor: string | number;
}
export enum AddPropertyStepTypes {
  BasicPlanInfo = 0,
  CapitalizationDetails = 1,
  AdditionalInfo = 2,
  ExecutiveCalender = 3,
}
