// Built-in import
import * as React from "react";

// External import
import { Button, Stack } from "@mui/material";

import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
// Internal import
import { TitleWithDescription } from "@/components/shared";
import { UpdateInfoModalWrapper } from "../../partials";
import routes from "@/navigation/routes";
import { useGlobalContext } from "@/context/global-context";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

interface ISuccessRegistration {}

const SuccessRegistration: React.FC<ISuccessRegistration> = () => {
  // Getting translation object
  const { t } = useTranslation(["common"]);
  // navigator
  const navigator = useNavigate();

  const { closeModal } = useGlobalContext();
  const handleFinlizeProfile = () => {
    navigator(`/${routes.dashboard.index}`);
    closeModal();
    window.location.reload();
  };

  return (
    <UpdateInfoModalWrapper>
      <Stack spacing={2} sx={{ maxWidth: ["initial", , 400] }}>
        <Stack
          sx={{
            borderBottom: "1px solid",
            borderColor: (t) => t.palette.grey[300],
            pb: 2,
          }}
        >
          <TitleWithDescription
            title={t("common:your_registration_was_successful")}
            description={t(
              "common:your_registration_request_has_been_sent_to_admin_and_after_checking_your_information_system_facilities_will_be_available_for_you"
            )}
            primaryColor="text.primary"
            headerVariant="headingBlack"
            descriptionVariant="bodyMedium"
          />
        </Stack>
        <Stack>
          <Button
            variant="contained"
            startIcon={<KeyboardArrowRightIcon />}
            onClick={handleFinlizeProfile}
          >
            {t("common:login_to_system")}
          </Button>
        </Stack>
      </Stack>
    </UpdateInfoModalWrapper>
  );
};

export default SuccessRegistration;
