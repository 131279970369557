// Built-in import
import * as React from "react";
import * as Yup from "yup";

// External import
import { Button, Stack, TextField, Typography } from "@mui/material";
// Internal import
import { MyDatePicker, Tag, TitleWithValue } from "@/components/shared";

import EventDetailsActions from "../actions";
import EventStatus from "@/components/shared/event-card/status";
import GalleryUploader from "@/components/shared/gallery-uploader";
import { IEvent } from "@/types/event";
import { Report } from "@/services";
import moment from "moment";
import { toaster } from "@/components/shared/toaster";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import DeleteEventModalContent from "../actions/delete-modal";
import { useGlobalContext } from "@/context/global-context";

interface IReportStep {
  event?: IEvent;
  onChangeEvents?: () => void;
  isEditable?: boolean;
}

const ReportStep: React.FC<IReportStep> = ({
  event,
  onChangeEvents,
  isEditable = false,
}) => {
  const { showModal } = useGlobalContext();
  // getting translation object
  const { t } = useTranslation(["common", "validation"]);
  // edit
  const [isEdit, setIsEdit] = React.useState(false);

  const [loading, setLoading] = React.useState(false);

  const [files, setFiles] = React.useState<any[]>([]);

  React.useEffect(() => {
    if (event.media) setFiles(event?.media);
  }, [event.media]);

  const { handleSubmit, getFieldProps, errors, touched } = useFormik({
    enableReinitialize: true,
    initialValues: {
      expires_at: event?.expires_at || "",
      description: event?.description || "",
      title: event?.title || "",
      media: event?.media || [],
    },
    validationSchema: Yup.object().shape({
      // expires_at: Yup.string().required(t("validation:this_field_is_required")),
      // description: Yup.string().required(
      //   t("validation:this_field_is_required")
      // ),
    }),
    onSubmit: async (values) => {
      let finalFiles = files.map((item) => {
        if (
          typeof item === "object" &&
          Object.keys(item).includes("collection_name")
        ) {
          return item.id;
        } else {
          return item;
        }
      });

      setLoading(true);
      try {
        const res = await Report.Create(
          event?.project_id,
          event?.type,
          {
            ...(values.expires_at
              ? { expires_at: moment(values.expires_at).unix() }
              : { description: values.description }),
            event_id: event?.id,
            title: values.title || "",
          },
          finalFiles
        );
        toaster.success(res.data.message);
        onChangeEvents();
      } catch (error) {
        toaster.error(error.response.data);
      } finally {
        setLoading(false);
      }
    },
  });
  return (
    <Stack spacing={5} component={"form"} onSubmit={handleSubmit}>
      {event.expires_at ? (
        <TitleWithValue
          title={t("common:anticipate_future_reports_date")}
          value={<MyDatePicker title={t("select_date")} />}
          direction={"column"}
          alignItems="flex-start"
          colorTitle="text.secondary"
          variantTitle="smallMedium"
        />
      ) : (
        <Stack width="100%" spacing={2}>
          <Stack sx={{ position: "relative" }}>
            <TextField
              fullWidth
              label={t("common:label")}
              {...getFieldProps("title")}
              disabled={isEdit ? false : true}
              error={Boolean(touched.title && errors.title)}
              helperText={touched.title && errors.title}
              InputProps={{
                readOnly: isEdit ? false : true,
              }}
            />
            <Stack
              sx={{
                position: "absolute",
                right: 8,
                top: "50%",
                transform: "translateY(-50%)",
              }}
            >
              <EventStatus status={event.status} />
            </Stack>
          </Stack>

          <Stack>
            <TitleWithValue
              title={t("common:description_of_report")}
              value={
                <TextField
                  fullWidth
                  multiline
                  rows={6}
                  {...getFieldProps("description")}
                  disabled={isEdit ? false : true}
                  // error={Boolean(touched.description && errors.description)}
                  // helperText={touched.description && errors.description}
                  InputProps={{
                    readOnly: isEdit ? false : true,
                  }}
                />
              }
              direction={"column"}
              alignItems="flex-start"
              colorTitle="text.secondary"
              variantTitle="smallMedium"
            />
          </Stack>
          {/* Gallery Images of event */}
          <GalleryUploader
            defaultItems={files}
            onChange={(items) => setFiles(items)}
            showActions={isEdit}
          />
          {/* Gallery Images of event */}
        </Stack>
      )}
      {isEditable ? (
        isEdit ? (
          <EventDetailsActions loading={loading} />
        ) : (
          <Stack
            spacing={2}
            direction={"row"}
            width={"100%"}
            justifyContent={"flex-end"}
            alignItems={"cetner"}
          >
            <Button
              variant="contained"
              color="error"
              onClick={() =>
                showModal(
                  <DeleteEventModalContent
                    event={event}
                    onChangeEvents={onChangeEvents}
                  />,
                  {
                    width: ["100%", , 500],
                    height: "auto",
                  }
                )
              }
            >
              {t("delete")}
            </Button>
            <Button
              variant="outlined"
              onClick={() => setIsEdit(true)}
              sx={{ alignSelf: "flex-end" }}
            >
              {t("common:edit")}
            </Button>
          </Stack>
        )
      ) : null}
    </Stack>
  );
};

export default ReportStep;
