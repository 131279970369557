// Built-in import
import * as React from "react";

// Internal import
import { AuthSubmitButton, GalleryUploader } from "@/components/shared";
// External import
import { Grid, Stack } from "@mui/material";
import {
  actionSetCurrentStep,
  actionSetUser,
  actionUpdateUserInfo,
} from "@/store/slices/auth";
import { api, getEndpoint } from "@/api";
import {
  updatedUserSelector,
  userTypeSelector,
} from "@/store/slices/auth/selectors";

import { CompleteInfoType } from "@/types/auth";
import UploadDocumentsFormWarning from "./warnings";
import { toaster } from "@/components/shared/toaster";
import { useAppSelector } from "@/store/store";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

interface IUploadDocumentsForm {}

const UploadDocumentsForm: React.FC<IUploadDocumentsForm> = (props) => {
  // Getting translation object
  const { t } = useTranslation(["common"]);

  const [loading, setLoading] = React.useState(false);

  //getting redux dispatch

  const dispatcher = useDispatch();
  //set media items that received from gallery
  // Getting updated user info from redux
  const userInfo = useAppSelector(updatedUserSelector);
  const [mediaItems, setMediaItems] = React.useState<any[]>(userInfo.media);

  //getting type of user that we setted for update
  const userType = useAppSelector(userTypeSelector);

  const handleCompleteRegistration = async () => {
    setLoading(true);
    if (!userType.length) return;
    const formData = new FormData();
    formData.append("_method", "PUT");
    // formData.append("type", userType);
    for (let index in mediaItems) {
      let mval = mediaItems[index];
      if (Object.keys(mediaItems[index]).includes("collection_name")) {
        formData.append(`documents[${index}]`, mediaItems[index].id);
      } else {
        formData.append(`documents[${index}]`, mval);
      }
    }
    for (let key of Object.keys(userInfo)) {
      let val = userInfo[key];
      formData.append(key, val);
      if (key === "id") formData.delete("id");
      if (key === "media") formData.delete("media");
      if (key === "roles") formData.delete("roles");
      if (userInfo[key] === null) {
        formData.delete(key);
        if (key === "type") {
          formData.append(key, userType);
        } else {
          formData.append(key, "");
        }
      }
      if (key === "type" && userInfo[key] !== null) {
        formData.delete("type");
        formData.append("type", userType);
      }
    }

    try {
      const res = await api.post(
        getEndpoint("singleUser", { id: userInfo.id }),
        formData
      );
      toaster.success(res.data.message);
      dispatcher(actionSetUser({ user: res?.data.data.item }));
      dispatcher(actionUpdateUserInfo(res?.data.data.item));
      dispatcher(actionSetCurrentStep(CompleteInfoType.LegalUpdatedSuccess));
    } catch (e) {
      toaster.error(e.response.data);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Stack spacing={2} sx={{ height: "100%" }} justifyContent="space-between">
      <Stack my={2}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <UploadDocumentsFormWarning />
          </Grid>
          <Grid item xs={12}>
            <GalleryUploader
              defaultItems={mediaItems}
              onChange={(items) => setMediaItems(items)}
            />
          </Grid>
        </Grid>
      </Stack>
      <Stack alignItems={{ xs: "initial", md: "start" }}>
        <AuthSubmitButton
          loading={loading}
          title={t("complete_registration")}
          btnType={"button"}
          sxProps={{
            width: ["100%", , "200px"],
          }}
          handleClick={handleCompleteRegistration}
        />
      </Stack>
    </Stack>
  );
};

export default UploadDocumentsForm;
