import * as React from "react";
import { Stack, alpha, SxProps, Theme } from "@mui/material";
import { IRegisterWaysItem } from "@/types/auth";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import { TitleWithDescription } from "@/components/shared";
interface IRegisterTypeBoxProps {
  item: IRegisterWaysItem;
  handleChange: (value: "Real" | "Legal") => void;
  isActive?: boolean;
}

const RegisterTypeBox: React.FunctionComponent<IRegisterTypeBoxProps> = ({
  item,
  handleChange,
  isActive = false,
}) => {
  //add style for active register way
  const activeWayStyles: SxProps<Theme> = {
    bgcolor: (t) => alpha(t.palette.primary.main, 0.2),
    borderColor: (t) => t.palette.primary.main,
    "& > :first-child": {
      position: "relative",
      "& svg": {
        position: "absolute",
        top: 0,
        right: 0,
      },
    },
  };

  return (
    <Stack
      onClick={() => handleChange(item.value)}
      spacing={{ xs: 2, md: 1 }}
      sx={{
        width: 175,
        height: 175,
        p: 2,
        borderRadius: "12px",
        display: "flex",
        cursor: "pointer",
        justifyContent: "start",
        position: "relative",
        alignItems: "center",
        border: "1px solid",
        bgcolor: (t) => alpha(t.palette.common.black, 0.03),
        borderColor: (t) => alpha(t.palette.common.black, 0.07),
        ...((isActive && activeWayStyles) || {}),
      }}
    >
      <Stack
        direction="row"
        width={"100%"}
        justifyContent="end"
        sx={{ position: "absolute" }}
      >
        {(isActive && <TaskAltIcon color="primary" />) || null}
      </Stack>
      {item.icon}
      <TitleWithDescription
        spacing={0}
        alignment="center"
        title={item.title}
        description={item.description}
        primaryColor={"#000"}
        headerVariant={"bodyBold"}
        descriptionVariant={"extraSmallMedium"}
      />
    </Stack>
  );
};

export default React.memo(RegisterTypeBox);
