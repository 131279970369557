import { useCallback, useEffect, useReducer } from "react"

type ReducerActionType<T = any> = {
  type: string
  payload: T
}

const initReducer = (state: any, action: ReducerActionType<any>) => {
  switch (action.type) {
    case "CHANGE_KEY":
      state = {
        ...state,
        [action.payload?.["key"]]: action.payload?.["value"],
      }
      break
    case "CHANGE_BULK":
      state = { ...state, ...action.payload?.value }
      break
  }
  return state
}

const useValues = <T>(init: T, deps: any[] = []) => {
  const [state, dispatch] = useReducer(initReducer, init)

  const changeKey = useCallback((key: string | number, value: any) => {
    dispatch({
      type: "CHANGE_KEY",
      payload: {
        key,
        value,
      },
    })
  }, [])

  const changeBulk = useCallback((value: any) => {
    dispatch({
      type: "CHANGE_BULK",
      payload: {
        value,
      },
    })
  }, [])

  useEffect(() => {
    changeBulk(init)
  }, [...deps])

  return {
    values: state as T,
    changeKey,
    changeBulk,
  }
}

export default useValues
