import { Typography } from "@mui/material";
import * as React from "react";

interface IFinancialSummaryTitleProps {
  title: string;
}

const FinancialSummaryTitle: React.FunctionComponent<
  IFinancialSummaryTitleProps
> = ({ title }) => {
  return (
    <Typography
      variant="smallBold"
      overflow={"hidden"}
      width={["70px", "100px"]}
      whiteSpace={"nowrap"}
      textOverflow={"ellipsis"}
    >
      {title}
    </Typography>
  );
};

export default FinancialSummaryTitle;
