import { useAppSelector } from "@/store/store";
import { userSelector } from "@/store/slices/auth/selectors";

const useAuth = () => {
  const user = useAppSelector(userSelector);

  const isRole = (role_name: string) => {
    if (!role_name) return false;
    if (!user?.roles?.length) return false;
    return user?.roles?.map((item) => item.name)?.includes(role_name);
  };

  return { user, isRole, isLoggedIn: Boolean(user) };
};
export default useAuth;
