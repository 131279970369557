import { IMedia } from "@/types/media";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { Button, Stack, alpha } from "@mui/material";
import { Link } from "react-router-dom";
import InsertLinkIcon from "@mui/icons-material/InsertLink";

type Props = {
  document: IMedia;
};

export default function Doc({ document }: Props) {
  return (
    <Stack
      sx={{
        height: 240,
        borderRadius: 3,
        backgroundSize: "cover",
        backgroundColor: (t) => alpha(t.palette.common.black, 0.05),
      }}
    >
      <div className='flex flex-col h-full gap-y-4 items-center justify-center'>
        <AttachFileIcon />
        <span className='whitespace-pre-wrap w-[70%] text-ellipsis overflow-hidden'>
          {document.name}
        </span>
        <Link to={`${document.original_url}`}>
          <Button type='button' startIcon={<InsertLinkIcon />}>
            Open File
          </Button>
        </Link>
      </div>
    </Stack>
  );
}
