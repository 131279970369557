// Built-in import
import * as React from "react"

// External Import
import { Button, Typography } from "@mui/material"

import PaymentDetailsContent from "./payment-details-modal"
import { financialSingleData } from "../data"
// Internal Import
import { useGlobalContext } from "@/context/global-context"
import { useTranslation } from "react-i18next"

interface IPaymentDetailsRendererProps {
  item: any
}

const PaymentDetailsRenderer: React.FunctionComponent<
  IPaymentDetailsRendererProps
> = ({ item }) => {
  // Getting Translation
  const { t } = useTranslation(["common"])
  //   Getting Show Modal
  const { showModal } = useGlobalContext()

  const RenderContent = (): any => {
    switch (item.status) {
      case "success":
        return (
          <Typography variant="smallMedium" color="text.primary">
            {item.trackingCode}
          </Typography>
        )
      case "pending":
        return (
          <Button
            variant="contained"
            sx={{
              bgcolor: (t) => t.palette.warning.light,
              color: (t) => t.palette.common.black,
              [`&:hover`]: {
                bgcolor: (t) => t.palette.warning.light
              }
            }}
          >
            {t("common:see")}
          </Button>
        )
      case "error":
        return (
          <Button
            variant="contained"
            onClick={() => {
              showModal(<PaymentDetailsContent item={financialSingleData} />, {
                [`&.modal`]: {
                  width: ["100%", , 920]
                }
              })
            }}
          >
            {t("common:pay")}
          </Button>
        )
      default:
        return (
          <Typography variant="smallMedium" color="text.primary">
            {item.trackingCode}
          </Typography>
        )
    }
  }
  return <RenderContent />
}

export default PaymentDetailsRenderer
