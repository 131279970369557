import { authSlice, propertySlice, settingsSlices } from "../slices";
import storage from "redux-persist/lib/storage";
import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";

const authPersistConfig = {
  key: "auth",
  storage,
  blacklist: ["currentStep", "selectedType", "updatedUser", "loading"],
};

const rootReducer = combineReducers({
  auth: persistReducer(authPersistConfig, authSlice),
  setting: settingsSlices,
  property: propertySlice,
});

export default rootReducer;
