import * as React from "react"

import { SxProps, Typography, alpha, useTheme } from "@mui/material"

interface ITagProps {
  title: string
  color?: string
  sxProps?: SxProps
}

const Tag: React.FC<ITagProps> = ({ title, color, sxProps }) => {
  // Getting mui theme
  const theme = useTheme()
  // color tag
  let bgcolorTag: any
  // color text
  let textColor: any
  switch (color) {
    case "seeding":
    case "success":
      bgcolorTag = theme.palette.success.main
      textColor = (t) => t.palette.common.white
      break
    case "info":
    case "pending":
      bgcolorTag = alpha(theme.palette.info.main, 0.2)
      textColor = theme.palette.info.main
      break

    case "warning":
      bgcolorTag = alpha(theme.palette.warning.main, 0.2)
      textColor = theme.palette.warning.main
      break
    case "rejected":
    case "suspended":
      bgcolorTag = alpha(theme.palette.error.main, 0.2)
      textColor = theme.palette.error.main
      break
    default:
      bgcolorTag = theme.palette.primary.main
      textColor = (t) => t.palette.common.white
      break
  }
  return (
    <Typography
      variant="extraSmallMedium"
      sx={{
        background: bgcolorTag,
        color: textColor,
        px: 1.5,
        py: 0.7,
        height: "fit-content",
        lineHeight: "20px !important",
        borderRadius: 10,
        whiteSpace: "nowrap",
        ...sxProps
      }}
    >
      {title}
    </Typography>
  )
}

export default Tag
