import {
  Box,
  Checkbox,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  alpha,
} from "@mui/material";
import React, { ChangeEvent, useState } from "react";

import { ITableProps } from "@/types/table";
import { MyTableSkeleton } from "./skeleton";
import { useTranslation } from "react-i18next";

const MyTable: React.FC<ITableProps> = ({
  title = "",
  endButton,
  tableHeadItems = [],
  items,
  selectable = false,
  onChangeSelectedIds,
  totalItems,
  loading = false,
  sxProps = {},
}): React.ReactElement => {
  //Getting translation object
  const { t } = useTranslation();

  //Collect item id
  const [selectedIds, setSelectedIds] = useState<number[]>([]);
  //Fire onChangeSelected
  const updateSelectedEvent = (items: number[]) => {
    if (onChangeSelectedIds && typeof onChangeSelectedIds === "function")
      onChangeSelectedIds(items);
  };
  //Function to store item id
  const handleSelectId = (event: ChangeEvent<HTMLInputElement>, id: number) => {
    if (selectedIds.indexOf(id) !== -1) {
      const newIds = selectedIds.filter((item) => item !== id);
      setSelectedIds(newIds);
      //Update event listener
      updateSelectedEvent(newIds);
    } else {
      const newIds = [...selectedIds, id];
      setSelectedIds(newIds);
      //Update event listener
      updateSelectedEvent(newIds);
    }
  };

  const MainTableContainer = () => (
    <TableContainer>
      <Table
        sx={{
          "& :last-child td, &:last-child th :not(thead)": {
            borderBottom: "2px solid",
            borderColor: (t) => alpha(t.palette.common.black, 0.12),
            bgcolor: (t) => t.palette.common.white,
          },
          ...sxProps,
        }}
      >
        <TableHead
          sx={{
            "& tr th": {
              whiteSpace: "nowrap",
              textAlign: "center",
              borderBottom: "2px solid",
              borderColor: (t) => alpha(t.palette.common.black, 0.12),
              bgcolor: (t) => alpha(t.palette.common.black, 0.02),
            },
          }}
        >
          <TableRow>
            {/** Selectable th */}
            {selectable && <TableCell>#</TableCell>}
            {/** Selectable th */}
            {tableHeadItems.map((item, key) => (
              <TableCell key={key}>{item.title}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody
          sx={{
            "& td": {
              whiteSpace: "nowrap",
              textAlign: "center",
              py: 1,
              px: 1.5,
            },
          }}
        >
          {loading ? (
            <MyTableSkeleton
              selectable={selectable}
              tableHeadItems={tableHeadItems}
            />
          ) : items.length === 0 ? (
            <TableRow>
              <TableCell colSpan={tableHeadItems.length}>
                <Typography>{t("nothing_was_found")}</Typography>
              </TableCell>
            </TableRow>
          ) : (
            items.map((item, rowKey) => {
              //Selected value
              const isSelectedCheckbox = selectedIds.indexOf(item?.id) !== -1;

              return (
                <TableRow key={rowKey}>
                  {/** Selectable checkbox */}
                  {selectable && (
                    <TableCell>
                      <Checkbox
                        checked={isSelectedCheckbox}
                        onChange={(e) => handleSelectId(e, item?.id)}
                      />
                    </TableCell>
                  )}
                  {/** Selectable checkbox */}
                  {tableHeadItems.map((tdItem, tdKey) => {
                    return (
                      <TableCell key={tdKey}>
                        {tdItem.render(item, rowKey)}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );

  return (
    <Box>
      <Stack
        direction={"row"}
        width="100%"
        mb={2}
        alignItems={"center"}
        justifyContent="space-between"
      >
        <Stack flexDirection={"row"} alignItems="baseline">
          {title && (
            <Typography sx={{ mr: 1 }} variant="bodyBold" color="text.primary">
              {title}
            </Typography>
          )}
          {totalItems && (
            <Typography variant="bodyRegular" color="label.main">
              {t("rows_quantity", { items: totalItems })}
            </Typography>
          )}
        </Stack>
        {endButton && endButton}
      </Stack>
      <MainTableContainer />
    </Box>
  );
};

export default MyTable;
